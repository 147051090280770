import {
  Sortable,
  SortableOnDragOverEvent,
} from "@progress/kendo-react-sortable";

import { SortableItemUIProps } from "@progress/kendo-react-sortable";
import ListItem from "../ListItem";
import ABKMessageNoRecords from "src/abk-shared/components/atoms/ABKMessageNoRecords";
import { DisplayItem } from "..";

const DnDView = ({
  componentData,
  objectKeys,
  dataToDisplay,
  updateData,
  onDragOver,
  setData,
}: {
  componentData: any[];
  objectKeys: string[];
  dataToDisplay: DisplayItem[];
  updateData: (data: any[]) => void;
  onDragOver: (event: SortableOnDragOverEvent) => void;
  setData: (data: any[]) => void;
}) => (
  <>
    {componentData.length === 0 ? (
      <ABKMessageNoRecords />
    ) : (
      <Sortable
        idField={"id"}
        data={componentData}
        itemUI={(props: SortableItemUIProps) => (
          <ListItem
            {...props}
            dataToDisplay={dataToDisplay}
            objectKeys={objectKeys}
            setData={updateData}
            data={componentData}
          />
        )}
        onDragOver={onDragOver}
        onDragEnd={(event) => {
          setData(event.newState);
        }}
      />
    )}
  </>
);

export default DnDView;
