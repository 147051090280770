import { Button } from "@progress/kendo-react-buttons";
import { EditorUtils } from "@progress/kendo-react-editor";
import { Input, InputHandle } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";
import * as React from "react";
import * as ReactDOM from "react-dom";
import ABKInfoPopup from "../../../../abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "../../../../abk-shared/components/atoms/ABKLabel";
import ABKWindow from "../../../../abk-shared/components/molecules/ABKWindow";
import "./insertImageDialog.css";
import { insertImageFiles } from "./utils";

export const InsertImageDialog = (props: any) => {
  const [selected, setSelected] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const windowRef = React.useRef<any>(null);

  let src: InputHandle | null = null;
  let altText: InputHandle | null = null;
  let title: InputHandle | null = null;
  let width: InputHandle | null = null;
  let height: InputHandle | null = null;

  const onTabSelect = (event: TabStripSelectEventArguments) => {
    setFiles([]);
    setSelected(event.selected);
  };

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onAddFiles = (event: any) => {
    setFiles(
      event.newState
        .map((f: UploadFileInfo) => f.getRawFile && f.getRawFile())
        .filter((f: UploadFileInfo) => f)
    );
  };

  const onInsert = () => {
    const { view, imageNode } = props;
    const nodes = view.state.schema.nodes;
    const nodeType = nodes[imageNode];
    const position = null;
    const data = {
      src: src ? src.value : null,
      title: title ? title.value : null,
      alt: altText ? altText.value : null,
      width: width ? width.value : null,
      height: height ? height.value : null,
    };

    const attrs = Object.keys(data)
      .filter(
        (key) =>
          data[key as keyof typeof data] !== null &&
          data[key as keyof typeof data] !== ""
      )
      .reduce(
        (acc, curr) =>
          Object.assign(acc, { [curr]: data[curr as keyof typeof data] }),
        {}
      );

    if (files.length) {
      insertImageFiles({ view, files, nodeType, position, attrs });
    } else {
      const newImage = nodeType.createAndFill(attrs);
      EditorUtils.insertNode(view, newImage, true);
    }

    view.focus();
    onClose();
  };

  const { view, imageNode } = props;
  const state = view && view.state;

  let attrs: any = {};

  if (
    state &&
    state.selection.node &&
    state.selection.node.type === state.schema.nodes[imageNode]
  ) {
    attrs = state.selection.node.attrs;
  }

  React.useEffect(() => {
    if (windowRef.current && windowRef.current.windowElement.style.height) {
      if (selected === 0) {
        windowRef.current.windowElement.style.height = files.length
          ? "510px"
          : "460px";
      } else {
        windowRef.current.windowElement.style.height = "420px";
      }
    }
  }, [selected, files]);

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <ABKLabel id="k-editor-image-alt">
          Alternativer Text
          <ABKInfoPopup
            popupContent={
              "Der alternative Text wird angezeigt, wenn das Bild nicht geladen werden konnte."
            }
          />
        </ABKLabel>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="k-editor-image-alt"
          defaultValue={attrs.alt}
          ref={(e) => (altText = e)}
        />
      </div>
      <div className="k-edit-label">
        <ABKLabel id="k-editor-image-title">
          Titel
          <ABKInfoPopup
            popupContent={
              "Der Titel wird angezeigt, wenn man mit der Maus über dem Bild bleibt."
            }
          />
        </ABKLabel>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="k-editor-image-title"
          defaultValue={attrs.title}
          ref={(e) => (title = e)}
        />
      </div>
      <div className="k-edit-label">
        <Label id="k-editor-image-width">Breite (px)</Label>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="k-editor-image-width"
          defaultValue={attrs.width}
          ref={(e) => (width = e)}
        />
      </div>
      <div className="k-edit-label">
        <Label id="k-editor-image-height">Höhe (px)</Label>
      </div>
      <div className="k-edit-field">
        <Input
          type="text"
          className="k-textbox"
          id="k-editor-image-height"
          defaultValue={attrs.height}
          ref={(e) => (height = e)}
        />
      </div>
    </React.Fragment>
  );

  const buttons = (
    <div className="text-right k-d-flex k-gap-sm" style={{ clear: "both" }}>
      <Button onClick={onClose}>Abbrechen</Button>
      <Button onClick={onInsert}>Einfügen</Button>
    </div>
  );

  return ReactDOM.createPortal(
    <ABKWindow
      className={"ImageWindow"}
      title="Bild einfügen"
      onClose={onClose}
      initialWidth={470}
      height={510}
      ref={windowRef}
    >
      <TabStrip
        className="imagetabstip"
        selected={selected}
        onSelect={onTabSelect}
        animation={false}
      >
        {Object.entries(attrs).length === 0 && (
          <TabStripTab title="Vom PC hochladen">
            <div
              className="k-edit-form-container pt-3 pb-3"
              style={{ height: "400" }}
            >
              <div className="k-edit-label">
                <Label id="k-editor-image-width">Bild</Label>
              </div>
              <div className="k-edit-field">
                <Upload
                  batch={false}
                  multiple={true}
                  defaultFiles={[]}
                  withCredentials={false}
                  saveUrl={
                    "https://demos.telerik.com/kendo-ui/service-v4/upload/save"
                  }
                  removeUrl={
                    "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
                  }
                  onAdd={onAddFiles}
                />
              </div>
              {fields}
              {buttons}
            </div>
          </TabStripTab>
        )}
        <TabStripTab title="Durch URL hochladen">
          <div
            className="k-edit-form-container pt-3 pb-3"
            style={{ height: "390" }}
          >
            <div className="k-edit-label">
              <Label id="k-editor-image-url">Webadresse</Label>
            </div>
            <div className="k-edit-field">
              <Input
                type="text"
                className="k-textbox"
                id="k-editor-image-url"
                defaultValue={attrs.src}
                disabled={/^data:image/.test(attrs.src || "")}
                ref={(e) => (src = e)}
                autoFocus={true}
              />
            </div>
            {fields}
            {buttons}
          </div>
        </TabStripTab>
      </TabStrip>
      <style>{`.k-dropzone { width: 100%; }`}</style>
    </ABKWindow>,
    document.body
  );
};
