import ABKToolbarButton from "src/abk-shared/components/atoms/ABKToolbarButton";
import ABKSearchBar from "src/abk-shared/components/molecules/ABKSearchBar";
import ABKToolbar from "src/abk-shared/components/molecules/ABKToolbar";
import {
  Edit_Delete,
  Grid_InsertEnd,
} from "src/abk-shared/interfaces/abk9SvgIcons";
import "./styles.scss";

interface Props {
  filterValue: string;
  isMobileGrid: boolean;
  showDeleteButton: boolean;
  onFilterChange: (newValue: string) => void;
  onInsertButtonClick: () => void;
  onDeleteButtonClick: () => void;
}

export const PiktogrammToolbar = ({
  filterValue,
  isMobileGrid,
  showDeleteButton,
  onFilterChange,
  onInsertButtonClick,
  onDeleteButtonClick,
}: Props) => {
  return (
    <ABKToolbar
      dataTestId="piktogramm-toolbar"
      leftTools={
        <>
          <ABKToolbarButton
            isMobile={isMobileGrid}
            svgIcon={Grid_InsertEnd}
            title="Hinzufügen"
            onClick={() => {
              onInsertButtonClick();
            }}
          />
          {showDeleteButton && (
            <ABKToolbarButton
              isMobile={isMobileGrid}
              svgIcon={Edit_Delete}
              title="Löschen"
              onClick={() => {
                onDeleteButtonClick();
              }}
            />
          )}
        </>
      }
      rightTools={
        <ABKSearchBar
          isMobileGrid={isMobileGrid}
          filterValue={filterValue}
          onFilterChange={onFilterChange}
        />
      }
    />
  );
};
