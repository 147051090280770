import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { usePrefetchDateiAll } from "src/modules/Datei/queries/usePrefetchDateiAll";
import WrapperTemplate from "../../core/components/templates/WrapperTemplate";
import useDrawerStore from "../../core/stores/useDrawerStore";
import { navigationItems } from "../../Router/Layout/navigationItems";
import useGetContInfo from "../Startseite/hooks/useGetContInfo";
import FileTypeMap from "./Templates/FileTypeMap";

const DateiLayout = () => {
  const params = useParams();
  const db = params.db as string;
  const contId = params.contId as string;
  const plID = params.plID ?? "";
  const { setCurrentDrawerItems } = useDrawerStore();
  const { data } = useGetContInfo(db, contId);

  usePrefetchDateiAll();

  useEffect(() => {
    const contType = data?.value?.CONTTYPE; // "cpl", "cgs" oder "cek"
    if (!contType) return;

    const fileInfo = FileTypeMap.get(contType);
    if (!fileInfo) return;

    const drawerItems = fileInfo?.drawerItems.map((item) =>
      navigationItems[item](db, contId, plID)
    );
    setCurrentDrawerItems(drawerItems);
  }, [db, contId, plID, setCurrentDrawerItems, data?.value?.CONTTYPE]);

  return (
    <WrapperTemplate
      includeTitle={true}
      includeDescription={true}
      appBarIncludesDropDown={true}
    >
      <Outlet />
    </WrapperTemplate>
  );
};

export default DateiLayout;
