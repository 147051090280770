import {
  CardHeader,
  CardTitle,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import Highlighter from "react-highlight-words";
import { AbkIconsMap } from "../../../../../abk-shared/interfaces/abk9SvgIcons";
import { SvgIcon } from "@progress/kendo-react-common";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";

type Props = {
  data: ContWithLastChangedModified;
  filterValue: string;
  onCardClick: (containerId: string) => unknown;
};
const DateienCardHeader = ({ data, filterValue, onCardClick }: Props) => (
  <CardHeader className="k-hbox">
    <span className="icon-box">
      {data.ContTypeInfo ? (
        <SvgIcon
          icon={AbkIconsMap.get(data.ContTypeInfo.ICONID)}
          height={40}
          width={40}
        />
      ) : (
        <SvgIcon icon={AbkIconsMap.get("Dummy.Empty")} />
      )}
    </span>
    <div className="title-container">
      <CardTitle
        style={{
          maxWidth: "100%",
          marginLeft: "8px",
          /*
            Wenn wir 60 "W" in "data.BEZ" haben, wird die Card sich vergrößern,
            und ein Scroll-X für die Mobil-Version verursachen.
            Deswegen wollen wir, dass hier ein "overflowWrap" steht, um kein
            Scroll-X zu erstellen.
          */
          overflowWrap: "anywhere",
        }}
      >
        <div
          onClick={() => onCardClick(data.CONTID)}
          style={{ textDecoration: "underline", width: "fit-content" }}
          className="card-title"
        >
          <Highlighter
            data-testid="cardTitle"
            searchWords={[filterValue]}
            textToHighlight={data.BEZ || ""}
          />
        </div>
      </CardTitle>
      <CardSubtitle style={{ margin: 0, marginLeft: "8px" }}>
        <Highlighter
          searchWords={[filterValue]}
          textToHighlight={data.ContTypeInfo.TXT}
        />
      </CardSubtitle>
    </div>
  </CardHeader>
);

export default DateienCardHeader;
