import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import "./style.css";
import useCustomViewHtmlStore from "./useCustomViewHtmlStore";
const CustomViewHtmlHook = () => {
  const { setShowDialogWindow } = useCustomViewHtmlStore();

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDialog = () => setIsOpen(!isOpen);
  const toggleDialogWindow = () => {
    const newIsOpen = !isOpen;
    setShowDialogWindow(newIsOpen);
    setIsOpen(newIsOpen);
  };

  return (
    <Button
      className={`html-button ${isOpen ? "active-editor-button" : "inactive-editor-button"}`}
      style={{
        height: 30,
      }}
      onClick={() => {
        toggleDialogWindow();
        if (isOpen) {
          toggleDialog(); // Call toggleDialog to handle dialog-specific logic
          setIsOpen(!isOpen); // Update the state of isOpen
        }
      }}
      title="HTML-Ansicht"
    >
      &lt;HTML&gt;
    </Button>
  );
};

export default CustomViewHtmlHook;
