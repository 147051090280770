import useGetCont, { RequestType } from "../../hooks/useGetCont";
import ContainerCardGrid from "../../components/organisms/ContainerCardGrid";
import { Loader } from "@progress/kendo-react-indicators";
import useOnClickContainerCardButton from "./useOnClickContainerCardButton";
import { useContainersWithFormattedDate } from "../../../../core/utility/cont";

type Props = {
  db: string;
  containerType: RequestType;
};

export const CONTAINER_CARD_BUTTON = {
  TEXT: "Öffnen",
  ICON: "Navigation.Open",
};

export default function Oeffnen({ db, containerType }: Props) {
  const { data, isLoading } = useGetCont(db, containerType);
  const containersWithFormattedDate = useContainersWithFormattedDate(data);

  const onCardClick = useOnClickContainerCardButton(db);

  if (isLoading) return <Loader />;

  return (
    <ContainerCardGrid
      containerList={containersWithFormattedDate}
      cardProps={{
        buttonText: CONTAINER_CARD_BUTTON.TEXT,
        buttonIcon: CONTAINER_CARD_BUTTON.ICON,
      }}
      onCardClick={onCardClick}
    />
  );
}
