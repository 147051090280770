import axios from "axios";
import { Job } from "../interfaces/Job";

export default async function fetchJobFiles(job: Job) {
  /*
    "job.FilesUrl" ist z.B.: "/db/Test-DB/cont/2024-07-08-17.57.41.7130@jlassi@abk.at/jobs/2024-07-08-17.57.41.7580@jlassi@abk.at/files"
    kann auch sein: "/api/db/Test-DB/cont/2024-07-08-17.57.41.7130@jlassi@abk.at/jobs/2024-07-08-17.57.41.7580@jlassi@abk.at/files"
  */
  const filesUrl = job.FilesUrl.replace("/api/", "/");
  const url = `${window.location.origin}/api${filesUrl}`;
  const files = await axios.get(url);
  return files.data.Items;
}
