import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import ABKExpansionPanel from "src/abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormDropdown from "src/abk-shared/components/atoms/ABKFormDropdown";
import ABKFormFieldInput from "src/abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import ABKForm from "src/abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ParTypePanelsMap";
import { parTypeDisplayedList } from "src/modules/Datei/pages/Parameterlisten/Eintraege/maps";

export default function AllgemeinPanelOhneKennung({
  setPageState,
  pageState,
}: PropsEintragPanel) {
  return (
    <ABKExpansionPanel defaultExpanded={true} title="Allgemein">
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <ABKFormDropdown
                  label="Kategorie"
                  editorId="PARTYP"
                  value={pageState?.ITEMTYPE}
                  data={parTypeDisplayedList}
                  backendKey="ITEMID"
                  onChange={(event: DropDownListChangeEvent) =>
                    setPageState({
                      ...pageState,
                      ITEMTYPE: event.value,
                    } as EintragFrontend)
                  }
                />
                <ABKFormFieldInput
                  label="Bezeichnung"
                  editorId="BEZ"
                  value={pageState?.BEZ}
                  setValue={(value: string) =>
                    setPageState({
                      ...pageState,
                      BEZ: value,
                    } as EintragFrontend)
                  }
                  backendKey="BEZ"
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKExpansionPanel>
  );
}
