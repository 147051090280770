import { Button } from "@progress/kendo-react-buttons";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import {
  Grid_InsertEnd,
  Edit_Edit,
  General_DragDrop,
  Edit_Cancel,
  Edit_Delete,
} from "src/abk-shared/interfaces/abk9SvgIcons";

export interface Tools {
  addItem: boolean;
  dragDrop: boolean;
  deleteAll: boolean;
}

const ListToolBar = ({
  handleAddItem,
  isEditMode,
  setIsEditMode,
  isMobile,
  tools,
}: {
  handleAddItem: () => void;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isMobile?: boolean;
  tools: Tools;
}) => (
  <div style={{ display: "flex", gap: 8 }} data-testid="list-toolbar">
    {tools.addItem && (
      <ButtonGroup>
        <Button
          svgIcon={Grid_InsertEnd}
          onClick={handleAddItem}
          data-testid="add-entry-button"
        />
      </ButtonGroup>
    )}
    {tools.dragDrop && (
      <ButtonGroup>
        {!isMobile ? (
          <>
            <Button
              svgIcon={General_DragDrop}
              data-testid="drag-drop-mode-button"
              onClick={() => setIsEditMode(false)}
              style={!isEditMode ? { backgroundColor: "#B2B2B2" } : undefined}
            />
            <Button
              data-testid="edit-mode-button"
              svgIcon={Edit_Edit}
              onClick={() => setIsEditMode(true)}
              style={isEditMode ? { backgroundColor: "#B2B2B2" } : undefined}
            />
          </>
        ) : (
          <Button
            data-testid="edit-mode-button"
            svgIcon={!isEditMode ? Edit_Edit : Edit_Cancel}
            onClick={() => setIsEditMode(!isEditMode)}
          />
        )}
      </ButtonGroup>
    )}
    {tools.deleteAll && (
      <ButtonGroup>
        <Button
          svgIcon={Edit_Delete}
          onClick={() => alert("Delete All")}
          data-testid="delete-all-button"
        />
      </ButtonGroup>
    )}
  </div>
);

export default ListToolBar;
