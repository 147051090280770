import { useParams } from "react-router-dom";
import { DEFAULT_DB } from "../constants/urls";

export default function useIsValidDB() {
  const params = useParams();
  const db = params.db;

  const isValidDB = !!db && db !== DEFAULT_DB;

  return { isValidDB, db };
}

export function useIsValidDbAndContIdInURL() {
  const params = useParams();
  const db = params.db;
  const contId = params.contId;

  const isValidDB = !!db && db !== DEFAULT_DB;
  const isValidContId = !!contId;
  const isValid = isValidDB && isValidContId;

  return { isValid, db, contId };
}
