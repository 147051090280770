import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import React from "react";
import QuickInfoContentWithMultipleLines from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/QuickInfoContentWithMultipleLines";
import QuickInfoItem from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/QuickInfoItem";
import { ABKError } from "../../../../../../abk-shared/interfaces/Error";
import { Reveal } from "@progress/kendo-react-animation";

export const TECHNICAL_INFORMATION = "Technische Informationen";
export default function TechnicalInformation({
  abkError,
}: {
  abkError: ABKError;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <ExpansionPanel
      expanded={isExpanded}
      title={<p>{TECHNICAL_INFORMATION}</p>}
      onAction={() => setIsExpanded(!isExpanded)}
      className="technical-information"
    >
      <Reveal>
        {isExpanded && (
          <ExpansionPanelContent>
            <QuickInfoItem title="Meldungscode" content={abkError.type} />
            <QuickInfoItem title="HTTP-Statuscode" content={abkError.status} />
            <QuickInfoItem
              title="TechInfo"
              content={
                <QuickInfoContentWithMultipleLines
                  itemContent={abkError.TechInfo}
                />
              }
            />
            <QuickInfoItem
              title="Callstack"
              content={
                <QuickInfoContentWithMultipleLines
                  itemContent={abkError.CallStack}
                />
              }
            />
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
}
