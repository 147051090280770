import useListItem from "../useListItem";
import { useState } from "react";
import "../styles.scss";
import { splitIntoPairs } from "src/abk-shared/utility/splitIntoPairs";
import ListItemButtons from "../ListItem/ListItemButtons";
import PairedColumnsContainer from "./PairedColumnsContainer";
import UpAndDownArrows from "./UpAndDownArrows";
import { DisplayItem } from "..";

interface Props {
  dataItem: any;
  data: any[];
  setData: (data: any[]) => void;
  isEditMode: boolean;
  dataToDisplay: DisplayItem[];
}

const MobileListItem = ({
  dataItem,
  data,
  setData,
  isEditMode,
  dataToDisplay,
}: Props) => {
  const { handleDelete } = useListItem(dataItem, data, setData);

  const [isActive, setIsActive] = useState(false);

  const pairedItems = splitIntoPairs(dataToDisplay);

  const moveUp = () => {
    const index = data.findIndex((item) => item.id === dataItem.id);
    if (index === 0) return;
    const newData = [...data];
    newData[index] = data[index - 1];
    newData[index - 1] = data[index];
    setData(newData);
  };

  const moveDown = () => {
    const index = data.findIndex((item) => item.id === dataItem.id);
    if (index === data.length - 1) return;
    const newData = [...data];
    newData[index] = data[index + 1];
    newData[index + 1] = data[index];
    setData(newData);
  };

  return (
    <div
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      className="mobile-list-item"
      data-testid="list-item"
      style={{
        border: isActive ? "1px solid #B5B5B5" : "1px solid #F5F5F5",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        <UpAndDownArrows moveUp={moveUp} moveDown={moveDown} />
        <PairedColumnsContainer
          pairedItems={pairedItems}
          data={data}
          setData={setData}
          dataItem={dataItem}
          isEditMode={isEditMode}
        />
      </div>

      <ListItemButtons
        handleDelete={handleDelete}
        dataItem={dataItem}
        isMobile
      />
    </div>
  );
};

export default MobileListItem;
