export const changeFavIcon = (url: string) => {
  // Identify the existing favicon link element
  const link: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (!link) {
    // If the favicon element does not exist, create it and append it to the <head>
    const newLink: HTMLLinkElement = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = url; // Use the url parameter for the new favicon
    document.head.appendChild(newLink);
  } else {
    // If it exists, just update the href to the new favicon path
    link.href = url; // Use the url parameter
  }
};
