import { GridCustomHeaderCellProps } from "@progress/kendo-react-grid";
import React, { CSSProperties } from "react";
import { GridSelectionProps } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  FIELD_DRAG_AND_DROP,
  FIELD_SELECTED,
  isFieldOfActionColumn,
} from "../../hooks/useGridColumns/gridActionColumns";
import { GridColumnPropsCustom } from "../../interfaces/GridColumns";
import getPropsFromColumns from "./getPropsFromColumns";
import usePreventDraggingColumnMenuButton from "./usePreventDraggingColumnMenuButton";

type Props = GridCustomHeaderCellProps & {
  columns: GridColumnPropsCustom[];
  isColumnMenuActive: (columnField: string | undefined) => boolean;
  numberOfSelection: number;
  total: number;
  selection?: GridSelectionProps;
};
export default function HeaderCustomCell(props: Props) {
  let className = props.thProps?.className ?? "";
  let style: CSSProperties = {
    ...props.thProps?.style,
    lineHeight: "normal", // Um den Text im Header nicht zu schneiden
  };

  const { headerIsColumnGroup, tooltip } = React.useMemo(
    () => getPropsFromColumns(props.columns, props.field),
    [props.columns, props.field]
  );

  if (headerIsColumnGroup) {
    className = `${className} k-justify-content-center`;
    style.fontSize = "smaller";
  }

  const isActive = props.isColumnMenuActive(props.field);
  if (isActive) className = `${className} active-column-menu`;

  if (props.field === FIELD_DRAG_AND_DROP)
    className = `${className} drag-handle-cell`;

  if (props.field === FIELD_SELECTED) {
    className = `${className} checkbox-cell .test-checkbox-toolbar-header`;
    if (
      props.numberOfSelection > 0 &&
      props.numberOfSelection !== props.total
    ) {
      className = `${className} checkbox-indeterminate`;
    }
  }

  /*
    Verhindere, dass der Anwender mit den Gruppenspalten ein Resizing macht.
    Der Grund dafür ist, die Breite dieser Spalten sind nicht im localStorage gespeichert.
  */
  const columnCanBeResized =
    !isFieldOfActionColumn(props.field) && !headerIsColumnGroup;

  const thProps = Object.assign({}, props.thProps);
  // Entferne React Warnungen
  if (thProps) {
    const ariaSortValue = thProps.ariaSort;
    delete thProps.ariaSort;
    thProps["aria-sort"] = ariaSortValue;

    const ariaColumnIndexValue = thProps.ariaColumnIndex;
    delete thProps.ariaColumnIndex;
    thProps["aria-colindex"] = ariaColumnIndexValue;

    const ariaSelectedValue = thProps.ariaSelected;
    delete thProps.ariaSelected;
    thProps["aria-selected"] = ariaSelectedValue;

    const ariaDescriptionValue = thProps.ariaDescription;
    delete thProps.ariaDescription;
    thProps["aria-description"] = ariaDescriptionValue;

    const columnIdValue = thProps.columnId;
    delete (thProps as any).columnId;
    (thProps as any)["columnid"] = columnIdValue;
  }

  thProps.title = tooltip ? tooltip : props.title;
  thProps.className = className;
  thProps.style = style;

  const thElementRef = usePreventDraggingColumnMenuButton();

  if (columnCanBeResized) {
    return (
      <th {...thProps} ref={thElementRef} key={thProps?.key}>
        {props.children}
      </th>
    );
  }

  let childrenWithoutResizer = props.children;
  if (props.children && Array.isArray(props.children))
    childrenWithoutResizer = props.children.slice(0, -1);

  return (
    <th {...thProps} ref={thElementRef} key={thProps?.key}>
      {childrenWithoutResizer}
    </th>
  );
}
