import createFunctionToMigrateColumns from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/persistedColumnsStore/createFunctionToMigrateColumns";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
} from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

export default function createColumnsDefinitionForParameterlisten() {
  const columns = [
    {
      migrationId: "Kenndaten.BEZ",
      field: "Kenndaten.BEZ",
      title: "Bezeichnung",
      orderIndex: 1,
      minWidth: 100,
    },
    {
      migrationId: "Kenndaten.KENNUNG",
      field: "Kenndaten.KENNUNG",
      title: "Kennung",
      orderIndex: 2,
      minWidth: 100,
    },
    {
      migrationId: "Kenndaten.VERSIONSNR",
      field: "Kenndaten.VERSIONSNR",
      title: "Version",
      orderIndex: 3,
      minWidth: 100,
    },
    {
      migrationId: "Kenndaten.VERSIONSDATE",
      field: "Kenndaten.VERSIONSDATE",
      title: "Versionsdatum",
      orderIndex: 4,
      minWidth: 100,
    },
    {
      migrationId: "Kenndaten.STATUS",
      field: "Kenndaten.STATUS",
      title: "Status",
      orderIndex: 5,
      minWidth: 100,
    },
    {
      title: "Format",
      migrationId: "SUBTYPE",
      field: "SUBTYPE",
      orderIndex: 6,
      minWidth: 100,
    },
    {
      migrationId: "DATAID",
      field: "DATAID",
      title: "Data-ID",
      show: false,
      orderIndex: 9,
      minWidth: 100,
    },
  ] as GridColumnPropsCustom[];

  const version = 5;

  const migrate: GridColumnsMigrateFunction = createFunctionToMigrateColumns(
    version,
    columns
  );

  return { columns, version, migrate };
}
