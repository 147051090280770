import { useNavigate } from "react-router-dom";
import ABKCompactMobileCard from "../../../../../../abk-shared/components/molecules/ABKCompactMobileCard";
import ABKBasisGrid from "../../../../../../abk-shared/components/organisms/ABKBasisGrid";
import {
  Parameterliste,
  ParameterlisteFrontend,
} from "../../../../interfaces/parameterlisten";
import useParameterlisten from "./useParameterlisten/useParameterlisten";
import { useParameterlistenState } from "./useParameterlisten/useParameterlistenState";

type Props = {
  dbName: string;
  contId: string;
  parameterlisten: ParameterlisteFrontend[];
  isFetching: boolean;
};

const PLGrid = ({ parameterlisten, dbName, contId, isFetching }: Props) => {
  const { columnsDefinition, customCells, gridActions, isGridLoading } =
    useParameterlisten(dbName, contId, isFetching);
  const navigate = useNavigate();
  const { setSelectedParameterlisten } = useParameterlistenState();

  return (
    <ABKBasisGrid
      data={parameterlisten}
      selection={{
        onSelectionChange: (x) => setSelectedParameterlisten(x as any),
      }}
      columnsDefinition={columnsDefinition}
      persistedDataStateId={{
        sector: "plGrid",
        unique: `plGrid-${dbName}-${contId}`,
      }}
      dataItemKey="DATAID"
      gridActions={gridActions}
      customCells={customCells}
      isLoading={isGridLoading}
      mobileGrid={{
        rowHeight: 40,
        renderRow: (
          rowRef,
          item,
          filterValue,
          isSelected,
          setDataItemSelected
        ) => (
          <ABKCompactMobileCard
            rowRef={rowRef}
            item={item}
            filterValue={filterValue}
            isSelected={isSelected}
            setDataItemSelected={setDataItemSelected}
            onRowClick={(item: Parameterliste) => {
              navigate(`${item.DATAID}`);
            }}
            cardTitle={item.Kenndaten.BEZ}
            cardExtraTitle={item.Kenndaten.KENNUNG}
            cardSubtitle={`Version ${item.Kenndaten.VERSIONSNR}`}
          />
        ),
      }}
    />
  );
};

export default PLGrid;
