import ABKExpansionPanel from "src/abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormFieldInput from "src/abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "src/abk-shared/components/atoms/ABKFormRow";
import ABKForm from "src/abk-shared/components/molecules/ABKForm";
import { PiktogrammItem } from "../piktogramm.types";
import { UploadLogoPiktogramm } from "../UploadLogoPiktogramm/UploadLogoPiktogramm";

interface Props {
  piktogramm: PiktogrammItem;
  setPiktogramm: React.Dispatch<React.SetStateAction<PiktogrammItem>>;
}

export default function PiktogrammDetailsPanel({
  piktogramm,
  setPiktogramm,
}: Props) {
  return (
    <ABKExpansionPanel defaultExpanded={true} title="Details">
      <ABKFormRow>
        <ABKForm
          sections={[
            {
              formElements: [
                <>
                  <ABKFormFieldInput
                    label="Bezeichnung"
                    value={piktogramm.BEZ}
                    editorId="BEZ"
                    setValue={(value: string) =>
                      setPiktogramm((prev) => {
                        return {
                          ...prev,
                          BEZ: value,
                        };
                      })
                    }
                    backendKey="BEZ"
                  />
                  {piktogramm.TYP && (
                    <ABKFormFieldInput
                      label="Dateityp"
                      value={piktogramm.TYP}
                      editorId="TYP"
                      backendKey="TYP"
                    />
                  )}
                  {piktogramm.FILEID && (
                    <ABKFormFieldInput
                      label="Piktogramm-ID"
                      value={piktogramm.FILEID}
                      editorId="FILEID"
                      backendKey="FILEID"
                    />
                  )}
                </>,
              ],
            },
          ]}
        />
        <div className="k-flex-1">
          <div>
            <UploadLogoPiktogramm
              disabled={false}
              imageName={piktogramm.BEZ}
              imageFormat={piktogramm.TYP}
              base64Image={piktogramm.CONTENT}
              setBase64Image={(newBase64: string) =>
                setPiktogramm((prev) => {
                  return {
                    ...prev,
                    CONTENT: newBase64,
                  };
                })
              }
              setFileName={(fileName: string) => {
                setPiktogramm((prev) => {
                  return {
                    ...prev,
                    BEZ: fileName,
                  };
                });
              }}
            />
          </div>
        </div>
      </ABKFormRow>
    </ABKExpansionPanel>
  );
}
