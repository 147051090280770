import { Button } from "@progress/kendo-react-buttons";
import { TestProps } from "../../../../../../abk-shared/interfaces/TestProps";
import { Navigation_Open } from "../../../../../../abk-shared/interfaces/abk9SvgIcons";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import { EintraegeActionHandlers } from "../../Eintraege/useEintraege/useEintraegeActionHandlers";
import "./styles.scss";

import { PropsCustomCell } from "src/abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import CellGridText from "../../Eintraege/useEintraege/CellGridText";

type Props = {
  eintrag: EintragFrontend;
  eintraegeActionHandlers: EintraegeActionHandlers;
  testProps?: TestProps;
  props: PropsCustomCell;
};
const BezeichnungCell = ({
  eintrag,
  eintraegeActionHandlers,
  testProps,
  props,
}: Props) => {
  const handleOnClickBezeichnung = (event: any) => {
    eintraegeActionHandlers.open(event, [eintrag]);
  };

  const text = eintrag.BEZ;

  return (
    <>
      <CellGridText props={props} text={text}>
        <Button
          svgIcon={Navigation_Open}
          fillMode="flat"
          data-testid={testProps?.dataTestid}
          onClick={handleOnClickBezeichnung}
          className="button-in-grid-row"
        >
          <span className="k-text-ellipsis">{text}</span>
        </Button>
      </CellGridText>
    </>
  );
};

export default BezeichnungCell;
