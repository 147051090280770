import { create } from "zustand";

interface CustomViewHtmlStore {
  showDialogWindow: boolean;
  setShowDialogWindow: (showDialogWindow: boolean) => void;
  toggleDialogWindow: () => void;
  resetStore: () => void;
}

const createCustomViewHtmlStore = (
  initialState = { showDialogWindow: false }
) =>
  create<CustomViewHtmlStore>((set) => ({
    ...initialState,
    setShowDialogWindow: (showDialogWindow) =>
      set(() => ({ showDialogWindow })),
    toggleDialogWindow: () =>
      set((state) => ({ showDialogWindow: !state.showDialogWindow })),
    resetStore: () => set(initialState),
  }));

const useCustomViewHtmlStore = createCustomViewHtmlStore();
export default useCustomViewHtmlStore;
