import React from "react";

export default function useIsGridLoading(isFetching: boolean) {
  const [isGridLoading, setIsGridLoading] = React.useState(false);
  React.useEffect(() => {
    setIsGridLoading(isFetching);
  }, [isFetching]);

  return { isGridLoading, setIsGridLoading };
}
