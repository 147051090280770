import _ from "lodash";
import { SortableOnDragOverEvent } from "@progress/kendo-react-sortable";
import { useState, useEffect } from "react";

interface ListViewStateProps {
  data: any[];
  setData: (data: any[]) => void;
}

const useListViewState = ({ data, setData }: ListViewStateProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [componentData, setComponentData] = useState(() =>
    data.map((item) => ({ ...item, id: _.uniqueId() }))
  );

  useEffect(() => {
    const dataWithIds = data.map((item) => ({
      ...item,
      id: item.id || _.uniqueId(),
    }));
    setComponentData(dataWithIds);
  }, [data]);

  const onDragOver = (event: SortableOnDragOverEvent) => {
    // Es benötigt InitialData, um einen komischen bug zu lösen,
    // wo die ganze Seite für 1s weiß blinkt beim Drag and Drop.
    // Vermutung: Interaktion mit anderen Komponenten
    setComponentData(event.newState);
  };

  const updateData = (newData: any[]) => {
    setData(newData);
    setComponentData(newData);
  };

  const handleAddItem = () => {
    updateData([...data, { id: _.uniqueId() }]);
  };

  return {
    componentData,
    onDragOver,
    updateData,
    handleAddItem,
    isEditMode,
    setIsEditMode,
  };
};

export default useListViewState;
