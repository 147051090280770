import { create } from "zustand";

interface MainPageTemplateState {
  mainPageTemplateTitle: string | React.ReactElement;
  setMainPageTemplateTitle: (title: string | React.ReactElement) => void;
  mainPageTemplateDescription: string;
  setMainPageTemplateDescription: (description: string) => void;
}

const useMainPageTemplateState = create<MainPageTemplateState>((set) => ({
  mainPageTemplateTitle: "",
  setMainPageTemplateTitle: (title: string | React.ReactElement) => {
    set(() => ({ mainPageTemplateTitle: title }));
  },
  mainPageTemplateDescription: "",
  setMainPageTemplateDescription: (description: string) => {
    set(() => ({ mainPageTemplateDescription: description }));
  },
}));

export default useMainPageTemplateState;
