import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import {
  KEINE_ZUORDNUNG,
  OHNE_INHALT,
} from "../../../../../../../core/constants/text";
import {
  AWList_Text,
  EintragCheckboxValue,
  GliederungssystemEintragId,
} from "../../../../../interfaces/parameterlisten";
import { IField } from "./IField";

export function generateSummaryGsEintragNummer(
  data: GliederungssystemEintrag[]
) {
  if (!data || data.length == 0) return KEINE_ZUORDNUNG;
  const numbers = data.map((gsEintrag) => gsEintrag.NUMMER);
  return numbers.join("; ");
}

export function generatePreviewGsEintragNummer(
  alleGsEintraege: GliederungssystemEintrag[],
  zugeordneteGsEintraege: GliederungssystemEintragId[]
) {
  if (alleGsEintraege.length === 0 || zugeordneteGsEintraege.length == 0)
    return "";
  const nummern = alleGsEintraege.map((gsEintrag) => {
    if (zugeordneteGsEintraege.some((z) => z.ItemID === gsEintrag.ITEMID)) {
      return gsEintrag.NUMMER;
    }
    return false;
  });
  return nummern.filter(Boolean).join("; ");
}

export default function generatePreviewTextForListOfIFields(fields: IField[]) {
  // case there are no fields
  if (fields.length === 0) return OHNE_INHALT;

  // case all fields are empty -> return ohneInhaltText
  let emptyFields = fields.filter((field) => fieldValueIsEmpty(field));
  if (emptyFields.length === fields.length) return OHNE_INHALT;

  //in case input is empty, then field should not be displayed (neither label nor value)
  let nonEmptyFields = fields.filter((field) => !fieldValueIsEmpty(field));

  let convertedFields = nonEmptyFields.map((field) => {
    if (field.isCheckbox) return field.label; // case it is checkbox -> only return the label
    return field.label + ": " + String(field.value);
  });

  let previewText = convertedFields.join("; ");
  return previewText;
}

export function generatePreviewTextForAuswahlliste(
  aWList_Text: AWList_Text[] | null
) {
  return aWList_Text?.length ? `${aWList_Text?.length} Einträge` : "0 Einträge";
}

export function generatePreviewForTextArea(field: IField | undefined) {
  if (field === undefined) return "";
  if (field.value === "" || field.value === undefined || field.value === null)
    return OHNE_INHALT;
  return String(field.value);
}

function fieldValueIsEmpty(field: IField) {
  const fieldValue = field.value;

  if (field.isCheckbox) return fieldValue === EintragCheckboxValue.notSelected;

  return fieldValue === "" || fieldValue === undefined || fieldValue === null;
}
