import { PropsCustomCell } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import BezeichnungCell from "../../WeitereParameterlisten/Parameterlisten/BezeichnungCell";
import CellGridText from "./CellGridText";
import GridCheckBox from "./GridCheckbox";
import { EintraegeActionHandlers } from "./useEintraegeActionHandlers";

const createEintraegeCustomCells = (
  eintraegeActionHandlers: EintraegeActionHandlers
) => [
  {
    columnField: "REQUIRED",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.REQUIRED}
      />
    ),
  },
  {
    columnField: "FAVORIT",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.FAVORIT}
      />
    ),
  },
  {
    columnField: "MENGENABH",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.MENGENABH}
      />
    ),
  },
  {
    columnField: "BEZ",
    render: (props: PropsCustomCell) => (
      <BezeichnungCell
        eintrag={props.dataItem}
        eintraegeActionHandlers={eintraegeActionHandlers}
        testProps={{ dataTestid: "bezeichnungCell" }}
        props={props}
      />
    ),
  },
  {
    columnField: "ITEMTYPE",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.ITEMTYPE} props={props} />
    ),
  },
  {
    columnField: "VARNAME",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.VARNAME} props={props} />
    ),
  },
  {
    columnField: "EH",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.EH} props={props} />
    ),
  },
  {
    columnField: "SUMMEBILDEN",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.SUMMEBILDEN}
      />
    ),
  },
  {
    columnField: "SHOWIF",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.SHOWIF} props={props} />
    ),
  },
  {
    columnField: "ITEMID",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.ITEMID} props={props} />
    ),
  },
  {
    columnField: "Funktion_string",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.Funktion_string} props={props} />
    ),
  },
  {
    columnField: "Produkt_string",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.Produkt_string} props={props} />
    ),
  },

  {
    columnField: "AWList_Num",
    render: (props: PropsCustomCell) => (
      <CellGridText
        text={
          props.dataItem.AWList_Num &&
          props.dataItem.AWList_Num.map((n: number) => n).join("; ")
        }
        props={props}
      />
    ),
  },
  {
    columnField: "AWList_Text_string",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.AWList_Text_string} props={props} />
    ),
  },
  {
    columnField: "FORMEL",
    render: (props: PropsCustomCell) => (
      <CellGridText text={props.dataItem.FORMEL} props={props} />
    ),
  },
  {
    columnField: "AWUSEREN",
    render: (props: PropsCustomCell) => (
      <GridCheckBox
        tdProps={props.tdProps}
        booleanValue={props.dataItem.AWUSEREN}
      />
    ),
  },
];
export default createEintraegeCustomCells;
