import { cloneDeep, isArray } from "lodash";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
  PersistedColumns,
} from "src/abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

export default function createFunctionToMigrateColumns(
  currentVersion: number,
  currentColumnsDefinition: GridColumnPropsCustom[]
) {
  /**
   * function as described in https://zustand.docs.pmnd.rs/integrations/persisting-store-data#migrate
   */
  const migrateColumns: GridColumnsMigrateFunction = (
    persistedColumns,
    version
  ) => {
    if (version < currentVersion) {
      if (!isArray((persistedColumns as any)?.columns)) {
        // Not migratable: Reset columns to default
        (persistedColumns as PersistedColumns).columns = cloneDeep(
          currentColumnsDefinition
        );
        return persistedColumns as PersistedColumns;
      }
      const oldCopy = cloneDeep((persistedColumns as PersistedColumns).columns);
      // To migrate versions, we set columnsDefinition as default.
      (persistedColumns as PersistedColumns).columns = cloneDeep(
        currentColumnsDefinition
      );
      // Then we look into old persistedColumns to copy the saved values into new persistedColumns
      oldCopy.forEach((columnCopy) => {
        const currentObj = (persistedColumns as PersistedColumns).columns.find(
          (x) => x.migrationId === columnCopy.migrationId
        );
        if (!currentObj) {
          return;
        }
        // This works because objects are call-by-reference, so we change the original object persistedColumns here
        currentObj.show = columnCopy.show;
        currentObj.manualWidth = columnCopy.manualWidth;
        currentObj.orderIndex = columnCopy.orderIndex;
        if (isArray(columnCopy.children)) {
          columnCopy.children.forEach((childCopy: GridColumnPropsCustom) => {
            const currentChild = currentObj.children?.find(
              (x: GridColumnPropsCustom) =>
                x.migrationId === childCopy.migrationId
            ) as GridColumnPropsCustom;
            if (currentChild) {
              currentChild.show = childCopy.show;
              currentChild.manualWidth = childCopy.manualWidth;
              currentChild.orderIndex = childCopy.orderIndex;
            }
          });
        }
      });

      return persistedColumns as PersistedColumns;
    }

    // Newest version
    return persistedColumns as PersistedColumns;
  };

  return migrateColumns;
}
