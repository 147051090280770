import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GliederungssystemEintraegeBackendResponse } from "src/modules/Datei/interfaces/gliederungssysteme.types";

export const QUERY_KEY_GLIEDERUNGSSYSTEM_EINTRAEGE =
  "QUERY_KEY_GLIEDERUNGSSYSTEM_EINTRAEGE";

function useGetGliederungssystemEintraege(
  dbName: string,
  contId: string,
  dataId: string | undefined,
  enabled: boolean
) {
  return useQuery({
    queryKey: [QUERY_KEY_GLIEDERUNGSSYSTEM_EINTRAEGE, dbName, contId, dataId],
    queryFn: async () => {
      const response =
        await axios.get<GliederungssystemEintraegeBackendResponse>(
          `/api/db/${dbName}/cont/${contId}/gs/${dataId}/items`
        );
      return response.data;
    },
    enabled: enabled && dataId !== undefined,
  });
}

export default useGetGliederungssystemEintraege;
