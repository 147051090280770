import { getter } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridCustomCellProps,
  GridHandle,
} from "@progress/kendo-react-grid";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { debounce } from "lodash";
import React from "react";
import { ABKBasisGridProps } from ".";
import ABKBasisGridContextMenu from "./components/ABKBasisGridContextMenu";
import ABKBasisGridToolbar from "./components/ABKBasisGridToolbar";
import CustomCell from "./components/CustomCell";
import HeaderCustomCell from "./components/HeaderCustomCell";
import LoadingPanel from "./components/LoadingPanel";
import createColumns from "./createColumns";
import useExcelExport from "./hooks/useExcelExport";
import useGridColumns from "./hooks/useGridColumns/useGridColumns";
import useGridContextMenu from "./hooks/useGridContextMenu";
import useGridDataState from "./hooks/useGridDataState/useGridDataState";
import useGridRowSelection from "./hooks/useGridRowSelection/useGridRowSelection";
// Diese Datei war importiert von: https://github.com/telerik/kendo-react-messages/blob/master/messages/de-DE/de-DE.json
import { DragAndDrop } from "@progress/kendo-react-common";
import { OnRefChangeType } from "react-resize-detector/build/types/types";
import { getDefaultValuesForSelectionProps } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectonInitialization";
import ABKMessageNoRecords from "../../atoms/ABKMessageNoRecords";
import BasisGridContextProvider from "./BasisGridContextProvider";
import GroupHeaderCustomCell from "./components/HeaderCustomCell/GroupHeaderCustomCell";
import { DraggableRow } from "./components/rowsReordering/DraggableRow";
import { DragHint } from "./components/rowsReordering/DragHint";
import VirtualizedMobileGridRows from "./components/VirtualizedMobileGridRows";
import { GRID_ROW_HEIGHT } from "./constants";
import germanMessages from "./de-DE.json";
import useFieldsWithInternalValue from "./hooks/useFieldsWithInternalValue";
import { FIELD_SELECTED } from "./hooks/useGridColumns/gridActionColumns";
import CheckboxCell from "./hooks/useGridColumns/gridActionColumns/CheckboxCell";
import filtersAreActive from "./hooks/useGridDataState/filtersAreActive";
import useGridReorderRows from "./hooks/useGridReorderRows";
import useSetInitialScrollTop from "./hooks/useSetInitialScrollTop";
import { setScrollTopLocalStorage } from "./hooks/useSetInitialScrollTop/scrollTopLocalStorage";
import { FunctionIdGetter } from "./interfaces/FunctionIdGetter.types";
import { isContextMenuOnGroupedColumnHeader } from "./utils/groupingFunctions";

// Inspired from: https://www.telerik.com/kendo-react-ui/components/grid/globalization/
const language = "de-DE";
loadMessages(germanMessages, language);

type Props = ABKBasisGridProps & {
  isMobileGrid: boolean;
  wrapperGridRef: OnRefChangeType<HTMLDivElement>;
  isGroupable?: boolean;
  sortable?: boolean;
};

export default function BasisGrid({
  data,
  columnsDefinition,
  persistedDataStateId,
  dataItemKey,
  mobileGrid,
  gridActions,
  isLoading,
  customCells,
  wrapperGridRef,
  isMobileGrid,
  selection,
  groupable = true,
  sortable = true,
  reorderRowsConfig,
  hasToolbar = true,
}: Props) {
  const gridRef = React.useRef<GridHandle>(null);
  useSetInitialScrollTop(gridRef, isMobileGrid, persistedDataStateId.unique);

  const idGetter = React.useMemo(
    () => getter(dataItemKey) as FunctionIdGetter,
    [dataItemKey]
  );
  const gridRowSelection = useGridRowSelection(
    data,
    dataItemKey,
    idGetter,
    selection
  );

  const columns = columnsDefinition.columns;

  const gridDataState = useGridDataState(
    data,
    columns,
    persistedDataStateId.sector,
    gridRowSelection.updateSelectedStateAfterFilter
  );

  const { hasCheckboxSelection } = getDefaultValuesForSelectionProps(selection);
  const isReorderingEnabled =
    reorderRowsConfig !== undefined &&
    !filtersAreActive(gridDataState.dataState);

  const gridColumns = useGridColumns(
    persistedDataStateId.sector,
    columnsDefinition,
    wrapperGridRef,
    gridDataState.dataState,
    hasCheckboxSelection,
    isMobileGrid,
    isReorderingEnabled
  );

  const headerSelectionValue = gridRowSelection.checkHeaderSelectionValue(
    gridDataState.dataResult.total
  );
  if (hasCheckboxSelection) {
    const columnWithCheckbox = gridColumns.columnsDisplayed[0];
    // @ts-expect-error `headerSelectionValue` can be `null`: it's the indeterminate state
    columnWithCheckbox.headerSelectionValue = headerSelectionValue;
  }

  const gridContextMenu = useGridContextMenu(
    gridRowSelection.setDataItemSelected
  );

  const dataResultWithSelectedField =
    gridRowSelection.addSelectedFieldToDataResult(gridDataState.dataResult);

  const gridReorderRows = useGridReorderRows(
    reorderRowsConfig,
    isReorderingEnabled,
    gridRowSelection.selectedItems,
    dataResultWithSelectedField.data,
    dataItemKey
  );

  const excelExport = useExcelExport(
    gridColumns.columnsDisplayed,
    dataResultWithSelectedField.data,
    gridDataState.dataState
  );

  const fieldsWithInternalValue = useFieldsWithInternalValue(columns);

  return (
    <BasisGridContextProvider
      gridRef={gridRef}
      gridReorderRows={gridReorderRows}
      gridRowSelection={gridRowSelection}
      dataState={gridDataState.dataState}
      dataResultWithSelectedField={dataResultWithSelectedField.data}
      gridActions={gridActions}
    >
      <DragAndDrop>
        {isLoading && <LoadingPanel />}
        {hasToolbar && (
          <ABKBasisGridToolbar
            filterValue={gridDataState.filterValue}
            onFilterChange={gridDataState.onFilterChange}
            excelExport={{
              disabled: dataResultWithSelectedField.total === 0,
              excelExport: excelExport.excelExport,
            }}
            isMobileGrid={isMobileGrid}
            headerSelectionValue={headerSelectionValue}
            onHeaderSelectionChange={(event) =>
              gridRowSelection.onHeaderSelectionChange(
                event,
                dataResultWithSelectedField.data
              )
            }
            hasCheckboxSelection={hasCheckboxSelection}
            columns={gridColumns.columns}
            onColumnToggleVisibility={gridColumns.onColumnToggleVisibility}
          />
        )}
        <LocalizationProvider language={language}>
          <ExcelExport
            data={dataResultWithSelectedField.data}
            ref={excelExport.ref}
            group={gridDataState.dataState.group}
          >
            {excelExport.columns}
          </ExcelExport>
          {isMobileGrid && mobileGrid ? (
            dataResultWithSelectedField.total === 0 ? (
              <ABKMessageNoRecords />
            ) : (
              <VirtualizedMobileGridRows
                dataItems={dataResultWithSelectedField.data}
                mobileGridProps={mobileGrid}
                filterValue={gridDataState.filterValue}
                setDataItemSelected={gridRowSelection.setDataItemSelected}
                gridUniqueId={persistedDataStateId.unique}
              />
            )
          ) : (
            <Grid
              ref={gridRef}
              className="grid-table"
              data={dataResultWithSelectedField}
              onScroll={(event) => {
                setScrollTopLocalStorage(
                  event.nativeEvent.target,
                  persistedDataStateId.unique,
                  false
                );
              }}
              {...gridDataState.dataState}
              onDataStateChange={gridDataState.onDataStateChange}
              sortable={
                sortable
                  ? {
                      mode: "multiple",
                    }
                  : false
              }
              resizable={true}
              onColumnResize={debounce(gridColumns.onColumnResize, 200)}
              reorderable={true}
              onColumnReorder={gridColumns.onColumnReorder}
              size="small"
              selectable={{
                enabled: !reorderRowsConfig,
                drag: false,
                cell: false,
                mode: "multiple",
              }}
              groupable={groupable}
              onExpandChange={gridDataState.onExpandChange}
              expandField="expanded"
              dataItemKey={dataItemKey}
              selectedField={FIELD_SELECTED}
              onSelectionChange={(event) => {
                gridRowSelection.onSelectionChange(event);
                gridContextMenu.setShow(false);
              }}
              onHeaderSelectionChange={(event) =>
                gridRowSelection.onHeaderSelectionChange(
                  event,
                  dataResultWithSelectedField.data
                )
              }
              onContextMenu={(event) => {
                /*
                  Wir wollen vermeiden, dass ein ContextMenü erscheint, wenn wir
                  auf ein Grouped Column Header klicken.
                  https://ib-data.atlassian.net/browse/ABK9-774
                */
                if (isContextMenuOnGroupedColumnHeader(event)) return;

                gridContextMenu.handleContextMenuOpen(
                  event.syntheticEvent,
                  event.dataItem
                );
              }}
              cells={{
                headerCell: (props) => (
                  <HeaderCustomCell
                    {...props}
                    columns={columns}
                    isColumnMenuActive={gridDataState.isColumnMenuActive}
                    numberOfSelection={gridRowSelection.selectedItems.length}
                    total={gridDataState.dataResult.total}
                    selection={selection}
                  />
                ),
                group: {
                  groupHeader: (props) => (
                    <GroupHeaderCustomCell {...props} columns={columns} />
                  ),
                },
                data: (props: GridCustomCellProps) => (
                  <CustomCell
                    {...props}
                    columns={columns}
                    filterValue={gridDataState.filterValue}
                    customCells={customCells}
                  />
                ),
                select: {
                  data: (props: GridCustomCellProps) => (
                    <CheckboxCell {...props} />
                  ),
                },
              }}
              {...(reorderRowsConfig && {
                rowRender: (row, rowProps) => (
                  <DraggableRow elementProps={row.props} {...rowProps} />
                ),
              })}
              scrollable="virtual"
              rowHeight={GRID_ROW_HEIGHT}
              style={{ height: "auto" }}
              total={gridDataState.dataResult.total}
            >
              {gridColumns.columnsDisplayed.map((parentColumn) =>
                createColumns(
                  "gridColumn",
                  parentColumn,
                  data,
                  fieldsWithInternalValue,
                  gridColumns.setWidth
                )
              )}
            </Grid>
          )}
        </LocalizationProvider>
        <DragHint
          ref={gridReorderRows.hintRef}
          portal={gridRef}
          draggedItems={gridReorderRows.draggedItems}
          dragHintKey={reorderRowsConfig?.dragHintKey}
        />
        <ABKBasisGridContextMenu gridContextMenu={gridContextMenu} />
      </DragAndDrop>
    </BasisGridContextProvider>
  );
}
