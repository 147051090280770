import { KEY_REMOVE_FROM_FILTERING } from "src/abk-shared/components/organisms/ABKBasisGrid/constants";
import createFunctionToMigrateColumns from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/persistedColumnsStore/createFunctionToMigrateColumns";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
} from "src/abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";

const columnsDefinition: GridColumnPropsCustom[] = [
  {
    migrationId: "allgemein",
    field: "Allgemein",
    title: "Allgemein",
    orderIndex: 1000,
    minWidth: 100,
    children: [
      {
        migrationId: "allgemein.VARNAME",
        field: "VARNAME",
        title: "Parameterkennung",
        orderIndex: 1001,
        minWidth: 100,
      },
      {
        migrationId: "allgemein.ITEMTYPE",
        field: "ITEMTYPE",
        title: "Kategorie",
        orderIndex: 1002,
        minWidth: 100,
      },
      {
        migrationId: "allgemein.BEZ",
        field: "BEZ",
        title: "Bezeichnung",
        orderIndex: 1003,
        minWidth: 100,
      },
      {
        migrationId: "allgemein.FAVORIT",
        field: "FAVORIT",
        title: "Favorit",
        orderIndex: 1004,
        minWidth: 100,
        [KEY_REMOVE_FROM_FILTERING]: true,
      },
      {
        migrationId: "allgemein.SHOWIF",
        field: "SHOWIF",
        title: "Ausblenden",
        show: false,
        orderIndex: 1005,
        minWidth: 100,
      },
      {
        migrationId: "allgemein.REQUIRED",
        field: "REQUIRED",
        title: "Erforderlich",
        orderIndex: 1006,
        minWidth: 100,
        [KEY_REMOVE_FROM_FILTERING]: true,
      },
      {
        migrationId: "allgemein.ITEMID",
        field: "ITEMID",
        title: "Parameter-ID",
        show: false,
        orderIndex: 1007,
        minWidth: 100,
      },
      {
        migrationId: "allgemein.PIKTID",
        field: "PIKTID",
        title: "Piktogramm-ID",
        show: false,
        orderIndex: 1008,
        width: 100,
      },
    ],
  },
  {
    migrationId: "zahl",
    field: "Zahl",
    title: "Zahl",
    orderIndex: 2000,
    show: false,
    minWidth: 100,
    children: [
      {
        migrationId: "zahl.NUMNK",
        field: "NUMNK",
        title: "Nachkommastellen",
        orderIndex: 2001,
        minWidth: 100,
        show: false,
        hasInternalValue: true,
      },
      {
        migrationId: "zahl.NUMMIN",
        field: "NUMMIN",
        title: "Minimalwert",
        orderIndex: 2002,
        minWidth: 100,
        show: false,
        hasInternalValue: true,
      },
      {
        migrationId: "zahl.NUMMAX",
        field: "NUMMAX",
        title: "Maximalwert",
        orderIndex: 2003,
        minWidth: 100,
        show: false,
        hasInternalValue: true,
      },
      {
        migrationId: "zahl.FORMEL",
        field: "FORMEL",
        title: "Rechenansatz",
        orderIndex: 2004,
        show: false,
      },
      {
        migrationId: "zahl.AWList_Num_string",
        field: "AWList_Num_string",
        title: "Auswahlliste bei Typ Zahl",
        orderIndex: 2005,
        width: 100,
        show: false,
      },
      {
        migrationId: "zahl.MENGENABH",
        field: "MENGENABH",
        title: "Mengenabhängig",
        orderIndex: 2006,
        minWidth: 100,
        [KEY_REMOVE_FROM_FILTERING]: true,
        show: false,
      },
      {
        migrationId: "zahl.EH",
        field: "EH",
        title: "Einheit",
        orderIndex: 2007,
        width: 100,
        show: false,
      },
      {
        migrationId: "zahl.SUMMEBILDEN",
        field: "SUMMEBILDEN",
        title: "Summe bilden",
        orderIndex: 2008,
        width: 100,
        show: false,
      },
    ],
  },
  {
    migrationId: "text",
    field: "Text",
    title: "Text",
    show: false,
    orderIndex: 3000,
    children: [
      {
        migrationId: "text.AWList_Text_string",
        field: "AWList_Text_string",
        title: "Auswahlliste bei Typ Text einzeilig",
        orderIndex: 3001,
        width: 100,
        show: false,
      },
    ],
  },
  {
    migrationId: "zuordnungen",
    field: "Zuordnungen",
    title: "Zuordnungen",
    show: false,
    orderIndex: 4000,
    children: [
      {
        migrationId: "zuordnungen.Funktion_string",
        field: "Funktion_string",
        title: "Funktion",
        orderIndex: 4001,
        width: 100,
        show: false,
      },
      {
        migrationId: "zuordnungen.Produkt_string",
        field: "Produkt_string",
        title: "Produktgruppe",
        orderIndex: 4002,
        width: 100,
        show: false,
      },
    ],
  },
  {
    migrationId: "sonstige",
    field: "Sonstige",
    title: "Sonstige",
    show: false,
    orderIndex: 5000,
    width: 100,
    children: [
      {
        migrationId: "AWUSEREN",
        field: "AWUSEREN",
        title: "Benutzereingabe für Auswahlliste",
        orderIndex: 5001,
        width: 100,
        show: false,
        [KEY_REMOVE_FROM_FILTERING]: true,
      },
    ],
  },
];

const EINTRAEGE_COLUMN_DEFINITION_VERSION = 8;

const migrate: GridColumnsMigrateFunction = createFunctionToMigrateColumns(
  EINTRAEGE_COLUMN_DEFINITION_VERSION,
  columnsDefinition
);

const createEintraegeColumnsDefinition = () => ({
  columns: columnsDefinition,
  version: EINTRAEGE_COLUMN_DEFINITION_VERSION,
  migrate,
});

export default createEintraegeColumnsDefinition;
