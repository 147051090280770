import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { GridSelectionProps } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { GenericObject } from "../../../interfaces/GenericObject";
import { isMobile } from "../../../utility/detectDevice";
import { ABKLoader } from "../../atoms/ABKLoader";
import BasisGrid from "./BasisGrid";
import { CustomCellObject } from "./components/CustomCell";
import { MobileGridProps } from "./components/VirtualizedMobileGridRows";
import { ReorderRowsConfig } from "./hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { CreateGridActionsFunction } from "./interfaces/GridAction";
import { GridColumnsDefinition } from "./interfaces/GridColumns";
import "./style.scss";

type LocalStorageId = {
  sector: string;
  unique: string;
};

export type ABKBasisGridProps = {
  data: GenericObject[];
  columnsDefinition: GridColumnsDefinition;
  persistedDataStateId: LocalStorageId;
  dataItemKey: string;
  mobileGrid?: MobileGridProps;
  gridActions?: CreateGridActionsFunction;
  isLoading?: boolean;
  customCells?: CustomCellObject[];
  groupable?: boolean;
  sortable?: boolean;
  selection?: GridSelectionProps;
  reorderRowsConfig?: ReorderRowsConfig;
  hasToolbar?: boolean;
  enableMobileGrid?: boolean;
};

const ABKBasisGrid: React.FC<ABKBasisGridProps> = (
  props: ABKBasisGridProps
) => {
  const enableMobileGrid =
    props.enableMobileGrid === undefined ? true : props.enableMobileGrid;

  const { width, ref: wrapperGridRef } = useResizeDetector();
  const isCalculatingWidth = width == null;
  const isSmallGrid = !isCalculatingWidth && width < 600;
  const isMobileGrid = enableMobileGrid && (isMobile() || isSmallGrid);

  let classNameWrapperGrid = "wrapper-grid";
  if (isCalculatingWidth) classNameWrapperGrid += " center-loader";

  return (
    <div ref={wrapperGridRef} className={classNameWrapperGrid}>
      {isCalculatingWidth ? (
        <ABKLoader />
      ) : (
        <BasisGrid
          {...props}
          isMobileGrid={isMobileGrid}
          wrapperGridRef={wrapperGridRef}
        />
      )}
    </div>
  );
};

export default ABKBasisGrid;
