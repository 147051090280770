import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import ABKConfirmationDialog from "../ABKConfirmationDialog";
import RawSaveBalken from "./RawSaveBalken";
import "./style.scss";

export interface BalkenProps {
  edited: boolean;
  conflictMessage?: string;
  onSave: () => void;
  onReset: () => void;
  height?: number;
  buttonsAreUnderTheText?: boolean;
  isInline?: boolean;
  disabledSaveButton?: boolean;
  saveButtonText?: "Speichern" | "Erstellen";
}

const ABKSaveBalken: React.FC<BalkenProps> = (props) => {
  const [showDialog, setShowDialog] = React.useState(false);

  useHotkeys(
    "ctrl+s",
    () => {
      props.onSave();
    },
    {
      enabled: props.edited,
      enableOnFormTags: true,
      preventDefault: true,
    },
    [props.onSave, props.edited]
  );

  if (!props.edited) {
    return <></>;
  }

  return (
    <>
      {showDialog && (
        <ABKConfirmationDialog
          message={
            <p
              style={{
                margin: "25px",
                textAlign: "center",
              }}
            >
              Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?
            </p>
          }
          onConfirm={() => {
            setShowDialog(false);
            props.onReset();
          }}
          onClose={() => setShowDialog(false)}
        />
      )}
      <RawSaveBalken
        inline={props.isInline}
        height={props.height}
        balkenMessage={
          props.conflictMessage
            ? props.conflictMessage
            : "Ihre Änderungen wurden noch nicht gespeichert."
        }
      >
        {!props.conflictMessage && (
          <>
            <Button
              themeColor="primary"
              onClick={() => props.onSave()}
              disabled={props.disabledSaveButton}
              data-testid="save-balken-button-confirm"
            >
              {props.saveButtonText ?? "Speichern"}
            </Button>
          </>
        )}
        <Button
          data-testid="save-balken-button-cancel"
          onClick={() => setShowDialog(true)}
        >
          Verwerfen
        </Button>
      </RawSaveBalken>
    </>
  );
};

export default ABKSaveBalken;
