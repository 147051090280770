import { useMutation } from "@tanstack/react-query";
import axios from "axios";

type MutationCancelJobData = {
  db: string;
  contId: string;
  jobId: string;
};

function useCancelJob() {
  return useMutation({
    mutationFn: ({ db, contId, jobId }: MutationCancelJobData) => {
      return axios.delete(
        `${window.location.origin}/api/db/${db}/cont/${contId}/jobs/${jobId}`
      );
    },
  });
}

export default useCancelJob;
