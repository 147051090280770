import React from "react";

const useMutationObserver = (
  callback: (
    mutationList: MutationRecord[],
    observer: MutationObserver
  ) => void,
  options: MutationObserverInit
) => {
  React.useEffect(() => {
    const observer = new MutationObserver(callback);

    observer.observe(document.body, options);

    return () => {
      observer.disconnect();
    };
  }, [callback, options]);
};

export default useMutationObserver;
