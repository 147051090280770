import { Input } from "@progress/kendo-react-inputs";
import React from "react";
import useGetValidationErrorMessage from "../../../stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import {
  addClassNameInputError,
  addClassNameInputReadOnly,
} from "../../../utility/addClassNameFormInput";
import ABKFormErrorMessage from "../ABKFormErrorMessage";
import ABKFormFieldInputWrapper from "../ABKFormFieldInputWrapper";
import ABKInfoPopup from "../ABKInfoPopup";
import ABKLabel from "../ABKLabel";

interface AbkFormFieldInputProps {
  editorId: string;
  tooltip?: string;
  title?: string;
  label?: string;
  placeHolder?: string;
  value: string | undefined | React.ReactElement;
  setValue?: (value: string) => void;
  className?: string;
  useJSXForValue?: boolean;
  backendKey?: string;
  type?: "text" | "number";
}

const ABKFormFieldInput = (props: AbkFormFieldInputProps): JSX.Element => {
  const readOnly = !props.setValue;
  const errorMessage = useGetValidationErrorMessage(props.backendKey);

  let classNameInput = "";
  classNameInput = addClassNameInputReadOnly(classNameInput, readOnly);
  classNameInput = addClassNameInputError(classNameInput, errorMessage);
  if (props.className) classNameInput += " " + props.className;

  return (
    <ABKFormFieldInputWrapper>
      {props.label && (
        <ABKLabel editorId={props.editorId}>
          {props.label}
          {props.tooltip && <ABKInfoPopup popupContent={props.tooltip} />}
        </ABKLabel>
      )}
      {readOnly && props.useJSXForValue ? (
        <span className={props.className}>{props.value}</span>
      ) : (
        <div>
          <Input
            type={props.type || "text"}
            className={classNameInput}
            id={props.editorId}
            name={props.editorId}
            placeholder={readOnly ? "" : props.placeHolder}
            title={props.value as string | undefined}
            value={props.value as string | undefined}
            {...(readOnly
              ? { readOnly: true }
              : {
                  onChange: (e) =>
                    props.setValue && props.setValue(e.target.value as string),
                })}
          />
        </div>
      )}
      {errorMessage && (
        <ABKFormErrorMessage>{errorMessage}</ABKFormErrorMessage>
      )}
    </ABKFormFieldInputWrapper>
  );
};

export default ABKFormFieldInput;
