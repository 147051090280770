import { useLiveQuery } from "dexie-react-hooks";
import { dbJobs } from "../db/dbJobs";

export default function useJobsDisplayed(dbName: string | undefined) {
  return useLiveQuery(() => {
    if (!dbName) {
      return [];
    } else {
      return dbJobs.jobsDisplayed.where("database").equals(dbName).toArray();
    }
  }, [dbName]);
}
