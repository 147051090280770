import React, { ReactNode } from "react";
import {
  getNestedValue,
  isFilterPatternMatchingValue,
} from "../../utils/helperFunctions";
import ABKBasisGridHighlighter from "../ABKBasisGridHighlighter";
import { PropsCustomCell } from ".";
import useFieldsWithInternalValue from "../../hooks/useFieldsWithInternalValue";
import { DATA_KEY } from "../../constants";
import { GridCustomCellProps } from "@progress/kendo-react-grid";

const HighlightedCell = (props: PropsCustomCell) => {
  const fieldsWithInternalValue = useFieldsWithInternalValue(props.columns);
  let field = props.field;
  const hasInternalValue = field && fieldsWithInternalValue.includes(field);
  if (hasInternalValue) field = `${field}.${DATA_KEY.DISPLAYED}`;

  const value = getNestedValue(field, props.dataItem)?.toString();

  if (props.tdProps)
    props.tdProps.style = { ...props.tdProps.style, whiteSpace: "nowrap" };
  const { children, ...propsWithoutChildren } = props;
  if (value == null) {
    return (
      <Cell {...propsWithoutChildren} value={value}>
        {children}
      </Cell>
    );
  }

  const filterValue = props.filterValue;
  const cellValueMatchesSearch =
    !!filterValue &&
    (filterValue as any).length > 0 &&
    isFilterPatternMatchingValue(filterValue, value);

  const cellChildren = hasInternalValue ? value : children;
  if (!cellValueMatchesSearch)
    return (
      <Cell {...propsWithoutChildren} value={value}>
        {cellChildren}
      </Cell>
    );

  const childrenHighlighted = highlightSearchTextInReactChildren(
    cellChildren,
    filterValue
  );
  return React.cloneElement(
    <Cell {...propsWithoutChildren} value={value}>
      {cellChildren}
    </Cell>,
    propsWithoutChildren,
    [childrenHighlighted]
  );
};

export const Cell = (props: GridCustomCellProps & { value?: string }) => (
  <td {...props.tdProps} title={props.value}>
    {props.children}
  </td>
);

/*
  The code in the following function is inspired by the following example:
  https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-search/
*/

function highlightSearchTextInReactChildren(
  children: ReactNode,
  filterValue: string
) {
  function highlightInNode(node: string | ReactNode): string | ReactNode {
    if (typeof node === "string")
      return <ABKBasisGridHighlighter filterValue={filterValue} node={node} />;

    if (React.isValidElement(node)) {
      if (!(node.props as any).children?.map) {
        return React.cloneElement(
          node,
          {},
          highlightInNode((node.props as any).children)
        );
      }

      return React.cloneElement(
        node,
        {},
        (node.props as any).children?.map((ch: string | ReactNode) =>
          highlightInNode(ch)
        )
      );
    }

    return node;
  }

  return React.Children.map(children, (child) => {
    return highlightInNode(child as ReactNode | string);
  });
}

export default HighlightedCell;
