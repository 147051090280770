import { RequestType } from "../../hooks/useGetCont";
import Oeffnen from "./Oeffnen";
import useIsValidDB from "../../../../core/utility/useIsValidDB";

type Props = {
  containerType: RequestType;
};

export default function OeffnenWrapper({ containerType }: Props) {
  const { isValidDB, db } = useIsValidDB();
  if (!isValidDB) return <></>;

  const validDB = db as string;
  return (
    <Oeffnen
      key={`oeffnen-${validDB}-${containerType}`}
      db={validDB}
      containerType={containerType}
    />
  );
}
