import { Meta } from "src/core/interfaces/meta.types";
import { LogoFormat } from "src/core/utility/imageProcessing";
import {
  Herausgeber,
  Kenndaten,
} from "src/modules/Datei/interfaces/parameterlisten";

export interface GliederungssystemeBackendResponse {
  Meta: Meta;
  Items: Gliederungssystem[];
}

export enum GliederungssystemSubtype {
  Funktion = "Funkt",
  Produkt = "Prod",
}
export type ZuordnungType = keyof typeof GliederungssystemSubtype;

export interface Gliederungssystem {
  SUBTYPE: GliederungssystemSubtype;
  Kenndaten: Kenndaten;
  Vorversion: Vorversion;
  ItemFmtDefinition: ItemFmtDefinition;
  DATAID: string;
  CONTID: string;
  DBNAME: string;
  Meta: Meta;
}

interface Vorversion {
  KKWPLDATAID: string;
  KENNUNG: string;
  VERSIONSNR: number;
  VERSIONSDATE: string;
  BEZ: string;
  BESCH: string;
  STATUS: string;
  DLURL: string;
  Herausgeber: Herausgeber;
  LOGOFORMAT: LogoFormat;
  LOGO: string;
}

interface ItemFmtDefinition {
  Ebenen: Ebenen[];
}

interface Ebenen {}

export interface GliederungssystemEintraegeBackendResponse {
  Meta: Meta;
  Items: GliederungssystemEintrag[];
}

export interface GliederungssystemEintrag {
  ITEMID: string;
  NUMMER: string;
  ITEMTYPE: string;
  BEZ: string;
  LANGTEXT: string;
  KOMM: string;
  NOTIZ: string;
  INTANMERK: string;
  ABKUERZ: string;
  HKKZ: string;
  KKWPARNAME: string;
  KKWPROZGR: string;
  PARENTID: string;
  Ebene: string;
  Kostenkennwert: string;
  GrafikLinks: unknown[];
  SummierteItems: unknown[];
  IFCEntities: unknown[];
  InKostenGru: unknown[];
  DATAID: string;
  CONTID: string;
  DBNAME: string;
  Meta: Meta;
}
