const zuordnungColumns = {
  nummer: {
    field: "NUMMER",
    title: "Nummer",
  },
  bezeichnung: {
    field: "BEZ",
    title: "Bezeichnung",
  },
};

export default zuordnungColumns;
