import { DataResult } from "@progress/kendo-data-query";
import {
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { produce } from "immer";
import React from "react";
import {
  createInitialSelectedState,
  getDefaultValuesForSelectionProps,
} from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelectonInitialization";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import { FunctionIdGetter } from "../../interfaces/FunctionIdGetter.types";
import { ungroupDataItems } from "../../utils/groupingFunctions";
import { FIELD_SELECTED } from "../useGridColumns/gridActionColumns";
import getSelectedItems from "./getSelectedItems";
import {
  FunctionSetDataItemSelected,
  GridSelectionProps,
  SelectedState,
} from "./gridRowSelection.types";

function useGridRowSelection(
  data: GenericObject[],
  dataItemKey: string,
  idGetter: FunctionIdGetter,
  selection?: GridSelectionProps
) {
  const { initialSelectedItems, onSelectionChangeFromParent } =
    getDefaultValuesForSelectionProps(selection);

  const initialSelectedState = React.useMemo(
    () => createInitialSelectedState(initialSelectedItems, idGetter),
    [initialSelectedItems, idGetter]
  );

  const [selectedState, setSelectedState] =
    React.useState<SelectedState>(initialSelectedState);

  const triggerOnSelectionChangeFromParent = React.useCallback(
    (newSelectedState: SelectedState, clickedItem?: GenericObject) => {
      if (!onSelectionChangeFromParent) return;

      const selectedItemsUpdated = getSelectedItems(
        data,
        newSelectedState,
        idGetter
      );
      onSelectionChangeFromParent(selectedItemsUpdated, clickedItem);
    },
    [data, idGetter, onSelectionChangeFromParent]
  );

  const setDataItemSelected: FunctionSetDataItemSelected = React.useCallback(
    (
      dataItem: GenericObject,
      isSelected = true,
      shouldOnlySelectCurrentItem = false
    ) => {
      const newSelectedState = produce(selectedState, (draft) => {
        const itemKey = dataItem[dataItemKey];

        if (shouldOnlySelectCurrentItem) {
          for (const itemId of Object.keys(draft)) draft[itemId] = false;
        }

        draft[itemKey] = isSelected;
      });

      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState, dataItem);
    },
    [dataItemKey, selectedState, triggerOnSelectionChangeFromParent]
  );

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const isClickOnRow = !!event.startDataItem;
      if (isClickOnRow)
        return setDataItemSelected(event.startDataItem, true, true);

      const itemKey = event.dataItem[dataItemKey];
      setDataItemSelected(event.dataItem, !selectedState[itemKey]);
    },
    [selectedState, dataItemKey, setDataItemSelected]
  );

  const onHeaderSelectionChange = React.useCallback(
    (
      event: GridHeaderSelectionChangeEvent | CheckboxChangeEvent,
      allItems: GenericObject[]
    ) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = (checkboxElement as HTMLInputElement).checked;
      const newSelectedState = {} as SelectedState;

      const dataItemsUngrouped = ungroupDataItems(allItems);
      dataItemsUngrouped.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    [idGetter, triggerOnSelectionChangeFromParent]
  );

  const checkHeaderSelectionValue = React.useCallback(
    (dataResultTotal: number) => {
      let nbSelectedItems = 0;
      for (const isSelected of Object.values(selectedState)) {
        if (isSelected) nbSelectedItems++;
      }
      if (nbSelectedItems === 0) return false;

      const allItemsAreSelected = nbSelectedItems === dataResultTotal;
      if (allItemsAreSelected) {
        return true;
      }
      const nothingSelected = nbSelectedItems === 0;
      if (nothingSelected) {
        return false;
      }
      // null shows indeterminate checkbox
      return null;
    },
    [selectedState]
  );

  const addSelectedFieldToDataResult = React.useCallback(
    (dataResult: DataResult) => {
      const addSelectedFieldToItem = (item: GenericObject) => {
        if (item.items) {
          return {
            ...item,
            items: item.items.map(addSelectedFieldToItem),
          };
        }

        return {
          ...item,
          [FIELD_SELECTED]: selectedState[idGetter(item)],
        };
      };
      const newData = dataResult.data.map(addSelectedFieldToItem);

      const newDataResult = { ...dataResult, data: newData };
      return newDataResult;
    },
    [idGetter, selectedState]
  );

  /*
    Wenn wir den Filter ändern, wollen wir, dass die Liste der ausgewählten
    Items sich aktualisiert.
    Das behebe den folgenden Bug:
     - Auf der Checkbox im Header klicken, um alle Dateien auszuwählen
     - In der Suche Text eingeben, z.B. FSV
     -> Die Header Checkbox ist nicht mehr selektiert!
     -> Die Elemente, die vorher ausgewählt waren, bleiben selektiert, auch wenn das Grid sie nicht mehr zeigt!
  */
  const updateSelectedStateAfterFilter = React.useCallback(
    (dataResult: DataResult) => {
      const newSelectedState: SelectedState = {};

      for (const dataItem of dataResult.data) {
        const isGrouped = dataItem.hasAggregates;
        if (isGrouped) continue; // Grouping und Auswahl ist jetzt nicht unterstützt

        const itemUniqueIdentifier = dataItem[dataItemKey];
        if (selectedState[itemUniqueIdentifier])
          newSelectedState[itemUniqueIdentifier] = true;
      }
      setSelectedState(newSelectedState);
      triggerOnSelectionChangeFromParent(newSelectedState);
    },
    [triggerOnSelectionChangeFromParent, dataItemKey, selectedState]
  );

  const selectedItems = getSelectedItems(data, selectedState, idGetter);

  return {
    selectedState,
    setSelectedState,
    selectedItems,
    setDataItemSelected,
    onSelectionChange,
    onHeaderSelectionChange,
    checkHeaderSelectionValue,
    addSelectedFieldToDataResult,
    updateSelectedStateAfterFilter,
  };
}

export default useGridRowSelection;
