import {
  DropDownList,
  DropDownListProps,
} from "@progress/kendo-react-dropdowns";
import ABKFormErrorMessage from "src/abk-shared/components/atoms/ABKFormErrorMessage";
import ABKFormFieldInputWrapper from "src/abk-shared/components/atoms/ABKFormFieldInputWrapper";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import useGetValidationErrorMessage from "src/abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { addClassNameInputError } from "src/abk-shared/utility/addClassNameFormInput";

interface Props extends DropDownListProps {
  label: string;
  editorId: string;
  tooltip?: string;
  errorMessage?: React.ReactNode;
  backendKey?: string;
  textField?: string;
  dataItemKey?: string;
  itemRender?: (
    li: React.ReactElement<HTMLLIElement>,
    item: any
  ) => React.ReactNode;
}

export default function ABKFormDropdown({
  label,
  editorId,
  tooltip,
  backendKey,
  textField,
  dataItemKey,
  itemRender,
  ...dropDownListProps
}: Props) {
  const errorMessage = useGetValidationErrorMessage(backendKey);
  let classNameDropDown = dropDownListProps.className ?? "";
  classNameDropDown = addClassNameInputError(classNameDropDown, errorMessage);
  const hasNoValidOptions =
    dropDownListProps?.data === undefined ||
    dropDownListProps.data.filter(Boolean).length === 0;

  return (
    <ABKFormFieldInputWrapper>
      <ABKLabel editorId={editorId}>
        {label}
        {tooltip && <ABKInfoPopup popupContent={tooltip} />}
      </ABKLabel>
      <DropDownList
        {...dropDownListProps}
        data={hasNoValidOptions ? [] : dropDownListProps.data}
        dataItemKey={dataItemKey ? dataItemKey : undefined}
        textField={textField ? textField : undefined}
        itemRender={itemRender}
        name={editorId}
        title={label}
        className={classNameDropDown}
      />
      {errorMessage && (
        <ABKFormErrorMessage>{errorMessage}</ABKFormErrorMessage>
      )}
    </ABKFormFieldInputWrapper>
  );
}
