import { DisplayItem } from "..";
import ABKFormFieldInput from "../../../atoms/ABKFormFieldInput";
import "../styles.scss";

const PairedColumnsContainer = ({
  pairedItems,
  data,
  isEditMode,
  dataItem,
  setData,
}: {
  pairedItems: DisplayItem[][];
  data: any[];
  isEditMode: boolean;
  dataItem: any;
  setData: (data: any[]) => void;
}) => (
  <div className="mobile-list-item-pairs-container">
    {pairedItems.map((pair) => {
      return (
        <div
          key={pair.toString()}
          style={{
            display: "flex",
            flexGrow: 1,
            gap: 8,
            marginBottom: 4,
          }}
          className="mobile-list-item-input"
        >
          {pair.map((item) => {
            return (
              <ABKFormFieldInput
                data-testid="mobile-list-item-input"
                type={item.type}
                key={item.key}
                label={item.name}
                value={
                  !dataItem[item.key] && !isEditMode
                    ? `(leer)`
                    : dataItem[item.key]
                }
                editorId={item.key}
                placeHolder={`(leer)`}
                setValue={
                  !isEditMode
                    ? undefined
                    : (value) => {
                        setData(
                          data.map((item_) =>
                            item_.id === dataItem.id
                              ? { ...item_, [item.key]: value }
                              : item_
                          )
                        );
                      }
                }
              />
            );
          })}
        </div>
      );
    })}
  </div>
);

export default PairedColumnsContainer;
