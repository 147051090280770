import { State } from "@progress/kendo-data-query";
import { createContext } from "react";
import { GenericObject } from "../../../../../interfaces/GenericObject";
import { CreateGridActionsFunction } from "../../interfaces/GridAction";

type DataStateContextType = {
  dataState: State;
  dataResultWithSelectedField: GenericObject[];
  gridActions: CreateGridActionsFunction | undefined;
};

const DataStateContext = createContext<DataStateContextType>({
  dataState: {},
  dataResultWithSelectedField: [],
  gridActions: undefined,
});

export default DataStateContext;
