import { SvgIcon } from "@progress/kendo-react-common";
import { General_DragDrop } from "../../../interfaces/abk9SvgIcons";

type Props = {
  style?: React.CSSProperties;
  dataDragHandle?: string;
  isNoDragAndDrop?: boolean;
};

const classNameForEndToEndTests = "test-drag-and-drop-icon";

const ABKDragAndDropIcon = ({
  style,
  dataDragHandle,
  isNoDragAndDrop,
}: Props) => (
  // Innerhalb eines `div` um ein Ellipsis in den Grid-Zellen zu vermeiden
  <div
    style={{
      ...style,
      ...(isNoDragAndDrop && { cursor: "not-allowed" }),
    }}
    data-drag-handle={dataDragHandle}
    className={classNameForEndToEndTests}
  >
    <SvgIcon
      style={{ pointerEvents: "none" }}
      icon={General_DragDrop}
      color={isNoDragAndDrop ? "lightgray" : undefined}
    />
  </div>
);

export default ABKDragAndDropIcon;
