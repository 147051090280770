import getValidLocale from "./getValidLocale";

export default function formatNumber(
  number: number | null | undefined,
  numberOfFractionDigits?: number
) {
  if (number == null) return "";

  /*
    `locale` ist an die `NumberFormat` explizit übergeben, weil wir das gleiche
    Format für Zahlen in den Input-Feldern und in den Zellen vom Grid wollen.
    Aber das Format in den Input-Feldern kann nicht alle Browser-Locales unterstützen,
    weil KendoReact IntlProvider nicht alles formatieren kann.
    Zum Beispiel wird die Locale "hu" nicht unterstützt.
    Daher wir formatieren immer mit einer gültigen Locale, die unterstützt ist, um
    die Zahlen vereinheitlich anzuzeigen.
  */
  const locale = getValidLocale();
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: numberOfFractionDigits,
    minimumFractionDigits: numberOfFractionDigits,
  }).format(number);
}
