import { Job } from "../../../interfaces/Job";

function getContainerNameFromJob(job: Job | undefined) {
  if (!job) return "";

  if (job.DefaultFile?.NAME) return job.DefaultFile.NAME;

  if (job.DESCRIPTION) return job.DESCRIPTION;

  return "";
}

export default getContainerNameFromJob;
