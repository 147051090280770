import {
  ExternalDropZone,
  Upload,
  UploadFileStatus,
} from "@progress/kendo-react-upload";
import { useRef } from "react";
import useIsMobileTemplate from "../../../../abk-shared/utility/useIsMobileTemplate";
import { BACKEND_URL } from "../../../../core/constants/urls";
import { addToJobsDisplayed } from "../../../../core/db/dbJobs";
import { Job } from "../../../../core/interfaces/Job";
import useNotificationStore from "../../../../core/stores/useNotificationStore";
import { getMessageFromError } from "../../../../core/utility/handleError";
import "./style.css";

type Props = { db: string };
const DateiImport = ({ db }: Props) => {
  const { addError } = useNotificationStore();

  const isMobile = useIsMobileTemplate();

  const handleUploadFailed = (response: any) => {
    if (!response?.response) return;

    const message = getMessageFromError(
      response.response.data,
      response.response
    );

    addError({
      message,
      type: "error",
      closable: true,
      JSError: response as any, // "response" enthält "name" und "message"
    });
  };

  const uploadRef = useRef<Upload | null>(null); // Use a single RefObject with correct type

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
      }}
    >
      {!isMobile && (
        <ExternalDropZone
          customNote={
            "Es werden derzeit nur die Dateitypen .abk9zip, .onbgs und .conbaek unterstützt."
          }
          uploadRef={uploadRef}
          style={{ height: "100%" }}
        />
      )}
      <Upload
        data-testid="upload"
        className={`uploadImportseite ${isMobile ? "mobile-upload" : ""}`}
        ref={uploadRef}
        batch={false}
        multiple={true}
        onStatusChange={(event) => {
          const response = event.response;
          const uploadFailed =
            event.affectedFiles[0].status === UploadFileStatus.UploadFailed;
          if (uploadFailed) {
            handleUploadFailed(response);
            return;
          }

          const job: Job | undefined = response?.response;
          return addToJobsDisplayed(job, db);
        }}
        defaultFiles={[]}
        withCredentials={false}
        saveUrl={`${BACKEND_URL}/api/db/${db}/run/importfile`}
        showFileList={false}
      />
    </div>
  );
};

export default DateiImport;
