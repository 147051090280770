import { GridCellProps, GridTdAttributes } from "@progress/kendo-react-grid";
import ABKDragAndDropIcon from "../../../../../atoms/ABKDragAndDropIcon";

// Inspiriert von: https://www.telerik.com/kendo-react-ui/components/grid/rows/row-reordering/#toc-kendoreact-dragdrop
export const DragHandleCell = (props: GridCellProps) => {
  const {
    ariaColumnIndex,
    dataItem,
    selectionChange,
    columnIndex,
    columnsCount,
    rowType,
    expanded,
    dataIndex,
    isSelected,
    ...tdProps
  } = props;

  // Entferne React Warnungen
  const isAlt = tdProps.isAlt;
  delete tdProps.isAlt;
  (tdProps as any).isalt = isAlt === undefined ? "" : isAlt.toString();

  const isSorted = tdProps.isSorted;
  delete tdProps.isSorted;
  (tdProps as any).issorted = isSorted === undefined ? "" : isSorted.toString();

  return (
    <td
      {...(tdProps as GridTdAttributes)}
      className={`${tdProps.className} drag-handle-cell`}
    >
      <ABKDragAndDropIcon
        style={{ cursor: "move", marginLeft: 2, marginTop: -2 }}
        dataDragHandle="true"
      />
    </td>
  );
};
