import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./style.css";
import { useHotkeys } from "react-hotkeys-hook";
import { isMobile } from "../../../utility/detectDevice";

type Props = {
  message: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
};

const ABKConfirmationDialog = ({ message, onClose, onConfirm }: Props) => {
  useHotkeys(
    "J",
    () => {
      onConfirm();
    },
    {},
    [onConfirm]
  );

  useHotkeys(
    "N",
    () => {
      onClose();
    },
    {},
    [onClose]
  );

  const shouldHideShortcuts = isMobile();
  const textClose = <>{shouldHideShortcuts ? <>N</> : <b>N</b>}ein</>;
  const textConfirm = <>{shouldHideShortcuts ? <>J</> : <b>J</b>}a</>;

  return (
    <Dialog
      title="Bestätigen"
      onClose={onClose}
      className="confirmation-dialog"
    >
      {message}
      <DialogActionsBar>
        <Button onClick={onConfirm}>{textConfirm}</Button>
        <Button onClick={onClose}>{textClose}</Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ABKConfirmationDialog;
