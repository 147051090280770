import ABKMessageNoRecords from "src/abk-shared/components/atoms/ABKMessageNoRecords";
import ListItem from "../ListItem";
import "../styles.scss";
import { DisplayItem } from "..";

const EditModeView = ({
  componentData,
  setData,
  dataToDisplay,
  noDnd,
}: {
  componentData: any[];
  dataToDisplay: DisplayItem[];
  setData: (data: any[]) => void;
  noDnd?: boolean;
}) => {
  if (componentData.length === 0) {
    return <ABKMessageNoRecords />;
  }

  return (
    <div>
      {componentData.map((item) => (
        <ListItem
          key={item.id}
          noDnd={noDnd}
          objectKeys={dataToDisplay.map((item) => item.key)}
          dataToDisplay={dataToDisplay}
          data={componentData}
          setData={setData}
          dataItem={item}
          isDisabled={true}
          isActive={false}
          isDragged={false}
          isDragCue={false}
          isEditMode={true}
          style={{
            border: "1px solid rgb(245, 245, 245)",
          }}
        />
      ))}
    </div>
  );
};

export default EditModeView;
