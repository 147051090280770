import { GridLayoutItem } from "@progress/kendo-react-layout";
import CardElement from "../../molecules/DateienCard";
import { ContWithLastChangedModified } from "../../../../../core/interfaces/Cont";
import CardContainer from "../../../../../abk-shared/components/molecules/ABKCardContainer";
import ABKMessageNoRecords from "../../../../../abk-shared/components/atoms/ABKMessageNoRecords";

type CardGridProps = {
  containerList: ContWithLastChangedModified[];
  onCardClick: (containerID: string) => unknown;
  cardProps: {
    buttonText: string;
    buttonIcon: string;
  };
};

const ContainerCardGrid = ({
  containerList,
  onCardClick,
  cardProps,
}: CardGridProps) => {
  if (!containerList || containerList.length === 0)
    return <ABKMessageNoRecords />;

  return (
    <CardContainer style={{ width: "100%" }}>
      <>
        {containerList?.map((data, index) => (
          <GridLayoutItem key={index}>
            <CardElement
              data={data}
              onCardClick={() => onCardClick(data.CONTID)}
              isTemplate={data.SPECIALFILE === "vorl"}
              buttonIcon={cardProps.buttonIcon}
              buttonText={cardProps.buttonText}
            />
          </GridLayoutItem>
        ))}
      </>
    </CardContainer>
  );
};
export default ContainerCardGrid;
