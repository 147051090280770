import { Button } from "@progress/kendo-react-buttons";
import { EditorToolsSettings, EditorUtils } from "@progress/kendo-react-editor";
import * as React from "react";
import { InsertImageDialog } from "./insertImageDialog";
import "./insertImageDialog.css";

const imageSettings = EditorToolsSettings.image;
export const InsertImage = (props: any) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggleDialog = () => setOpenDialog(!openDialog);
  const { view } = props;
  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;
  return (
    <React.Fragment>
      <Button
        className={`${openDialog ? "active-editor-button" : "inactive-editor-button"}`}
        style={{
          height: 30,
        }}
        onClick={toggleDialog}
        disabled={
          !nodeType || !state || !EditorUtils.canInsert(state, nodeType)
        }
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title="Bild einfügen"
        {...imageSettings.props}
      />
      {openDialog && view && (
        <InsertImageDialog
          key="insertImageDialog"
          view={view}
          onClose={toggleDialog}
          imageNode={imageSettings.node}
        />
      )}
    </React.Fragment>
  );
};
