import { GridLayout } from "@progress/kendo-react-layout";
import "./style.scss";

type Props = {
  style?: React.CSSProperties;
  children: React.ReactElement;
  hasGridLayout?: boolean;
};

function CardContainer({ style, children, hasGridLayout = true }: Props) {
  return (
    <div className="wrapper-card-container" style={style}>
      {/*
        Dieses "div" ist da, um einen border-radius für den weißen Hintergrund zu
        haben, auch wenn wir nicht "abk-viewport" als direkt Parent haben.
        "abk-viewport" hat das border-radius, aber es kann sein, dass es von anderen
        Componenten bedeckt ist, zum Beispiel die Toolbar vom Grid.
      */}
      <div className="container-border-radius">
        {hasGridLayout ? (
          <GridLayout className="card-container">{children}</GridLayout>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default CardContainer;
