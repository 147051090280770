import AbkColors from "../../../../../../constants/AbkColors";

const DragAndDropLine = () => (
  <tr
    style={{
      outlineStyle: "solid",
      outlineWidth: 1,
      outlineColor: AbkColors.green,
      /*
        Die Position ist "relative", da die erste Zellen für die Checkbox
        und das DragHandle `sticky` sind.
        Ohne diese `position: "relative"` wird die `DragAndDropLine` nicht
        die ganze Zeile bedecken.
      */
      position: "relative",
      zIndex: 3,
    }}
  />
);

export default DragAndDropLine;
