import germanMessages from "../../organisms/ABKBasisGrid/de-DE.json";

const ABKMessageNoRecords = () => (
  <div className="k-grid-norecords">
    <div
      className="k-grid-norecords-template"
      data-keyboardnavid="_no-records_cell"
    >
      {germanMessages.grid.noRecords}
    </div>
  </div>
);

export default ABKMessageNoRecords;
