import React from "react";
import { Dummy_Missing } from "src/abk-shared/interfaces/abk9SvgIcons";
import BreadcrumbDropDown from "src/core/components/molecules/ABKBreadcrumbs/ABKBreadcrumbLink/BreadcrumbDropDown";
import useLastBreadcrumb from "src/core/components/molecules/ABKBreadcrumbs/ABKBreadcrumbLink/useLastBreadcrumb";
import {
  FILE_PIKTOGRAMME_SUFFIX,
  FILE_PL_EINTRAEGE_SUFFIX,
  FILE_PL_KENNDATEN_SUFFIX,
} from "src/core/constants/urls";
import useGetAllContainerParameterlisten from "src/modules/Datei/queries/useGetAllContainerParameterlisten";
import createNavigationItem from "src/Router/Layout/navigationItems/createNavigationItem";
import TabsLayout from "src/Router/Layout/TabsLayout";
import createParameterlisteURL from "../createParameterlisteURL";

type Props = { plID: string; dbName: string; contId: string };

const ParameterlisteLayout = ({ plID, dbName, contId }: Props) => {
  const { data: parameterlisten } = useGetAllContainerParameterlisten(
    dbName,
    contId
  );
  const { setLastBreadcrumb } = useLastBreadcrumb();

  React.useEffect(() => {
    if (!parameterlisten || parameterlisten.length === 0) return;

    const parameterlisteItems = parameterlisten.map((parameterliste) =>
      createNavigationItem(
        parameterliste.DATAID,
        parameterliste.Kenndaten.KENNUNG,
        parameterliste.Kenndaten.KENNUNG,
        Dummy_Missing,
        createParameterlisteURL(
          parameterliste.DATAID,
          parameterliste.DBNAME,
          parameterliste.CONTID
        ),
        false,
        ""
      )
    );

    setLastBreadcrumb(
      <BreadcrumbDropDown
        navigationItems={parameterlisteItems}
        currentItemId={plID}
        keyForItemId="id"
      />
    );
  }, [parameterlisten, setLastBreadcrumb, plID]);

  return (
    <TabsLayout
      routePrefix="pl"
      data-testid="pl-tabs"
      aria-label="Parameterlisten-Tabs"
      tabs={[
        {
          id: 0,
          label: "Kenndaten",
          to: `pl/${plID}/${FILE_PL_KENNDATEN_SUFFIX}`,
        },
        {
          id: 1,
          label: "Einträge",
          to: `pl/${plID}/${FILE_PL_EINTRAEGE_SUFFIX}`,
        },
        {
          id: 2,
          label: "Piktogramme",
          to: `pl/${plID}/${FILE_PIKTOGRAMME_SUFFIX}`,
        },
      ]}
    />
  );
};

export default ParameterlisteLayout;
