import {
  Window,
  WindowMoveEvent,
  WindowProps,
} from "@progress/kendo-react-dialogs";
import React from "react";
import useInitialWidth from "src/abk-shared/utility/useInitialWidth";
import "./style.css";

const windowMoveEventHandler = function (event: WindowMoveEvent) {
  // Der Code behebt den Bug,  der bei Bewegung des Windows den RichTextEditor markiert-.
  if (event.end === true) {
    document.body.style.userSelect = "auto";
    return;
  }
  document.body.style.userSelect = "none";
};

const ABKWindow = React.forwardRef<Window, WindowProps>((props, ref) => {
  const initialWidth = useInitialWidth(props.initialWidth);

  return (
    <Window
      {...props}
      className={`${props.className} abk-dialog`}
      ref={ref}
      initialWidth={initialWidth}
      onResize={windowMoveEventHandler}
      onMove={windowMoveEventHandler}
    />
  );
});

export default ABKWindow;
