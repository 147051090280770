import FormSection, { FormSectionProps } from "./FormSection";
import FormTitle from "./FormTitle";

interface FormProps {
  title?: string;
  sections: FormSectionProps[];
  style?: React.CSSProperties;
}

const ABKForm = ({ title, sections, style }: FormProps) => {
  return (
    <div
      style={{ ...style, flex: 1, maxWidth: "100%" }}
      /*
        Um zu vermeiden, dass die Expansion Panels sich schließen, wenn wir
        "Enter" oder "Space" drücken, während wir in das Formular Text eingeben.
      */
      onKeyDown={(e) => e.stopPropagation()}
    >
      {title && <FormTitle title={title} />}
      {sections.map((section, index) => (
        <FormSection {...section} key={index} />
      ))}
    </div>
  );
};

export default ABKForm;
