import { EintragFrontend } from "../../../../../interfaces/parameterlisten";

export default function getQueryDataFromFirstEintrag(
  eintraege: EintragFrontend[]
) {
  const firstEintrag = eintraege[0];

  const dbName = firstEintrag.DBNAME;
  const contId = firstEintrag.CONTID;
  const plID = firstEintrag.DATAID;

  return {
    dbName,
    contId,
    plID,
  };
}
