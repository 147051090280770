import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useJobsDisplayed from "src/core/jobs/useJobsDisplayed";
import { INotification } from "../../../../abk-shared/components/organisms/ABKSideBar/NotificationBox";
import { dbJobs, JobDisplayed } from "../../../db/dbJobs";
import { Job, Status } from "../../../interfaces/Job";
import useGetAllJobs from "../../../queries/jobs/useGetAllJobs";
import useCancelJob from "../../../queries/useCancelJob";
import useCurrentDBStore from "../../../stores/useCurrentDBStore";
import useJobDialogStore, {
  JobDialogResponse,
} from "../../molecules/ABKConfirmationDialogForJob/useJobDialogStore";
import generateJobNotification, {
  getDBFromJob,
} from "./generateSidebarElements/job/generateJobNotification";

function useJobNotifications() {
  const [jobNotifications, setJobNotifications] = useState<INotification[]>([]);
  const [shouldPollJobs, setShouldPollJobs] = useState(true);

  const params = useParams();
  const database = params.db;
  const { currentDB } = useCurrentDBStore();
  const navigate = useNavigate();

  const oneHourAgo = 3600;
  const refetchInterval: number | false = shouldPollJobs ? 2_000 : false;
  const { data: jobsReceivedFromBackend } = useGetAllJobs(
    currentDB?.DBNAME,
    oneHourAgo,
    refetchInterval
  );

  const jobsDisplayed = useJobsDisplayed(database);

  React.useEffect(() => {
    if (shouldContinueToPollJobs(jobsDisplayed)) {
      if (!shouldPollJobs) setShouldPollJobs(true);
      return;
    }
    setShouldPollJobs(false);
  }, [jobsDisplayed]);

  const { setDialogType, toggleDialog, setJob, setDialogPromise } =
    useJobDialogStore();

  const onCloseJob = React.useCallback(
    async (job: Job) => {
      setDialogType("remove");
      setJob(job);
      toggleDialog();
      const { shouldProceed } = await new Promise<JobDialogResponse>(
        (resolve) => {
          setDialogPromise({ resolve });
        }
      );
      if (!shouldProceed) return;

      return dbJobs.jobsDisplayed.delete(job.JOBID);
    },
    [setDialogType, setJob, toggleDialog, setDialogPromise]
  );

  const mutationCancelJob = useCancelJob();
  const onCancelJob = React.useCallback(
    async (job: Job) => {
      setDialogType("cancel");
      setJob(job);
      toggleDialog();
      const { shouldProceed } = await new Promise<JobDialogResponse>(
        (resolve) => {
          setDialogPromise({ resolve });
        }
      );
      if (!shouldProceed) return;

      const db = currentDB?.DBNAME || getDBFromJob(job);
      await mutationCancelJob.mutateAsync({
        db: db,
        contId: job.CONTID,
        jobId: job.JOBID,
      });

      if (!jobsDisplayed) return;

      const correspondingJobDisplayed = jobsDisplayed.find(
        (jobDisplayed) => jobDisplayed.job.JOBID === job.JOBID
      );
      if (!correspondingJobDisplayed) return;

      correspondingJobDisplayed.job.STATUS = Status.AbortRequested;
      return dbJobs.jobsDisplayed.put(correspondingJobDisplayed);
    },
    [
      setDialogType,
      setJob,
      toggleDialog,
      currentDB?.DBNAME,
      jobsDisplayed,
      setDialogPromise,
    ]
  );

  const queryClient = useQueryClient();

  const getJobNotifications = React.useCallback(
    (jobs: JobDisplayed[]) => {
      const newJobNotifications: INotification[] = [];
      jobs.forEach(async (job) => {
        const notification = generateJobNotification(
          job.job,
          currentDB?.DBNAME,
          navigate,
          onCloseJob,
          onCancelJob,
          queryClient
        );
        if (notification) newJobNotifications.push(notification);
      });
      setJobNotifications(newJobNotifications || []);
    },
    [currentDB, navigate, onCloseJob, onCancelJob, queryClient]
  );

  React.useEffect(() => {
    getJobNotifications(jobsDisplayed || []);
  }, [getJobNotifications, jobsDisplayed]);

  React.useEffect(() => {
    const { jobsToUpdate, jobsToRemove } = updateJobsDisplayedFromBackendData(
      jobsReceivedFromBackend,
      jobsDisplayed
    );

    if (jobsToUpdate.length > 0)
      void dbJobs.jobsDisplayed.bulkPut(jobsToUpdate);
    if (jobsToRemove.length > 0)
      void dbJobs.jobsDisplayed.bulkDelete(jobsToRemove);
  }, [jobsReceivedFromBackend, jobsDisplayed]);

  return jobNotifications;
}

const jobIsFinished = (job: Job) =>
  job.STATUS !== Status.Running && job.STATUS !== Status.AbortRequested;

function shouldContinueToPollJobs(jobsDisplayed: JobDisplayed[] | undefined) {
  if (!jobsDisplayed) return false;

  const allJobsAreFinished = jobsDisplayed.every((jobDisplayed) =>
    jobIsFinished(jobDisplayed.job)
  );
  if (allJobsAreFinished) return false;

  return true;
}

function updateJobsDisplayedFromBackendData(
  jobsReceivedFromBackend: Job[] | undefined,
  jobsDisplayed: JobDisplayed[] | undefined
) {
  const result = {
    jobsToUpdate: [] as JobDisplayed[],
    jobsToRemove: [] as string[],
  };
  if (!jobsReceivedFromBackend || !jobsDisplayed) return result;

  for (const jobDisplayed of jobsDisplayed) {
    const correspondingJobFromBackend = jobsReceivedFromBackend.find(
      (jobFromBackend) => jobFromBackend.JOBID === jobDisplayed.job.JOBID
    );

    const userHasCancelledTheJob =
      jobDisplayed.job.STATUS === Status.AbortRequested;
    if (!correspondingJobFromBackend) {
      if (userHasCancelledTheJob) {
        /*
          Wenn ein Job nicht mehr in der Liste ist, und der Anwender hatte ihn
          abgebrochen, das bedeutet, dass das Abbrechen erfolgreich durchgeführt wurde.
        */
        result.jobsToRemove.push(jobDisplayed.jobId);
      }
      continue;
    }

    if (!jobDisplayedHasChanged(jobDisplayed, correspondingJobFromBackend))
      continue;

    if (userHasCancelledTheJob) {
      const backendJobIsFinished = jobIsFinished(correspondingJobFromBackend);
      if (backendJobIsFinished) result.jobsToRemove.push(jobDisplayed.jobId);
      continue;
    }

    result.jobsToUpdate.push({
      ...jobDisplayed,
      job: correspondingJobFromBackend,
    });
  }

  return result;
}

function jobDisplayedHasChanged(
  jobDisplayed: JobDisplayed,
  correspondingJobFromBackend: Job
) {
  const statusHasChanged =
    correspondingJobFromBackend.STATUS !== jobDisplayed.job.STATUS;

  const contInfoHasChanged = !_.isEqual(
    correspondingJobFromBackend.ContInfo,
    jobDisplayed.job.ContInfo
  );

  return statusHasChanged || contInfoHasChanged;
}

export default useJobNotifications;
