import { SvgIcon } from "@progress/kendo-react-common";
import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";
import "./style.scss";

export interface NavigationButtonItemProps {
  text: string;
  icon: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  hasSeparator?: boolean;
  title?: string;
}

const ABKNavigationButtonItem = ({
  text,
  icon,
  active = false,
  onClick,
  disabled = false,
  hasSeparator,
  title,
}: NavigationButtonItemProps) => {
  const handleClick = (event: any) => {
    if (disabled) return;

    if (onClick) {
      onClick(event);
      return;
    }
  };

  const className = createClassName(disabled, active);
  return (
    <div
      className={className}
      role="button"
      onClick={handleClick}
      style={{ borderBottom: hasSeparator ? "solid 1px #000" : "none" }} //#6e6b64
      aria-disabled={disabled}
      title={title}
    >
      <div className="icon-container">
        <SvgIcon icon={AbkIconsMap.get(icon)} />
      </div>
      <p>{text}</p>
    </div>
  );
};

function createClassName(disabled: boolean, active: boolean) {
  let className = "app-navigator-item";
  if (disabled) className = `${className} navigator-item-disabled`;
  if (active) className = `${className} navigator-item-active`;
  return className;
}

export default ABKNavigationButtonItem;
