import ABKExpansionPanel from "../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";
import "./style.scss";

export default function AdminHelpPage() {
  const tenantName = window.location.hostname;
  const hrefAllgemein = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi.yaml`;
  const hrefParameterlisten = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi_pl.yaml`;
  const hrefGliederungssysteme = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi_gs.yaml`;
  const hrefLeistungsbeschreibung = `https://editor.swagger.io/?url=https://${tenantName}/api/files/openapi_lb.yaml`;

  return (
    <div className="wrapper-admin-help">
      <ABKExpansionPanel title="REST-API-Dokumentation" defaultExpanded={true}>
        <ABKFormFieldInput
          editorId="allgemein-rest-api-documentation"
          label="Allgemein"
          useJSXForValue={true}
          value={
            <a href={hrefAllgemein} target="_blank" rel="noreferrer">
              {hrefAllgemein}
            </a>
          }
        />
        <ABKFormFieldInput
          editorId="parameterlisten-rest-api-documentation"
          label="Parameterlisten"
          useJSXForValue={true}
          value={
            <a href={hrefParameterlisten} target="_blank" rel="noreferrer">
              {hrefParameterlisten}
            </a>
          }
        />
        <ABKFormFieldInput
          editorId="gliederungssysteme-rest-api-documentation"
          label="Gliederungssysteme"
          useJSXForValue={true}
          value={
            <a href={hrefGliederungssysteme} target="_blank" rel="noreferrer">
              {hrefGliederungssysteme}
            </a>
          }
        />
        <ABKFormFieldInput
          editorId="leistungsbeschreibung-rest-api-documentation"
          label="Leistungsbeschreibung"
          useJSXForValue={true}
          value={
            <a
              href={hrefLeistungsbeschreibung}
              target="_blank"
              rel="noreferrer"
            >
              {hrefLeistungsbeschreibung}
            </a>
          }
        />
      </ABKExpansionPanel>
    </div>
  );
}
