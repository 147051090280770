import { SortableOnDragOverEvent } from "@progress/kendo-react-sortable";
import Columns from "../Columns";
import DnDView from "./DnDView";
import EditModeView from "./EditModeView";
import { DisplayItem } from "..";

const DesktopListView = ({
  componentData,
  dataToDisplay,
  updateData,
  onDragOver,
  setData,
  isEditMode,
  noDnd,
}: {
  componentData: any[];
  dataToDisplay: DisplayItem[];
  updateData: (data: any[]) => void;
  onDragOver: (event: SortableOnDragOverEvent) => void;
  setData: (data: any[]) => void;
  isEditMode: boolean;
  noDnd?: boolean;
}) => (
  <>
    <Columns
      data-testid="list-columns"
      noDnd={noDnd}
      objectKeys={dataToDisplay.map((item) => item.name)}
    />
    {isEditMode || noDnd ? (
      <EditModeView
        data-testid="edit-mode-view"
        componentData={componentData}
        noDnd={noDnd}
        setData={setData}
        dataToDisplay={dataToDisplay}
      />
    ) : (
      <DnDView
        dataToDisplay={dataToDisplay}
        data-testid="dnd-view"
        componentData={componentData}
        objectKeys={dataToDisplay.map((item) => item.key)}
        updateData={updateData}
        onDragOver={onDragOver}
        setData={setData}
      />
    )}
  </>
);

export default DesktopListView;
