interface EintragBearbeitenExpansionPanelPreviewProps {
  title: string;
  previewText: string;
  shouldShowPreview: boolean;
}
const classNameForEndToEndTests = "test-expansion-panel-preview";

const EintragBearbeitenExpansionPanelPreview = ({
  title,
  previewText,
  shouldShowPreview,
}: EintragBearbeitenExpansionPanelPreviewProps) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="k-expander-title" style={{ minWidth: "fit-content" }}>
          {title}
        </div>
        {shouldShowPreview && (
          <span className={`Textvorschau ${classNameForEndToEndTests}`}>
            {previewText}
          </span>
        )}
      </div>
    </>
  );
};

export default EintragBearbeitenExpansionPanelPreview;
