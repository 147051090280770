import AbkColors from "../../../../../../constants/AbkColors";

const HintNumberOfDraggedItems = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div
    style={{
      position: "absolute",
      pointerEvents: "none",
      bottom: 0,
      right: 0,
      background: AbkColors.green,
      padding: 8,
      width: 32,
      color: "white",
      borderRadius: "50%",
      transform: "translate(50%, 50%)",
    }}
  >
    {children}
  </div>
);

export default HintNumberOfDraggedItems;
