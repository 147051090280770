import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";
import ABKEigenschaftenfenster from "src/core/components/molecules/ABKEigenschaftenfenster";
import { convertHtmlToOenorm } from "./CustomViewHtml";
import "./style.css";

interface CustomHTMLDialogProps {
  value: string;
  setShowDialogWindow: (value: boolean) => void;
}

const stripHtmlTags = (str: string): string => {
  return str.replace(/<(\w+)\s*><\/\1\s*>/gm, "");
};

const CustomHTMLDialog = ({
  setShowDialogWindow,
  value,
}: CustomHTMLDialogProps) => {
  const plainText = stripHtmlTags(convertHtmlToOenorm(value));

  const isMobile = useIsMobileTemplate();
  const initialHeight = isMobile ? window.innerHeight : 600;
  const initialWidth = isMobile ? undefined : 800;

  return (
    /*
      Verwende die `Eigenschaftenfenster` Komponente, da wir das gleiche Verhalten
      für die Mobile-Version haben wollen.
    */
    <ABKEigenschaftenfenster
      className={"HTMLWindow"}
      title={"HTML-Ansicht"}
      onClose={() => setShowDialogWindow(false)}
      initialWidth={initialWidth}
      initialHeight={initialHeight}
    >
      {plainText ? <pre className="html-content">{plainText}</pre> : null}
    </ABKEigenschaftenfenster>
  );
};

export default CustomHTMLDialog;
