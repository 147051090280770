import { DATA_KEY } from "src/abk-shared/components/organisms/ABKBasisGrid/constants";
import formatNumber from "src/abk-shared/utility/formatNumber";
import { GliederungssystemEintrag } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { generatePreviewGsEintragNummer } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/previewTextGenerator";
import {
  EintragBackend,
  EintragFrontend,
} from "../../../interfaces/parameterlisten";
import {
  booleanFieldMapper,
  booleanFieldReverseMapper,
  eintragShowIfMapper,
  eintragShowIfReverseMapper,
  parTypeMapper,
  parTypeReverseMapper,
} from "./maps";

export const backendEintragToFrontend = (
  backendEintrag: EintragBackend,
  allGsEintraege: GliederungssystemEintrag[] = []
) =>
  ({
    ...backendEintrag,
    ITEMTYPE: parTypeMapper.get(backendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldMapper.get(backendEintrag.MENGENABH),
    FAVORIT: booleanFieldMapper.get(backendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldMapper.get(backendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldMapper.get(backendEintrag.REQUIRED),
    SHOWIF: eintragShowIfMapper.get(backendEintrag.SHOWIF),
    /*
      Wir erstellen einen `internalValue` für `NUMNK`, weil wir wollen, dass
      es im Column-Menü nicht als `null` angezeigt wird, wenn wir keinen
      Wert für das Column-Menü haben.
    */
    NUMNK: createInternalValueForNumber(backendEintrag.NUMNK),
    NUMMIN: createInternalValueForNumber(
      backendEintrag.NUMMIN,
      backendEintrag.NUMNK
    ),
    NUMMAX: createInternalValueForNumber(
      backendEintrag.NUMMAX,
      backendEintrag.NUMNK
    ),
    Funktion_string: generatePreviewGsEintragNummer(
      allGsEintraege,
      backendEintrag.Funktion
    ),
    Produkt_string: generatePreviewGsEintragNummer(
      allGsEintraege,
      backendEintrag.Produkt
    ),
    AWList_Text_string:
      backendEintrag.AWList_Text?.map(
        (item) =>
          `${item.Eintrag}${item.Bezeichnung && ` [${item.Bezeichnung}]`}`
      ).join("; ") || "",
    AWList_Num_string: backendEintrag.AWList_Num?.join("; ") || "",
    AWList_Num: backendEintrag.AWList_Num.map((item, index) => ({
      Wert: item,
      id: index.toString(),
    })),
    AWList_Text: backendEintrag.AWList_Text?.map((item, index) => ({
      Eintrag: item.Eintrag,
      Bezeichnung: item.Bezeichnung,
      id: index.toString(),
    })),
  }) as EintragFrontend;

export const createInternalValueForNumber = (
  number: number | null | undefined,
  numberOfFractionDigits?: number
) => ({
  [DATA_KEY.DISPLAYED]: formatNumber(number, numberOfFractionDigits),
  [DATA_KEY.INTERNAL]: number,
});

export const frontendEintragToBackend = (frontendEintrag: EintragFrontend) =>
  ({
    ...frontendEintrag,
    ITEMTYPE: parTypeReverseMapper.get(frontendEintrag.ITEMTYPE),
    MENGENABH: booleanFieldReverseMapper.get(frontendEintrag.MENGENABH),
    FAVORIT: booleanFieldReverseMapper.get(frontendEintrag.FAVORIT),
    SUMMEBILDEN: booleanFieldReverseMapper.get(frontendEintrag.SUMMEBILDEN),
    REQUIRED: booleanFieldReverseMapper.get(frontendEintrag.REQUIRED),
    SHOWIF: eintragShowIfReverseMapper.get(frontendEintrag.SHOWIF),
    NUMNK: frontendEintrag.NUMNK.internal,
    NUMMIN: frontendEintrag.NUMMIN.internal,
    NUMMAX: frontendEintrag.NUMMAX.internal,
    Funktion: Array.isArray(frontendEintrag.Funktion)
      ? frontendEintrag.Funktion
      : [],
    Produkt: Array.isArray(frontendEintrag.Produkt)
      ? frontendEintrag.Produkt
      : [],
    AWList_Num: frontendEintrag.AWList_Num?.map((item) =>
      typeof item.Wert === "string" ? parseFloat(item.Wert || "0") : item.Wert
    ),
    AWList_Text: frontendEintrag.AWList_Text?.map((item) => ({
      Eintrag: item.Eintrag,
      Bezeichnung: item.Bezeichnung,
    })),
  }) as EintragBackend;
