import "./style.scss";

type Props = {
  children: React.ReactNode;
  isHalfRow?: boolean;
  className?: string;
};

export default function ABKFormRow({
  children,
  isHalfRow = false,
  className,
}: Props) {
  return (
    <div
      className={createClassName(isHalfRow, className)}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
}

function createClassName(isHalfRow: boolean, className?: string) {
  let finalClassName = "abk-form-row-common";

  if (isHalfRow) finalClassName = `${finalClassName} abk-form-half-row`;
  else finalClassName = `${finalClassName} abk-form-row`;

  if (!className) return finalClassName;

  return `${finalClassName} ${className}`;
}
