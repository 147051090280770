import useGetVersion from "../../../../modules/Startseite/hooks/useGetVersion";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

const SysInfoGrid = () => {
  const { data } = useGetVersion();

  const sysInfoData = [
    { Attribute: "AuthUser", Value: data?.AuthUser },
    { Attribute: "Session", Value: data?.Session },
    { Attribute: "InfoCreated", Value: data?.InfoCreated },
    { Attribute: "ServerInfo", Value: data?.ServerInfo },
    { Attribute: "ServerVersion", Value: data?.ServerVersion },
    { Attribute: "ServerExe", Value: data?.ServerExe },
    { Attribute: "HostInfo", Value: data?.HostInfo },
    { Attribute: "PortalServer", Value: data?.PortalServer },
    { Attribute: "Client", Value: data?.Client },
    { Attribute: "UserAgent", Value: data?.UserAgent },
    { Attribute: "Language", Value: data?.Language },
    { Attribute: "DebugInfo", Value: data?.DebugInfo },
  ];

  return (
    <Grid data={sysInfoData}>
      <GridColumn field="Attribute" title="Attribute" />
      <GridColumn field="Value" title="Value" />
    </Grid>
  );
};

export default SysInfoGrid;
