import QuickInfoSubTitle from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/QuickInfoSubTitle";
import { DefaultFile, Job } from "../../../../../interfaces/Job";
import { getDBFromJob } from "./generateJobNotification";
import React from "react";

export default function createListOfFileLinks(files: DefaultFile[], job: Job) {
  return (
    <>{files.map((file, index) => createFileLinkForJob(file, index, job))}</>
  );
}

function createFileLinkForJob(file: DefaultFile, index: number, job: Job) {
  const db = getDBFromJob(job);
  const downloadLink = `/api/db/${db}/cont/${file.CONTID}/jobs/${file.JOBID}/files/${file.FILEID}/download`;

  return (
    <React.Fragment key={index}>
      <div className="k-pl-sm">
        <QuickInfoSubTitle
          title={
            <a style={{ wordWrap: "break-word" }} href={downloadLink}>
              {file.NAME}
            </a>
          }
        />
      </div>
    </React.Fragment>
  );
}
