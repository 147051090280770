import { SvgIcon } from "@progress/kendo-react-common";
import { SortableItemUIProps } from "@progress/kendo-react-sortable";
import { General_DragDrop } from "src/abk-shared/interfaces/abk9SvgIcons";
import { DisplayItem } from "..";
import ABKFormFieldInput from "../../../atoms/ABKFormFieldInput";
import "../styles.scss";
import useListItem from "../useListItem";
import ListItemButtons from "./ListItemButtons";

interface ListItemProps extends SortableItemUIProps {
  objectKeys: string[];
  setData: (data: any[]) => void;
  data: any[];
  isEditMode?: boolean;
  dataToDisplay: DisplayItem[];
  noDnd?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  dataItem,
  isActive,
  style,
  forwardRef,
  attributes,
  setData,
  data,
  dataToDisplay,
  isEditMode,
  noDnd,
}) => {
  const { handleDelete } = useListItem(dataItem, data, setData);

  return (
    <div
      ref={forwardRef}
      {...attributes}
      className="list-item"
      data-testid="list-item"
      style={{
        border: isActive ? "1px solid #B5B5B5" : "1px solid #F5F5F5",
        ...style,
      }}
    >
      {!noDnd && (
        <SvgIcon
          style={{ cursor: isEditMode ? "not-allowed" : "move" }}
          data-testid="drag-and-drop-icon"
          icon={General_DragDrop}
          width={16}
          color={isEditMode ? "lightgray" : undefined}
        />
      )}
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          gap: 8,
        }}
      >
        {dataToDisplay.map((item) => {
          const defaultValue = item.type === "number" ? 0 : "(leer)";

          return (
            <ABKFormFieldInput
              data-testid={`list-item-input-${item.key}`}
              key={item.key}
              type={item.type}
              value={
                !dataItem[item.key] && !isEditMode
                  ? defaultValue
                  : dataItem[item.key]
              }
              editorId={item.key}
              placeHolder={`(leer)`}
              setValue={
                !isEditMode || noDnd
                  ? undefined
                  : (value) => {
                      setData(
                        data.map((item_) =>
                          item_.id === dataItem.id
                            ? { ...item_, [item.key]: value }
                            : item_
                        )
                      );
                    }
              }
            />
          );
        })}
      </div>

      <ListItemButtons handleDelete={handleDelete} dataItem={dataItem} />
    </div>
  );
};

export default ListItem;
