import { PanelBarSelectEventArguments } from "@progress/kendo-react-layout";
import React from "react";

export default function useColumnsDivHeightToFitViewport() {
  const wrapperColumnCheckboxes = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  React.useEffect(() => {
    if (wrapperColumnCheckboxes.current)
      reduceElementHeightToFitViewport(wrapperColumnCheckboxes.current);
  }, [expanded]);

  const idPanelBarItem = "toggle-column-panel-bar-item";

  const onSelect = (event: PanelBarSelectEventArguments) => {
    if (!event.expandedItems) return;

    const isExpanded = event.expandedItems.some(
      (itemId) => itemId === idPanelBarItem
    );
    setExpanded(isExpanded);
  };

  return {
    wrapperColumnCheckboxes,
    expanded,
    onSelect,
    idPanelBarItem,
  };
}

function reduceElementHeightToFitViewport(divElement: HTMLDivElement) {
  const divRect = divElement.getBoundingClientRect();
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const overflowBottom = Math.max(0, divRect.bottom - viewportHeight);
  if (overflowBottom === 0) return;

  const currentHeight = divRect.height;

  const offsetToPreventScrollbarInBody = 1;
  const heightInViewportRange =
    currentHeight - (overflowBottom + offsetToPreventScrollbarInBody);

  if (heightInViewportRange > 0)
    divElement.style.height = `${heightInViewportRange}px`;
}
