import React, { CSSProperties, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { GenericObject } from "../../../../interfaces/GenericObject";
import CardContainer from "../../../molecules/ABKCardContainer";
import { FIELD_SELECTED } from "../hooks/useGridColumns/gridActionColumns";
import { FunctionSetDataItemSelected } from "../hooks/useGridRowSelection/gridRowSelection.types";
import {
  getScrollTopLocalStorage,
  setScrollTopLocalStorage,
} from "../hooks/useSetInitialScrollTop/scrollTopLocalStorage";
import { ungroupDataItems } from "../utils/groupingFunctions";

export type MobileGridProps = {
  rowHeight: number;
  renderRow: FunctionMobileGridRow;
};

export type FunctionMobileGridRow = (
  rowRef: React.RefObject<HTMLDivElement>,
  item: GenericObject,
  filterValue: string,
  isSelected: boolean,
  setDataItemSelected: FunctionSetDataItemSelected
) => React.ReactElement;

type Props = {
  dataItems: GenericObject[];
  mobileGridProps: MobileGridProps;
  filterValue: string;
  setDataItemSelected: FunctionSetDataItemSelected;
  gridUniqueId: string;
};

export default function VirtualizedMobileGridRows({
  dataItems,
  mobileGridProps,
  filterValue,
  setDataItemSelected,
  gridUniqueId,
}: Props) {
  const [gridDimensions, setGridDimensions] = useState({ width: 0, height: 0 });
  const listRef = React.useRef<FixedSizeList>(null);
  const mobileScrollContainerRef = React.useRef<HTMLDivElement>(null);
  const flattenedDataItems = React.useMemo(
    () => ungroupDataItems(dataItems),
    [dataItems]
  );

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const rowRef = React.useRef<HTMLDivElement>(null);

    return (
      <div
        style={{
          ...style,
          borderBottom:
            index === flattenedDataItems.length - 1
              ? "1px solid black"
              : undefined, // so wird nur bei der letzten Zeile ein border-bottom hinzugefügt.
        }}
        ref={rowRef}
      >
        {mobileGridProps.renderRow(
          rowRef,
          flattenedDataItems[index],
          filterValue,
          flattenedDataItems[index][FIELD_SELECTED],
          setDataItemSelected
        )}
      </div>
    );
  };

  return (
    <CardContainer hasGridLayout={false}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          if (gridDimensions.height === 0 && height !== 0)
            setGridDimensions({ height, width });
          return (
            <FixedSizeList
              ref={listRef}
              outerRef={mobileScrollContainerRef}
              height={height}
              width={width}
              itemCount={flattenedDataItems.length}
              initialScrollOffset={getScrollTopLocalStorage(gridUniqueId, true)}
              itemSize={mobileGridProps.rowHeight}
              onScroll={() => {
                const element = mobileScrollContainerRef.current;
                const numberOfShownItems =
                  gridDimensions.height / (mobileGridProps?.rowHeight || 1);
                if (!element) return;
                // Only scroll back to last element IF there are enough items to scroll
                // (this is a Bugfix for https://ib-data.atlassian.net/browse/ABK9-975)
                if (numberOfShownItems === 0) return;
                if (flattenedDataItems.length < numberOfShownItems) return;
                setScrollTopLocalStorage(element, gridUniqueId, true);
              }}
            >
              {Row}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    </CardContainer>
  );
}
