import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { AbkIconsMap } from "../../../../../../interfaces/abk9SvgIcons";
import {
  createColumnCheckboxes,
  FunctionColumnToggleVisibility,
} from "../../../hooks/useGridColumns/toggleColumnVisibility";
import { GridColumnPropsCustom } from "../../../interfaces/GridColumns";
import "./style.scss";
import useAdjustHeightToFitViewport from "./useAdjustHeightToFitViewport";

type Props = {
  columns: GridColumnPropsCustom[];
  onColumnToggleVisibility: FunctionColumnToggleVisibility;
};

export default function ABKToggleColumns({
  columns,
  onColumnToggleVisibility,
}: Props) {
  const columnCheckboxes = createColumnCheckboxes(
    columns,
    onColumnToggleVisibility
  );

  const { onSelect, idPanelBarItem, expanded, wrapperColumnCheckboxes } =
    useAdjustHeightToFitViewport();

  return (
    <div
      data-testid="grid-menu-toggle-columns"
      onClick={(e) => e.stopPropagation()}
    >
      <PanelBar className="abk-toggle-columns" onSelect={onSelect}>
        <PanelBarItem
          title="Spalten ein- und ausblenden"
          svgIcon={AbkIconsMap.get("General.Import")}
          id={idPanelBarItem}
          expanded={expanded}
        >
          <div
            className="wrapper-checkboxes-toggle-column"
            ref={wrapperColumnCheckboxes}
          >
            {columnCheckboxes}
          </div>
        </PanelBarItem>
      </PanelBar>
    </div>
  );
}
