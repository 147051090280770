import { Loader } from "@progress/kendo-react-indicators";
import "./style.scss";

// Taken from: https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-loading-indicator/
const LoadingPanel = () => (
  <div
    data-testid="grid-loading-panel"
    className="k-loading-mask grid-loading-panel"
  >
    <span className="k-loading-text">Loading</span>
    <Loader size="large" type="infinite-spinner" className="loading-spinner" />
    <div className="k-loading-color"></div>
  </div>
);

export default LoadingPanel;
