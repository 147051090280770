import { Link } from "react-router-dom";
import { FILE_IN_URL } from "../../../../../constants/urls";

type Props = {
  instance: string | undefined;
  instanceText: string | undefined;
};
export default function LinkToInstance({ instance, instanceText }: Props) {
  if (!instanceText) return <></>;

  let instanceLink = "#";
  if (instance) {
    /*
      "instance" kann z.B. so sein: "/api/db/Test-DB/cont/8a2024-07-08-15.31.13.6490@jlassi@abk.at"
      Wir entfernen "api" und wechseln "cont" für "files", sodass es im Frontend
      funktioniert.
    */
    const instanceCorrespondingToFrontend = instance
      .replace("/api", "")
      .replace("/cont/", `/${FILE_IN_URL}/`);
    instanceLink = instanceCorrespondingToFrontend;
  }

  return <Link to={instanceLink}>{instanceText}</Link>;
}
