import Dexie, { EntityTable } from "dexie";
import { Job } from "../interfaces/Job";

interface JobDisplayed {
  database: string;
  jobId: string;
  job: Job;
}

const dbJobs = new Dexie("Jobs") as Dexie & {
  jobsDisplayed: EntityTable<JobDisplayed, "jobId">;
};

// Schema declaration:
dbJobs.version(1).stores({
  jobsDisplayed: "++jobId, database",
});

function addToJobsDisplayed(
  job: Job | undefined,
  database: string | undefined
) {
  if (!job || !database) return;

  const jobDisplayed: JobDisplayed = {
    database: database,
    jobId: job.JOBID,
    job: job,
  };
  return dbJobs.jobsDisplayed.add(jobDisplayed);
}

export type { JobDisplayed };
export { dbJobs, addToJobsDisplayed };
