import useListViewState from "./useListViewState";
import { ListViewTitle } from "./ListViewTitle";
import MobileListView from "./MobileListView";
import DesktopListView from "./DesktopListView";
import ListToolBar, { Tools } from "./ListToolBar";

export type DisplayItem = {
  key: string;
  name: string;
  type: "number" | "text";
};

type ABKListViewProps = {
  data: any[];
  setData: (data: any[]) => void;
  dataToDisplay: DisplayItem[];
  titleText?: string;
  label?: string;
  isMobile?: boolean;
  tools: Tools;
};

const ABKListView = ({
  data,
  setData,
  label,
  isMobile,
  dataToDisplay,
  tools,
}: ABKListViewProps) => {
  const {
    componentData,
    onDragOver,
    updateData,
    handleAddItem,
    isEditMode,
    setIsEditMode,
  } = useListViewState({
    data,
    setData,
  });

  return (
    <div data-testid="abk-list-view">
      <ListToolBar
        handleAddItem={handleAddItem}
        isEditMode={isEditMode}
        tools={tools}
        setIsEditMode={setIsEditMode}
        isMobile={isMobile}
      />
      <div
        data-testid="list-view-container"
        style={{ display: "flex", width: "100%", flexDirection: "column" }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {label && <ListViewTitle label={label} data-testid="list-view-title" />}
        {!isMobile ? (
          <DesktopListView
            data-testid="desktop-list-view"
            componentData={componentData}
            updateData={updateData}
            onDragOver={onDragOver}
            setData={setData}
            dataToDisplay={dataToDisplay}
            isEditMode={isEditMode}
            noDnd={tools.dragDrop === false}
          />
        ) : (
          <MobileListView
            data-testid="mobile-list-view"
            componentData={componentData}
            dataToDisplay={dataToDisplay}
            updateData={updateData}
            isEditMode={isEditMode}
          />
        )}
      </div>
    </div>
  );
};

export default ABKListView;
