import { Location } from "react-router-dom";
import { navigationItems } from "src/Router/Layout/navigationItems";

interface Args {
  location: Location;
  dbName: string;
  contId: string;
  customTitle: string;
}

export function getActiveNavigationItems({
  location,
  dbName,
  contId,
  customTitle,
}: Args) {
  const segments = location.pathname.split("/").filter(Boolean);
  const breadcrumbData = segments
    .map((segment) => {
      if (!navigationItems.hasOwnProperty(segment)) return undefined;
      return navigationItems[segment as keyof typeof navigationItems](
        dbName,
        contId,
        customTitle
      );
    })
    .filter((item) => !!item);

  const lastBreadcrumb = breadcrumbData[breadcrumbData.length - 1];
  if (lastBreadcrumb) {
    breadcrumbData[breadcrumbData.length - 1] = {
      ...lastBreadcrumb,
      disabled: true,
    } as any;
  }

  return breadcrumbData;
}
