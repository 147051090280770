import { useState } from "react";
import EintragBearbeitenExpansionPanelPreview from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/EintragBearbeitenExpansionPanelPreview";
import { generatePreviewTextForAuswahlliste } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/previewTextGenerator";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKListView, {
  DisplayItem,
} from "../../../../../../../abk-shared/components/organisms/ABKListView";
import useIsMobileTemplate from "../../../../../../../abk-shared/utility/useIsMobileTemplate";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const useAWList = (props: PropsEintragPanel) => {
  const { setPageState, pageState } = props;

  const isZahl = pageState?.ITEMTYPE === "Zahl";

  const key = isZahl ? "AWList_Num" : "AWList_Text";

  const zahlData = pageState?.AWList_Num;
  const textData = pageState?.AWList_Text;

  const data = isZahl ? zahlData : textData;

  const setData = (data: any[]) => {
    if (pageState) {
      setPageState({
        ...pageState,
        [key]: data,
      });
    }
  };

  const textDisplayData: DisplayItem[] = [
    {
      key: "Eintrag",
      name: "Wert",
      type: "text",
    },
    {
      key: "Bezeichnung",
      name: "Bezeichnung",
      type: "text",
    },
  ];

  const zahlDisplayData: DisplayItem[] = [
    {
      key: "Wert",
      name: "Wert",
      type: "number",
    },
  ];

  const displayData = isZahl ? zahlDisplayData : textDisplayData;

  return {
    displayData,
    data,
    setData,
  };
};

const AuswahllistePanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  const [expanded, setExpanded] = useState(false);

  const isMobileTemplate = useIsMobileTemplate();

  const { data, setData, displayData } = useAWList({
    setPageState,
    pageState,
  });

  const previewText = generatePreviewTextForAuswahlliste(
    pageState?.AWList_Text
  );

  return (
    <ABKControlledExpansionPanel
      title={
        <EintragBearbeitenExpansionPanelPreview
          title="Auswahlliste"
          previewText={previewText}
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKListView
        tools={{
          addItem: true,
          dragDrop: true,
          deleteAll: false,
        }}
        dataToDisplay={displayData}
        setData={setData}
        isMobile={isMobileTemplate}
        data={data || []}
      />
    </ABKControlledExpansionPanel>
  );
};

export default AuswahllistePanel;
