import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Versioned } from "src/core/interfaces/Versioned";
import { GliederungssystemeBackendResponse } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { formatWithEtag } from "src/modules/Datei/queries/formatWithEtag";

export const QUERY_KEY_GLIEDERUNGSSYSTEME = "QUERY_KEY_GLIEDERUNGSSYSTEME";

export const useGetGliederungssysteme = (db: string, contId: string) => {
  const getGliederungssysteme = async () => {
    const url = `/api/db/${db}/cont/${contId}/gs`;
    const response = await axios.get<GliederungssystemeBackendResponse>(url);
    return formatWithEtag<GliederungssystemeBackendResponse>(response);
  };

  return useQuery<Versioned<GliederungssystemeBackendResponse>>({
    queryKey: [QUERY_KEY_GLIEDERUNGSSYSTEME, db, contId],
    queryFn: () => getGliederungssysteme(),
    enabled: Boolean(db) && Boolean(contId),
  });
};
