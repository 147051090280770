import axios from "axios";
import { Meta } from "src/core/interfaces/meta.types";
import { Job } from "../../interfaces/Job";

interface FetchJobResponse {
  Meta: Meta;
  Items: Job[];
}

export default async function fetchJobs(url: string) {
  try {
    const response = await axios.get(url);
    const json: FetchJobResponse = response.data;
    const jobs: Job[] = [...json.Items];
    return jobs.sort((a, b) => {
      return new Date(b.TIMESTART).getTime() - new Date(a.TIMESTART).getTime();
    }) as Job[];
  } catch (error) {
    throw error;
  }
}
