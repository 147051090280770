type LocalStorageScrollTop = {
  isAtBottom: boolean;
  value: number;
};

export function setScrollTopLocalStorage(
  element: HTMLElement,
  gridUniqueId: string,
  isMobileGrid: boolean
) {
  const localStorageScrollTop: LocalStorageScrollTop = {
    isAtBottom: false,
    value: element.scrollTop,
  };

  // Von: https://stackoverflow.com/a/49573628
  const scrollIsAtBottom =
    Math.abs(
      element.scrollHeight - (element.scrollTop + element.clientHeight)
    ) <= 1;
  localStorageScrollTop.isAtBottom = scrollIsAtBottom;

  localStorage.setItem(
    generateLocalStorageKey(isMobileGrid, gridUniqueId),
    JSON.stringify(localStorageScrollTop)
  );
}

export function getScrollTopLocalStorage(
  gridUniqueId: string,
  isMobileGrid: boolean
) {
  const localStorageScrollTopString = localStorage.getItem(
    generateLocalStorageKey(isMobileGrid, gridUniqueId)
  );
  if (localStorageScrollTopString == null) return 0;

  const localStorageScrollTop: LocalStorageScrollTop = JSON.parse(
    localStorageScrollTopString
  );

  // Von: https://stackoverflow.com/a/270628
  if (localStorageScrollTop.isAtBottom) {
    const offsetToEnsureItWillBeAtBottom = 10_000;
    return localStorageScrollTop.value + offsetToEnsureItWillBeAtBottom;
  }

  return localStorageScrollTop.value;
}

const PREFIX_LOCAL_STORAGE = "grid-scroll-top";

const generateLocalStorageKey = (isMobile: boolean, gridUniqueId: string) =>
  `${PREFIX_LOCAL_STORAGE}-${isMobile ? "mobile" : "desktop"}-${gridUniqueId}`;
