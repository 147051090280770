import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import createFunctionToMigrateColumns from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridColumns/persistedColumnsStore/createFunctionToMigrateColumns";
import ABKBasisGrid from "../../../../abk-shared/components/organisms/ABKBasisGrid";
import { DATA_KEY } from "../../../../abk-shared/components/organisms/ABKBasisGrid/constants";
import {
  GridColumnPropsCustom,
  GridColumnsMigrateFunction,
} from "../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridColumns";
import { formatDateTimeToBrowserDefault } from "../../../../abk-shared/utility/dateUtils";
import { Job } from "../../../interfaces/Job";
import createQuickInfoForJob from "../../templates/MainPageTemplate/generateSidebarElements/job/createQuickInfoForJob";
import generateQuickInfoForLogsAndFiles from "../../templates/MainPageTemplate/generateSidebarElements/job/generateQuickInfoForLogsAndFiles";
import useQuickInfoOnGridSelection from "../../templates/MainPageTemplate/generateSidebarElements/useQuickInfoOnGridSelection";
import JobCard from "./JobCard";

type Props = { jobs: Job[]; gridUniqueId: string };
export default function JobGrid({ jobs, gridUniqueId }: Props) {
  const jobsWithFormattedDate = React.useMemo(
    () =>
      jobs.map((job) => ({
        ...job,
        TIMESTART: {
          [DATA_KEY.INTERNAL]: new Date(job.TIMESTART),
          [DATA_KEY.DISPLAYED]: formatDateTimeToBrowserDefault(job.TIMESTART),
        },
        TIMEEND: {
          [DATA_KEY.INTERNAL]: new Date(job.TIMEEND),
          [DATA_KEY.DISPLAYED]: formatDateTimeToBrowserDefault(job.TIMEEND),
        },
      })),
    [jobs]
  );

  const columnsDefinition = React.useMemo(() => {
    const columns: GridColumnPropsCustom[] = [
      {
        migrationId: "CONTID",
        field: "CONTID",
        title: "CONTID",
        orderIndex: 2,
      },
      {
        migrationId: "JOBID",
        field: "JOBID",
        title: "JOBID",
        orderIndex: 4,
      },
      {
        migrationId: "OWNER",
        field: "OWNER",
        title: "OWNER",
        orderIndex: 5,
      },
      {
        migrationId: "TIMESTART",
        field: "TIMESTART",
        title: "TIMESTART",
        orderIndex: 6,
        hasInternalValue: true,
      },
      {
        migrationId: "TIMEEND",
        field: "TIMEEND",
        title: "TIMEEND",
        orderIndex: 7,
        hasInternalValue: true,
      },
      {
        migrationId: "COMMAND",
        field: "COMMAND",
        title: "COMMAND",
        orderIndex: 8,
      },
      {
        migrationId: "PARAMS",
        field: "PARAMS",
        title: "PARAMS",
        orderIndex: 10,
      },
      {
        migrationId: "DESCRIPTION",
        field: "DESCRIPTION",
        title: "DESCRIPTION",
        orderIndex: 11,
      },
      {
        migrationId: "STATUS",
        field: "STATUS",
        title: "STATUS",
        orderIndex: 12,
      },
      {
        migrationId: "CNTERR",
        field: "CNTERR",
        title: "CNTERR",
        orderIndex: 19,
      },
      {
        migrationId: "CNTWARN",
        field: "CNTWARN",
        title: "CNTWARN",
        orderIndex: 20,
      },
      {
        migrationId: "CNTINFO",
        field: "CNTINFO",
        title: "CNTINFO",
        orderIndex: 21,
      },
      {
        migrationId: "LogUrl",
        field: "LogUrl",
        title: "LogUrl",
        orderIndex: 22,
      },
      {
        migrationId: "FilesUrl",
        field: "FilesUrl",
        title: "FilesUrl",
        orderIndex: 23,
      },
    ];

    const version = 4;

    const migrate: GridColumnsMigrateFunction = createFunctionToMigrateColumns(
      version,
      columns
    );

    return { columns, version, migrate };
  }, []);

  const queryClient = useQueryClient();
  const generateQuickInfoForOneItem = async (item: any) => {
    const quickInfo = createQuickInfoForJob(item);
    const quickInfoForLogsAndFiles = await generateQuickInfoForLogsAndFiles(
      queryClient,
      item
    );
    const quickInfoContent = [...quickInfo, ...quickInfoForLogsAndFiles];
    return quickInfoContent;
  };
  const onSelectionChange = useQuickInfoOnGridSelection(
    generateQuickInfoForOneItem
  );

  return (
    <ABKBasisGrid
      data={jobsWithFormattedDate}
      columnsDefinition={columnsDefinition}
      persistedDataStateId={{ sector: "jobs", unique: gridUniqueId }}
      dataItemKey="JOBID"
      selection={{ onSelectionChange, hasCheckboxSelection: false }}
      mobileGrid={{
        rowHeight: 110,
        renderRow: (rowRef, item, filterValue) => (
          <JobCard
            rowRef={rowRef}
            item={item as Job}
            filterValue={filterValue}
            onSelectionChange={onSelectionChange}
          />
        ),
      }}
    />
  );
}
