export function isValidDate(date: unknown) {
  return date instanceof Date && !isNaN(date.getTime());
}

export const formatDateTimeToBrowserDefault = (
  date: Date | string | null | undefined
) => {
  let dateObj = date;

  // Check if the date is a string and try to convert it to a Date object
  if (typeof date === "string") dateObj = new Date(date);

  // Check if the dateObj is a valid Date object
  if (dateObj == null || !isValidDate(dateObj)) {
    const returnValue = typeof date === "string" ? date : "";
    return returnValue;
  }

  const dateTimeFormatted = (dateObj as Date).toLocaleTimeString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return dateTimeFormatted;
};
