import DateiImport from "./DateiImport";
import useIsValidDB from "../../../../core/utility/useIsValidDB";

export default function ImportWrapper() {
  const { isValidDB, db } = useIsValidDB();
  if (!isValidDB) return <></>;

  const validDB = db as string;
  return <DateiImport key={`import-${validDB}`} db={validDB} />;
}
