import { State } from "@progress/kendo-data-query";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type GridDataState = {
  dataState: State;
  setDataState: (dataState: State) => void;
};

const localStorageKeys = {
  sort: "sort",
  group: "group",
};

const createPersistedDataStateStore = (gridSectorId: string) =>
  create<GridDataState>()(
    persist(
      (set) => ({
        dataState: {
          [localStorageKeys.sort]: [],
          [localStorageKeys.group]: [],
          take: 70,
          skip: 0,
          filter: {
            logic: "or",
            filters: [],
          },
        },
        setDataState: (dataState: State) =>
          set(() => ({ dataState: dataState })),
      }),
      {
        name: `grid-${gridSectorId}-dataState`,
        version: 2,
        // From https://docs.pmnd.rs/zustand/integrations/persisting-store-data#migrate
        migrate: (persistedState, version) => {
          if (version === 1) {
            const newPersistedState = persistedState as State;
            // Wurde in version 2 gelöscht, da "take" vom User nie geändert sein wird
            delete newPersistedState.take;
            /*
              Wurde in version 2 hinzugefügt, um einen Bug zu fixen.
              Diese 2 Eigenschaften waren in anderen "useState".

              Der Bug ist folgendes:
              Wir machen ein Update von "skip" und "dataState" gleichzeitig,
              im gleichen Event Handler.
              Aber "dataState" kommt von Zustand, und "skip" kommt von einem React
              useState.
              Beides sind NICHT gleichzeitig aktualisiert, und das verursacht ein
              kurzes Flakern des Grids.
              https://ib-data.atlassian.net/browse/ABK9-820
            */
            newPersistedState.skip = 0;
            newPersistedState.filter = {
              logic: "or",
              filters: [],
            };

            return newPersistedState;
          }

          return persistedState;
        },
        // From https://docs.pmnd.rs/zustand/integrations/persisting-store-data#partialize
        partialize: (state) => {
          const keysSavedInLocalStorage = Object.values(localStorageKeys);

          const localStorageEntries = Object.entries(state.dataState).filter(
            ([key]) => keysSavedInLocalStorage.includes(key)
          );

          const localStorageDataState = Object.fromEntries(localStorageEntries);
          return localStorageDataState;
        },
        merge: (persistedState, currentState) => {
          const { sort, group } = persistedState as {
            sort?: State["sort"];
            group?: State["group"];
          };

          if (sort) currentState.dataState.sort = sort;
          if (group) currentState.dataState.group = group;

          return currentState;
        },
      }
    )
  );

export default createPersistedDataStateStore;
