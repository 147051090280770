import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AbkIconsMap } from "../../../../abk-shared/interfaces/abk9SvgIcons";
import { Button } from "@progress/kendo-react-buttons";
import "./style.css";

type Props = {
  text: string | undefined;
  width?: number;
  customButton?: (isCopied: boolean) => React.ReactNode;
};
const ABKButtonCopyToClipboard = ({ text, width, customButton }: Props) => {
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2_000);
    }
  }, [isCopied]);

  const buttonText = "In die Zwischenablage kopieren";

  return (
    <CopyToClipboard text={text ?? ""} onCopy={() => setIsCopied(true)}>
      {customButton ? (
        customButton(isCopied)
      ) : (
        <Button
          onClick={(event) => event.stopPropagation()}
          svgIcon={
            isCopied
              ? AbkIconsMap.get("Log.Check")
              : AbkIconsMap.get("Grid.Copy")
          }
          fillMode="link"
          className="button-copy-to-clipboard"
          style={{ width: width }}
          title={buttonText}
        >
          {width && width > 150 && <>{buttonText}</>}
        </Button>
      )}
    </CopyToClipboard>
  );
};

export default ABKButtonCopyToClipboard;
