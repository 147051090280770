import WeitereGliederungssystemePage from "src/modules/Datei/pages/WeitereGliederungssysteme/WeitereGliederungssystemePage";

const WeitereGliederungssysteme = () => {
  // Commented out, because we will need it later. But now, the variables are unused.

  // const { isValid, db, contId } = useIsValidDbAndContIdInURL();

  // if (!isValid) return <></>;

  return <WeitereGliederungssystemePage />;
};

export default WeitereGliederungssysteme;
