import ABKExpansionPanel from "../../../../abk-shared/components/atoms/ABKExpansionPanel";
import ABKFormFieldInput from "../../../../abk-shared/components/atoms/ABKFormFieldInput";
import ABKFormRow from "../../../../abk-shared/components/atoms/ABKFormRow";
import { ABKLoader } from "../../../../abk-shared/components/atoms/ABKLoader";
import useGetVersion from "../../../Startseite/hooks/useGetVersion";
import "./style.css";

const SysInfoPage = () => {
  const { data, isLoading } = useGetVersion();

  if (isLoading) {
    return <ABKLoader />;
  }

  if (!data) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <ABKExpansionPanel title="Systeminformationen" defaultExpanded={true}>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="InfoCreated"
            label="Information erstellt"
            value={data?.InfoCreated}
          />
          <ABKFormFieldInput
            editorId="ServerInfo"
            label="Serverinformationen"
            value={data?.ServerInfo}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="ServerVersion"
            label="Serverversion"
            value={data?.ServerVersion}
          />
          <ABKFormFieldInput
            editorId="ServerExe"
            label="Server-Executable"
            value={data?.ServerExe}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="HostInfo"
            label="Hostinformationen"
            value={data?.HostInfo}
          />
          <ABKFormFieldInput
            editorId="RedisServer"
            label="Redis-Server"
            value={data?.RedisServer}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="PortalServer"
            label="Portal-Server"
            value={data?.PortalServer}
          />
          <ABKFormFieldInput
            editorId="Client"
            label="Client"
            value={data?.Client}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="UserAgent"
            label="User-Agent"
            value={data?.UserAgent}
          />
          <ABKFormFieldInput
            editorId="Language"
            label="Sprache"
            value={data?.Language}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="Tenantname"
            label="Tenantname"
            value={data?.Tenantname}
          />
          <ABKFormFieldInput
            editorId="TenantnameDisplay"
            label="Anzeigename des Tenants"
            value={data?.TenantnameDisplay}
          />
        </ABKFormRow>
        <ABKFormRow>
          <ABKFormFieldInput
            editorId="AuthUser"
            label="Authentifizierter Benutzer"
            value={data?.AuthUser}
          />
          <ABKFormFieldInput
            editorId="AuthUserDisplay"
            label="Anzeigename des Benutzers"
            value={data?.AuthUserDisplay}
          />
        </ABKFormRow>
      </ABKExpansionPanel>
    </div>
  );
};

export default SysInfoPage;
