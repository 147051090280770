import { Button } from "@progress/kendo-react-buttons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import React from "react";
import { DB } from "../../../../abk-shared/interfaces/db";
import { addToJobsDisplayed } from "../../../../core/db/dbJobs";
import { QUERY_KEY_DB } from "../../queries/useGetDBStatus";

type Props = { db: DB | undefined };
export default function DbActionButtons({ db }: Props) {
  const queryClient = useQueryClient();

  const onSuccess = React.useCallback(
    async (response: AxiosResponse<any, any>) => {
      const job = response.data;
      await addToJobsDisplayed(job, db?.DBNAME);
      return queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_DB, db?.DBNAME],
      });
    },
    [db?.DBNAME]
  );

  const createDB = useMutation({
    mutationFn: () => {
      return axios.post(
        `/api/db/${db?.DBNAME}/run/createdb?createserverdb=true`
      );
    },
    onSuccess,
  });

  const updateDB = useMutation({
    mutationFn: () => axios.post(`/api/db/${db?.DBNAME}/run/updatedb`),
    onSuccess,
  });

  const repairDB = useMutation({
    mutationFn: () => axios.post(`/api/db/${db?.DBNAME}/run/repairdb`),
    onSuccess,
  });

  const loadingMessage = "Anfrage wird abgeschickt...";

  return (
    <div className="k-d-flex k-gap-sm k-flex-wrap k-mt-sm">
      {db?.DBStatus?.Condition === "UpdateRequired" && (
        <Button
          disabled={updateDB.isPending}
          fillMode="solid"
          onClick={() => updateDB.mutate()}
        >
          {updateDB.isPending
            ? loadingMessage
            : "Datenbankstruktur aktualisieren"}
        </Button>
      )}
      {db?.DBStatus?.Condition === "DBNotFound" && (
        <Button
          disabled={createDB.isPending}
          fillMode="solid"
          onClick={() => createDB.mutate()}
        >
          {createDB.isPending ? loadingMessage : "Datenbank anlegen"}
        </Button>
      )}
      <Button
        disabled={repairDB.isPending}
        fillMode="solid"
        onClick={() => repairDB.mutate()}
      >
        {repairDB.isPending ? loadingMessage : "Datenbank reparieren versuchen"}
      </Button>
    </div>
  );
}
