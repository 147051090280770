import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useValidationError } from "src/abk-shared/stores/ValidationErrorStoreProvider";
import { setValidationErrorFromABKError } from "src/abk-shared/stores/ValidationErrorStoreProvider/useGetValidationErrorMessage";
import { BACKEND_URL } from "src/core/constants/urls";
import { QUERY_KEY_PARAMETERLISTE } from "src/modules/Datei/queries/useGetSingleParameterliste";
import { PiktogrammItem } from "./piktogramm.types";
import { QUERY_KEY_PIKTOGRAMME } from "./useGetPiktogramme";

async function createPiktogramm(
  url: string,
  data: PiktogrammItem
): Promise<any> {
  return axios.post(url, data);
}

export const useCreatePiktogramm = (
  dbName: string,
  contId: string,
  plID: string
) => {
  const queryClient = useQueryClient();
  const { setValidationErrors } = useValidationError();
  const url = `${BACKEND_URL}/api/db/${dbName}/cont/${contId}/pl/${plID}/pikto`;
  return useMutation({
    mutationFn: async (data: PiktogrammItem) => {
      return createPiktogramm(url, data);
    },
    onSuccess: () => {
      setValidationErrors([]);
      return queryClient.invalidateQueries({
        queryKey: [
          dbName,
          contId,
          plID,
          QUERY_KEY_PARAMETERLISTE,
          QUERY_KEY_PIKTOGRAMME,
        ],
      });
    },
    onError: (error) => {
      setValidationErrorFromABKError(error, setValidationErrors);
    },
  });
};
