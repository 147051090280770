import { SvgIcon } from "@progress/kendo-react-common";
import { Dummy_Empty } from "../../../interfaces/abk9SvgIcons";
import "./styles.scss";

export default function Columns({
  objectKeys,
  noDnd,
}: {
  objectKeys: string[];
  noDnd?: boolean;
}) {
  return (
    <div className="list-view-columns">
      {!noDnd && (
        <SvgIcon
          width={20}
          height={20}
          style={{ marginRight: 2 }}
          icon={Dummy_Empty}
        />
      )}
      <div style={{ display: "flex", gap: 20, flexGrow: 1 }}>
        {objectKeys.map((key) => (
          <span
            className="k-column-title"
            style={{ flex: 1, color: "gray", fontWeight: "bold" }}
            key={key}
          >
            {key}
          </span>
        ))}
      </div>
      <SvgIcon width={20} height={20} icon={Dummy_Empty} />
      <SvgIcon width={20} height={20} icon={Dummy_Empty} />
    </div>
  );
}
