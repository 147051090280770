import { PropsCommunicationComponents } from ".";
import addHttpToLinkIfNotPresent from "../../addHttpToLinkIfNotPresent";
import ABKFormFieldInput from "../../../../../../../../abk-shared/components/atoms/ABKFormFieldInput";
import shouldDisplayJSXInsteadOfInput from "./shouldDisplayJSXInsteadOfInput";

export default function Website({
  value,
  readOnly,
  changeValue,
  backendKeyPrefix,
}: PropsCommunicationComponents) {
  const useJSXForValue = shouldDisplayJSXInsteadOfInput(value, readOnly);
  const websiteFormatted = useJSXForValue ? (
    <a
      className="kenndaten-link"
      href={addHttpToLinkIfNotPresent(value)}
      target="_blank"
      rel="noreferrer"
    >
      {value}
    </a>
  ) : (
    value
  );

  const setWebsite = readOnly
    ? undefined
    : (value: string) => changeValue("www", value);

  return (
    <ABKFormFieldInput
      editorId="website"
      label="Webseite"
      value={websiteFormatted}
      setValue={setWebsite}
      useJSXForValue={useJSXForValue}
      backendKey={`${backendKeyPrefix}.kommunikation.www`}
    />
  );
}
