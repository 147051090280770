import { EditorTools, EditorToolsSettings } from "@progress/kendo-react-editor";
import CustomTableBorderButton from "./CustomTableBorderButton";
import { InsertImage } from "./insertImageTool";
import CustomFindAndReplace from "./ToolsCustomized/CustomFindAndReplace";
import FontSizeTool from "./ToolsCustomized/FontSizeTool";
import FormatBlockTool from "./ToolsCustomized/FormatBlockTool";
import OenormBold from "./ToolsCustomized/OenormBold";
import OenormItalic from "./ToolsCustomized/OenormItalic";
import oenormUnderline from "./ToolsCustomized/OenormUnderline";
import Undo = EditorTools.Undo;
import Redo = EditorTools.Redo;
import Subscript = EditorTools.Subscript;
import Superscript = EditorTools.Superscript;
import AlignLeft = EditorTools.AlignLeft;
import AlignCenter = EditorTools.AlignCenter;
import AlignRight = EditorTools.AlignRight;
import AlignJustify = EditorTools.AlignJustify;
import OrderedList = EditorTools.OrderedList;
import UnorderedList = EditorTools.UnorderedList;
import Indent = EditorTools.Indent;
import Outdent = EditorTools.Outdent;
import FontName = EditorTools.FontName;
import BackColor = EditorTools.BackColor;
import ForeColor = EditorTools.ForeColor;
import InsertTable = EditorTools.InsertTable;
import AddRowBefore = EditorTools.AddRowBefore;
import AddRowAfter = EditorTools.AddRowAfter;
import AddColumnBefore = EditorTools.AddColumnBefore;
import AddColumnAfter = EditorTools.AddColumnAfter;
import DeleteRow = EditorTools.DeleteRow;
import DeleteColumn = EditorTools.DeleteColumn;
import DeleteTable = EditorTools.DeleteTable;
import SplitCell = EditorTools.SplitCell;
import MergeCells = EditorTools.MergeCells;
import CleanFormatting = EditorTools.CleanFormatting;

// From: https://www.telerik.com/kendo-react-ui/components/editor/tools/#toc-customizing-built-in-tools
const CustomBackColor = (props: any) => (
  <BackColor
    {...props}
    colorPickerProps={{
      ...EditorToolsSettings.backColor.colorPickerProps,
      paletteSettings: {
        ...EditorToolsSettings.backColor.colorPickerProps.paletteSettings,
        palette: "basic",
      },
    }}
  />
);

const CustomForeColor = (props: any) => (
  <ForeColor
    {...props}
    colorPickerProps={{
      ...EditorToolsSettings.foreColor.colorPickerProps,
      paletteSettings: {
        ...EditorToolsSettings.foreColor.colorPickerProps.paletteSettings,
        palette: "basic",
      },
    }}
  />
);

const editorTools = {
  oenormTools: [
    [Undo, Redo],
    [OenormBold, OenormItalic, oenormUnderline],
    [Subscript, Superscript],
    [UnorderedList, OrderedList],
    FormatBlockTool,
  ],
  oenormTabelleTools: [[InsertTable]],

  fontTools: [FontName, FontSizeTool, CustomBackColor, CustomForeColor],

  commonTools: [
    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
    [Outdent, Indent],
    [CleanFormatting],
    [InsertImage],
  ],
  tableTools: [
    [CustomTableBorderButton],
    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
    [DeleteRow, DeleteColumn, DeleteTable],
    [MergeCells, SplitCell],
  ],
  extraTools: [[CustomFindAndReplace]],
};
export default editorTools;
