import { Loader } from "@progress/kendo-react-indicators";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { DEFAULT_DB } from "../core/constants/urls";
import useCurrentDBStore from "../core/stores/useCurrentDBStore";
import useNotificationStore, {
  ErrorNotification,
} from "../core/stores/useNotificationStore";
import useGetDB from "./Startseite/hooks/useGetDB";

const ModulesLayout = () => {
  const currentPath = useLocation().pathname;
  const params = useParams();
  const db = params.db;

  const { data: dbList, isLoading } = useGetDB();
  const { setCurrentDB } = useCurrentDBStore();

  const navigate = useNavigate();
  const { addError } = useNotificationStore();

  const checkForDB = async () => {
    const shouldChangeDB = dbList && db === DEFAULT_DB;
    const dbInURLMatchesDBFromBackend =
      db && dbList && dbList.some((dbItem) => dbItem.DBNAME === db);
    if (shouldChangeDB) {
      const savedDB = localStorage.getItem("currentDB");
      if (savedDB) {
        const defaultDB = dbList.find((db) => db.DBNAME === savedDB);
        if (!defaultDB) return;
        setCurrentDB(defaultDB);
        navigate(currentPath.replace("_default_", defaultDB.DBNAME), {
          replace: true,
        });
      } else {
        const defaultDB = dbList[0];
        setCurrentDB(defaultDB);
        navigate(currentPath.replace("_default_", defaultDB.DBNAME), {
          replace: true,
        });
      }
      return;
    }

    if (dbInURLMatchesDBFromBackend) {
      const selectedDB = dbList.find((dbItem) => dbItem.DBNAME === db);
      if (!selectedDB) return;
      setCurrentDB(selectedDB);
      /*
        Bitte nicht diese Zeile unkommentieren!
        Die ist für einen Bug schuldig.

        Der Bug ist folgendes:
        Wenn man auf dem DrawerItem klickt, macht die
        Anwendung ein Navigate zu z.B. "/db/Test-DB/start/new".
        Dann, das Component verantwortlich für "/db/Test-DB/start/new" macht ein
        <Navigate to={LAST_USED} />.
        Der Bug ist, dass dieses Navigate nicht funktioniert.

        Dieser Bug tritt nur mit dem Production Build ("npm run build") auf.
        Nicht mit dem Development Build ("npm start").
        Und dieser Bug ist nur da, wenn man auf einem DrawerItem klickt.
        Nicht wenn wir die Seite unter der URL "/db/Test-DB/start/new" aktualisieren.
       */
      // navigate(currentPath.replace(db, selectedDB!.DBNAME));
      return;
    }

    const message = `Datenbank "${db}" konnte nicht gefunden werden.`;
    let details = message;
    details += "\n";
    details +=
      "Bitte die eingegebene Datenbank in der URL überprüfen oder eine gültige Datenbank in der oberen Menüleiste auswählen.";
    const errorNotification: ErrorNotification = {
      message: message,
      type: "error",
      JSError: { name: "Fehler", message: details },
      closable: true,
    };
    addError(errorNotification);
  };

  useEffect(() => {
    if (!isLoading) void checkForDB();
  }, [currentPath, db, dbList, navigate, setCurrentDB, isLoading]);

  if (isLoading) {
    return (
      <div className="k-d-flex k-justify-content-center k-align-items-center k-h-full">
        <Loader size="large" type="infinite-spinner" />
      </div>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default ModulesLayout;
