import axios from "axios";
import { DB } from "../../abk-shared/interfaces/db";
import { BACKEND_URL } from "../constants/urls";

interface ApiResponse {
  Meta: string | null;
  Items: DB[];
}

const fetchDB = async (): Promise<DB[]> => {
  try {
    const response = await axios.get<ApiResponse>(BACKEND_URL + "/api/db");
    return response.data.Items;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Fetching databases failed:", error);
    throw error;
  }
};

export default fetchDB;
