import { ABKError } from "../../../../../../abk-shared/interfaces/Error";
import "./style.scss";
import ItemContent from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo/ItemContent";
import LinkToInstance from "./LinkToInstance";
import TechnicalInformation, {
  TECHNICAL_INFORMATION,
} from "./TechnicalInformation";
import { InfoItem } from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";

export const createQuickInfoForABKError = (abkError: ABKError) => {
  let errorTitle = "Fehler";

  switch (abkError.Severity) {
    case "E":
      errorTitle = "Fehler";
      break;
    case "W":
      errorTitle = "Warnung";
      break;
    case "H":
      errorTitle = "Hinweis";
      break;
    case "I":
      errorTitle = "Info";
      break;
    case "O":
      errorTitle = "OK";
      break;
    case "D":
      errorTitle = "Debug";
      break;
  }

  return {
    quickInfoContent: [
      {
        title: errorTitle,
        content: (
          <>
            <ItemContent itemContent={abkError.title} />
            <ItemContent
              itemContent={abkError.detail}
              shouldDisplayNoContent={false}
            />
            <ItemContent
              itemContent={
                <LinkToInstance
                  instance={abkError.instance}
                  instanceText={abkError.InstanceText}
                />
              }
            />
          </>
        ),
      },
      <TechnicalInformation abkError={abkError} />,
    ] as InfoItem[],
    quickInfoClipboard: createClipboardTextABKError(errorTitle, abkError),
  };
};

function createClipboardTextABKError(errorTitle: string, abkError: ABKError) {
  let textCopiedToClipboard = "";

  textCopiedToClipboard += `[${errorTitle}]`;
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.title;
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.detail;
  textCopiedToClipboard += `${abkError.InstanceText}: ${abkError.instance}`;
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += "\n";

  textCopiedToClipboard += `[${TECHNICAL_INFORMATION}]`;
  textCopiedToClipboard += "\n";

  textCopiedToClipboard += "[Meldungscode]";
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.type;
  textCopiedToClipboard += "\n";

  textCopiedToClipboard += "[HTTP-Statuscode]";
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.status;
  textCopiedToClipboard += "\n";

  textCopiedToClipboard += "[TechInfo]";
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.TechInfo;
  textCopiedToClipboard += "\n";

  textCopiedToClipboard += "[Callstack]";
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += abkError.CallStack;
  textCopiedToClipboard += "\n";

  return textCopiedToClipboard;
}

export function createQuickInfoForJSError(jsError: any) {
  return {
    quickInfoContent: [
      {
        title: "Fehler",
        content: (
          <>
            <ItemContent itemContent={jsError?.message} />
            <ItemContent
              itemContent={jsError?.stack || jsError?.response?.data?.Message}
              shouldDisplayNoContent={false}
            />
          </>
        ),
      },
    ] as InfoItem[],
    quickInfoClipboard: createClipboardTextJSError(jsError),
  };
}

function createClipboardTextJSError(jsError: any) {
  let textCopiedToClipboard = "";

  textCopiedToClipboard += "[Fehler]";
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += jsError?.message;
  textCopiedToClipboard += "\n";
  textCopiedToClipboard += jsError?.stack || jsError?.response?.data?.Message;

  return textCopiedToClipboard;
}
