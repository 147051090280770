import { GenericObject } from "../../../../../interfaces/GenericObject";
import { FunctionIdGetter } from "../../interfaces/FunctionIdGetter.types";
import { GridSelectionProps, SelectedState } from "./gridRowSelection.types";

export function createInitialSelectedState(
  initialSelectedItems: GenericObject[] | undefined,
  idGetter: FunctionIdGetter
) {
  const initialSelectedState: SelectedState = {};
  if (!initialSelectedItems) return initialSelectedState;

  for (const item of initialSelectedItems)
    initialSelectedState[idGetter(item)] = true;

  return initialSelectedState;
}

export function getDefaultValuesForSelectionProps(
  selection?: GridSelectionProps
) {
  const initialSelectedItems = selection?.initialSelectedItems;

  const onSelectionChangeFromParent = selection?.onSelectionChange;

  const hasCheckboxSelection =
    selection?.hasCheckboxSelection === undefined
      ? true
      : selection?.hasCheckboxSelection;

  return {
    initialSelectedItems,
    onSelectionChangeFromParent,
    hasCheckboxSelection,
  };
}
