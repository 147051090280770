import { Grid_MoveUp } from "src/abk-shared/interfaces/abk9SvgIcons";
import { Grid_MoveDown } from "src/abk-shared/interfaces/abk9SvgIcons";
import { SvgIcon } from "@progress/kendo-react-common";
import "../styles.scss";

const UpAndDownArrows = ({
  moveUp,
  moveDown,
}: {
  moveUp: () => void;
  moveDown: () => void;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
    <SvgIcon
      data-testid="move-up"
      style={{ cursor: "pointer", marginRight: 2 }}
      icon={Grid_MoveUp}
      height={20}
      width={20}
      onClick={moveUp}
    />
    <SvgIcon
      data-testid="move-down"
      style={{ marginRight: 2, cursor: "pointer" }}
      icon={Grid_MoveDown}
      height={20}
      width={20}
      onClick={moveDown}
    />
  </div>
);

export default UpAndDownArrows;
