import { Children, cloneElement, isValidElement, ReactNode } from "react";
import { PropsCustomCell } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell";
import HighlightedCell from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/components/CustomCell/HighlightedCell";
import { useEintragBearbeitenState } from "../EintragBearbeitenDialog/useEintragBearbeitenState";
import { parTypeMapper } from "../maps";

type Props = {
  text: string;
  props: PropsCustomCell;
  children?: ReactNode;
};
export default function CellGridText({ text, props, children }: Props) {
  const isBold = props.dataItem.ITEMTYPE === parTypeMapper.get("Set");
  const isGreen = props.dataItem.ITEMTYPE === parTypeMapper.get("Head");

  const { currentEintrag } = useEintragBearbeitenState();
  const isSelected = props.dataItem.ITEMID === currentEintrag?.ITEMID;

  const isSelectedStyle = {
    color: "#464600",
    fontWeight: "bold",
    fontStyle: "italic",
  };

  const cellContentStyle = {
    fontWeight: isBold || isGreen ? "bold" : "normal",
    color: isGreen ? "#999900" : "black",
    ...(isSelected && isSelectedStyle),
  };

  return (
    <HighlightedCell {...props}>
      {children ? (
        Children.map(children, (child) =>
          isValidElement(child)
            ? cloneElement(
                child,
                {},
                <span className="k-text-ellipsis" style={cellContentStyle}>
                  {text}
                </span>
              )
            : child
        )
      ) : (
        <span className="k-text-ellipsis" title={text} style={cellContentStyle}>
          {text}
        </span>
      )}
    </HighlightedCell>
  );
}
