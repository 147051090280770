import { SvgIcon } from "@progress/kendo-react-common";
import { Edit_Delete } from "../../../../interfaces/abk9SvgIcons";

const ListItemButtons = ({
  handleDelete,
  dataItem,
  isMobile,
}: {
  handleDelete: (id: string) => void;
  dataItem: any;
  isMobile?: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 1,
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <SvgIcon
        data-testid="delete-button"
        onClick={() => handleDelete(dataItem.id)}
        style={{ cursor: "pointer" }}
        icon={Edit_Delete}
        height={20}
        width={20}
      />
    </div>
  );
};

export default ListItemButtons;
