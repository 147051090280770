import { useNavigate } from "react-router-dom";
import useGetCont, { RequestType } from "../../hooks/useGetCont";
import ContainerCardGrid from "../../components/organisms/ContainerCardGrid";
import {
  getFileInfoUrlForContainer,
  useContainersWithFormattedDate,
} from "../../../../core/utility/cont";
import useCreateContFromTemplate from "../../hooks/useCreateContFromTemplate";
import { Loader } from "@progress/kendo-react-indicators";

type Props = {
  db: string;
  containerType: RequestType;
};

export default function Erstellen({ db, containerType }: Props) {
  const { data, isLoading } = useGetCont(db, containerType);
  const containersWithFormattedDate = useContainersWithFormattedDate(data);

  const mutationCreateContainer = useCreateContFromTemplate();
  const navigate = useNavigate();

  if (isLoading) return <Loader />;

  return (
    <ContainerCardGrid
      containerList={containersWithFormattedDate}
      cardProps={{
        buttonText: "Erstellen",
        buttonIcon: "Edit.New",
      }}
      onCardClick={async (containerID) => {
        const response = await mutationCreateContainer.mutateAsync({
          templateID: containerID,
          database: db,
        });

        navigate(getFileInfoUrlForContainer(db, response.data.CONTID));
      }}
    />
  );
}
