import { FunctionSetValidationErrors, useValidationError } from ".";
import { ABKError, ValidationError } from "../../interfaces/Error";

export default function useGetValidationErrorMessage(backendKey?: string) {
  const { validationErrors } = useValidationError();
  return getValidationErrorMessage(validationErrors, backendKey);
}

function getValidationErrorMessage(
  validationErrors: ValidationError[],
  fieldName: string | undefined
) {
  const messages = validationErrors
    .filter((error) => error.Fieldname === fieldName)
    .map((validationError) => validationError.title);

  if (messages.length === 0) return undefined;

  return <ValidationErrorMessage messages={messages} />;
}

const ValidationErrorMessage = ({ messages }: { messages: string[] }) => (
  <>
    {messages.map((message) => (
      <>
        {message}
        <br />
      </>
    ))}
  </>
);

export function setValidationErrorFromABKError(
  error: unknown,
  setValidationErrors: FunctionSetValidationErrors
) {
  const abkError = (error as any)?.response?.data;
  if (!abkError) return;

  if (!abkError.hasOwnProperty("Validations")) return;

  setValidationErrors((abkError as ABKError).Validations);
}
