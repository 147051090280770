import React from "react";
import { ToolbarActionObject } from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import useCreateToolbarActions from "src/abk-shared/components/molecules/ABKToolbar/useCreateToolbarButtons";
import DataStateContext from "../../hooks/useGridDataState/DataStateContext";
import { SelectionContext } from "../../hooks/useGridReorderRows/gridReorderRowsContexts";

type Props = {
  isMobileGrid: boolean;
};

export default function ABKToolbarGridActions({ isMobileGrid }: Props) {
  const { dataState, dataResultWithSelectedField, gridActions } =
    React.useContext(DataStateContext);
  const gridRowSelection = React.useContext(SelectionContext);

  const actions = React.useMemo(() => {
    const actions = gridActions
      ? gridActions(gridRowSelection, dataResultWithSelectedField, dataState)
      : [];

    for (const action of actions) {
      if (React.isValidElement(action)) continue;

      const actionObject = action as ToolbarActionObject;
      const actionObjectOnClick = actionObject.onClick;
      const onClickWithSelectedItems = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
      ) => {
        actionObjectOnClick(event, gridRowSelection.selectedItems);
      };
      actionObject.onClick = onClickWithSelectedItems;
    }

    return actions;
  }, [dataResultWithSelectedField, dataState, gridActions, gridRowSelection]);

  const toolbarLeftElements = useCreateToolbarActions(isMobileGrid, 3, actions);
  return toolbarLeftElements;
}
