import { create } from "zustand";
import { Job } from "../../../interfaces/Job";

type DialogType = "remove" | "cancel";

export type JobDialogResponse = {
  shouldProceed: boolean;
};

type JobDialogPromise = { resolve: (value: JobDialogResponse) => void };

interface JobDialogStore {
  toggleDialog: () => void;
  isDialogOpen: boolean;
  job: Job | undefined;
  setJob: (job: Job) => void;
  dialogType: undefined | DialogType;
  setDialogType: (dialogType: DialogType) => void;
  dialogPromise: null | JobDialogPromise;
  setDialogPromise: (promise: JobDialogPromise) => void;
}

const useJobDialogStore = create<JobDialogStore>((set) => ({
  isDialogOpen: false,
  toggleDialog: () => set((state) => ({ isDialogOpen: !state.isDialogOpen })),
  job: undefined,
  setJob: (job: Job) =>
    set(() => ({
      job: job,
    })),
  dialogType: undefined,
  setDialogType: (dialogType: DialogType) =>
    set(() => ({ dialogType: dialogType })),
  dialogPromise: null,
  setDialogPromise: (promise: JobDialogPromise) =>
    set(() => ({ dialogPromise: promise })),
}));

export default useJobDialogStore;
