import { produce } from "immer";
import React from "react";
import { SaveType } from "src/modules/Datei/pages/Parameterlisten/Eintraege/SaveTypeEnum";
import { DialogResponse } from "src/modules/Startseite/pages/Verwalten/delete/DeleteDialog";
import { GridRowSelection } from "../../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "../../EintragBearbeitenDialog/useEintragBearbeitenState";
import useDeleteEintrag from "../mutations/useDeleteEintrag";
import useMoveEintraege from "../mutations/useMoveEintraege";

export type EintraegeActionHandlers = ReturnType<
  typeof useEintraegeActionHandlers
>;

export default function useEintraegeActionHandlers(
  setIsGridLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dataItemKey: "ITEMID"
) {
  const {
    currentEintrag,
    setCurrentEintrag,
    setOpenDialog,
    setShowDeleteDialog,
    setShowNewItemDialog,
    selectedEintraege,
    setDeleteDialogPromise,
    setNewItemSaveType,
  } = useEintragBearbeitenState();

  const mutationMoveEintraege = useMoveEintraege(setIsGridLoading);
  const mutationDeleteEintrag = useDeleteEintrag(setIsGridLoading);

  const eintraegeActionHandlers = React.useMemo(
    () => ({
      open: (_event: any, selectedEintraege: EintragFrontend[]) => {
        const eintragSelected = selectedEintraege[0];
        setCurrentEintrag(eintragSelected);
        setOpenDialog(true);
      },
      delete: async (gridRowSelection: GridRowSelection) => {
        setShowDeleteDialog(true);
        const { shouldProceed } = await new Promise<DialogResponse>(
          (resolve) => {
            setDeleteDialogPromise({ resolve });
          }
        );
        if (!shouldProceed) return;

        await mutationDeleteEintrag.mutateAsync(selectedEintraege);

        const newSelectedState = produce(
          gridRowSelection.selectedState,
          (draft) => {
            for (const selectedEintrag of selectedEintraege)
              delete draft[selectedEintrag[dataItemKey]];
          }
        );
        gridRowSelection.setSelectedState(newSelectedState);

        const eintragDiplayedInDialogHasBeenDeleted = selectedEintraege.some(
          (selectedEintrag) =>
            selectedEintrag[dataItemKey] === currentEintrag?.[dataItemKey]
        );

        if (eintragDiplayedInDialogHasBeenDeleted) {
          setOpenDialog(false);
          setCurrentEintrag(null);
        }
      },
      newAtEnd: () => {
        setNewItemSaveType(SaveType.NewAtEnd);
        setShowNewItemDialog(true);
      },
      newBeforeItem: () => {
        setNewItemSaveType(SaveType.InsertBefore);
        setShowNewItemDialog(true);
      },
      moveDown: async (
        selectedEintraege: EintragFrontend[],
        anchorEintrag: EintragFrontend
      ) => {
        return mutationMoveEintraege.mutate({
          mode: "move-down",
          selectedEintraege,
          anchorEintrag,
        });
      },
      moveUp: async (
        selectedEintraege: EintragFrontend[],
        anchorEintrag: EintragFrontend
      ) => {
        return mutationMoveEintraege.mutate({
          mode: "move-up",
          selectedEintraege,
          anchorEintrag,
        });
      },
    }),
    [
      setCurrentEintrag,
      setOpenDialog,
      setShowDeleteDialog,
      mutationDeleteEintrag,
      selectedEintraege,
      setDeleteDialogPromise,
      dataItemKey,
      currentEintrag,
      setNewItemSaveType,
      setShowNewItemDialog,
      mutationMoveEintraege,
    ]
  );

  return eintraegeActionHandlers;
}
