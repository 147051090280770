import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import React from "react";
import ABKImageUploadButton from "src/abk-shared/components/atoms/ABKImageUploadButton";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import {
  Dummy_Missing,
  Edit_Delete,
  General_Export,
} from "src/abk-shared/interfaces/abk9SvgIcons";
import LogoDisplayer from "src/core/components/atoms/LogoDisplayer";
import downloadFile from "src/core/utility/downloadFile";
import {
  compressFileToLessThan64kB,
  generateBase64Image,
  getLogoFormat,
  LogoFormat,
  toBase64,
} from "src/core/utility/imageProcessing";
import {
  Kenndaten,
  Parameterliste,
} from "src/modules/Datei/interfaces/parameterlisten";

type Props = {
  pageState: Parameterliste;
  setPageState: (parameterliste: Parameterliste) => void;
  kenndaten: Kenndaten;
  disabled: boolean;
};

const messageAvailableFormats = "Erlaubte Dateiformate: JPG, PNG, GIF";

export default function UploadLogo({
  pageState,
  setPageState,
  kenndaten,
  disabled,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleFileUpload = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrorMessage("");
      setIsLoading(true);

      const inputFile = event.target.files?.[0];
      const userCanceledFileSelection = !inputFile;
      if (userCanceledFileSelection) {
        setIsLoading(false);
        return;
      }

      // Setze das Logo zurück, falls es ein Logo vorher eingegeben war
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          LOGO: "",
          LOGOFORMAT: LogoFormat.EMPTY,
        },
      });

      const type = inputFile.type;
      const logoFormat = getLogoFormat(type);
      if (logoFormat === LogoFormat.EMPTY || logoFormat === LogoFormat.SVG) {
        setErrorMessage(
          `Dateiformat nicht erlaubt. ${messageAvailableFormats}`
        );
        setIsLoading(false);
        return;
      }

      const { compressedFile, isCompressed } =
        await compressFileToLessThan64kB(inputFile);
      const fileBase64 = await toBase64(
        isCompressed ? (compressedFile as File) : inputFile
      );
      // Das Back-end kann nicht das Präfix speichern, weil es die `;` entfernen wird
      const fileBase64WithoutPrefix = (fileBase64 as string).split(
        ";base64,"
      )[1];

      setIsLoading(false);
      setPageState({
        ...pageState,
        Kenndaten: {
          ...kenndaten,
          LOGO: fileBase64WithoutPrefix,
          LOGOFORMAT: logoFormat,
        },
      });
    },
    [pageState, kenndaten]
  );

  const tooltip = `${messageAvailableFormats}; Maximale Dateigröße: 64kB. Dateien über 64kB werden automatisch verkleinert.`;

  const logoFormat = pageState.Kenndaten.LOGOFORMAT;
  const { hasImage, imageBase64 } = generateBase64Image(
    pageState?.Kenndaten?.LOGO,
    logoFormat
  );

  const downloadImage = React.useCallback(() => {
    const fileName = `Logo.${logoFormat.toLowerCase()}`;
    downloadFile(imageBase64, fileName);
  }, [imageBase64, logoFormat]);

  return (
    <div>
      <ABKLabel editorId="allgemein-status">
        Logo
        <ABKInfoPopup popupContent={tooltip} />
      </ABKLabel>
      <div className="upload-logo-piktogramm-img-button-container">
        <div className="upload-logo-piktogramm-img-container">
          {hasImage && (
            <LogoDisplayer
              isLoading={isLoading}
              hasImage={hasImage}
              imageBase64={imageBase64}
              errorMessage={errorMessage}
            />
          )}
          {!hasImage && (
            <div
              style={{
                height: "110px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SvgIcon icon={Dummy_Missing} width={40} height={40} />
            </div>
          )}
        </div>

        <div className="upload-logo-piktogramm-button-container">
          <ABKImageUploadButton
            className="k-d-inline-block"
            onChange={handleFileUpload}
            disabled={disabled}
            multiple={false}
          />
          <Button
            onClick={() => {
              setPageState({
                ...pageState,
                Kenndaten: {
                  ...kenndaten,
                  LOGO: "",
                  LOGOFORMAT: LogoFormat.EMPTY,
                },
              });
            }}
            svgIcon={Edit_Delete}
            title="Löschen"
            disabled={disabled || !hasImage}
          />
          <Button
            onClick={downloadImage}
            svgIcon={General_Export}
            title="Herunterladen"
            disabled={disabled || !hasImage}
          />
        </div>
      </div>
    </div>
  );
}
