import { create } from "zustand";
import { DialogResponse } from "../../../../../../Startseite/pages/Verwalten/delete/DeleteDialog";
import { ParameterlisteFrontend } from "../../../../../interfaces/parameterlisten";

type DeleteDialogPromise = {
  resolve: (value: DialogResponse) => void;
} | null;

interface State {
  showDeleteDialog: boolean;
  setShowDeleteDialog: (show: boolean) => void;
  deleteDialogPromise: DeleteDialogPromise;
  setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) => void;
  selectedParameterlisten: any;
  setSelectedParameterlisten: (list: ParameterlisteFrontend[]) => void;
}

export const useParameterlistenState = create<State>((set) => ({
  showDeleteDialog: false,
  setShowDeleteDialog: (show: boolean) => set({ showDeleteDialog: show }),
  deleteDialogPromise: null,
  setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) =>
    set({ deleteDialogPromise }),
  selectedParameterlisten: [],
  setSelectedParameterlisten: (
    selectedParameterlisten: ParameterlisteFrontend[]
  ) => set({ selectedParameterlisten }),
}));
