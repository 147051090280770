import _ from "lodash";
import { useEffect, useState } from "react";
import ABKSaveBalken from "../../../../../../abk-shared/components/molecules/ABKSaveBalken";
import ValidationErrorStoreProvider, {
  useValidationError,
} from "../../../../../../abk-shared/stores/ValidationErrorStoreProvider";
import useIsMobileTemplate from "../../../../../../abk-shared/utility/useIsMobileTemplate";
import ABKEigenschaftenfenster from "../../../../../../core/components/molecules/ABKEigenschaftenfenster";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import { parTypePanelsMap } from "./panels/ParTypePanelsMap";
import { useEintragBearbeitenState } from "./useEintragBearbeitenState";
import useSaveDataItem from "./useSaveDataItem";

type Props = {
  onClose: () => void;
};

export const WrapperEintragBearbeitenDialog = ({ onClose }: Props) => (
  <ValidationErrorStoreProvider>
    <EintragBearbeitenDialog onClose={onClose} />
  </ValidationErrorStoreProvider>
);

const EintragBearbeitenDialog = ({ onClose }: Props) => {
  const { currentEintrag } = useEintragBearbeitenState();

  const [pageState, setPageState] = useState(_.cloneDeep(currentEintrag));
  const [originalState, setOriginalState] = useState(
    _.cloneDeep(currentEintrag)
  );
  const isMobile = useIsMobileTemplate();

  const browserWindowHeight = isMobile
    ? window.innerHeight
    : window.innerHeight * 0.7;

  const { setValidationErrors } = useValidationError();

  useEffect(() => {
    if (currentEintrag) {
      setPageState(_.cloneDeep(currentEintrag));
      setOriginalState(_.cloneDeep(currentEintrag));
    }
    /*
      Setze `ValidationErrors` im Store zurück.
      Das heißt, wir werden den folgenden Bug verursachen:
        - Der User gibt etwas falsches ein, und speichert.
        - Vom Backend kommt einen Fehler, und die Fehlermeldung wird unterm
        Input-Feld angezeigt.
        - Der User wechselt zu einem anderen Eintrag.
        - Die Fehlermeldung bleibt unterm Input-Feld, auch wenn das Input-
        Feld einen gültigen Wert hat.
    */
    setValidationErrors([]);
  }, [currentEintrag, setValidationErrors]);

  const saveDataItemMutation = useSaveDataItem({
    itemId: currentEintrag?.ITEMID || "",
    dbname: currentEintrag?.DBNAME || "",
    contid: currentEintrag?.CONTID || "",
    dataid: currentEintrag?.DATAID || "",
    eintrag: pageState as EintragFrontend,
  });

  if (!pageState) return <></>;

  const panels: Function[] = parTypePanelsMap.get(pageState.ITEMTYPE) || [];

  return (
    <ABKEigenschaftenfenster
      initialHeight={browserWindowHeight}
      title="Eintrag bearbeiten"
      onClose={onClose}
    >
      <div className="wrapper-panels-and-balken">
        <div className="panels">
          {panels.map((panel, index) => (
            <div key={index}>{panel && panel(pageState, setPageState)}</div>
          ))}
        </div>
        <div className="save-balken-container">
          <ABKSaveBalken
            edited={!_.isEqual(pageState, originalState)}
            onSave={async () => {
              await saveDataItemMutation.mutateAsync();
            }}
            onReset={() => {
              setPageState(originalState as EintragFrontend);
              setValidationErrors([]);
            }}
            isInline={true}
            saveButtonText="Speichern"
          />
        </div>
      </div>
    </ABKEigenschaftenfenster>
  );
};

export default EintragBearbeitenDialog;
