import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { useParams } from "react-router-dom";
import { useIsValidDbAndContIdInURL } from "src/core/utility/useIsValidDB";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten";
import { PropsEintragPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ParTypePanelsMap";
import PiktogrammForm from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/PiktogrammForm";
import {
  defaultPiktogramm,
  PiktogrammItem,
} from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";
import useGetPiktogramme from "src/modules/Datei/pages/Parameterlisten/Piktogramme/useGetPiktogramme";
import ABKExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKExpansionPanel";

export default function PiktogrammPanel({
  setPageState,
  pageState,
}: PropsEintragPanel) {
  const { isValid, db, contId } = useIsValidDbAndContIdInURL();
  const { plID } = useParams();

  const {
    data: piktogrammeFromBackend,
    isLoading: isPiktogrammeLoading,
    error: piktogrammeLoadingError,
  } = useGetPiktogramme(db as string, contId as string, plID!);

  let piktogramme = piktogrammeFromBackend?.value?.Items ?? [];

  const keinPiktogrammOption: PiktogrammItem = {
    ...defaultPiktogramm,
    BEZ: "Keines",
  };

  if (!piktogramme.some((item) => item.BEZ === keinPiktogrammOption.BEZ)) {
    piktogramme.unshift(keinPiktogrammOption);
  }

  const selectedPiktogramm =
    piktogramme.find((piktogramm) => piktogramm.FILEID === pageState?.PIKTID) ||
    keinPiktogrammOption;

  if (!isValid) return <></>;

  if (piktogrammeLoadingError)
    return <div>{piktogrammeLoadingError.message}</div>;

  const handleDropDownChange = (event: DropDownListChangeEvent) => {
    setPageState({
      ...pageState,
      PIKTID: event.value.FILEID,
    } as EintragFrontend);
  };

  return (
    <ABKExpansionPanel defaultExpanded={false} title="Piktogramm">
      {isPiktogrammeLoading ? (
        <Loader />
      ) : (
        <PiktogrammForm
          selectedPiktogramm={selectedPiktogramm}
          piktogramme={piktogramme}
          onChange={handleDropDownChange}
        />
      )}
    </ABKExpansionPanel>
  );
}
