import React from "react";
import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";

const WINDOW_DEFAULT_SIZE = 950;
export default function useInitialWidth(inputWidth?: number) {
  const isMobile = useIsMobileTemplate();
  const [initialWidth, setInitialWidth] = React.useState(
    isMobile ? window.innerWidth : inputWidth || WINDOW_DEFAULT_SIZE
  );

  React.useEffect(() => {
    const updateWidth = () => {
      if (window.innerWidth <= 768) {
        setInitialWidth(window.innerWidth);
      } else {
        setInitialWidth(inputWidth || WINDOW_DEFAULT_SIZE);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, [inputWidth]);

  return initialWidth;
}
