import { GridHandle } from "@progress/kendo-react-grid";
import React from "react";
import { GridRowSelection } from "../useGridRowSelection/gridRowSelection.types";
import { GridReorderRows } from "./gridReorderRowsInterfaces";

export const ReorderContext = React.createContext<{
  reorder: GridReorderRows["reorder"];
  dragStart: GridReorderRows["dragStart"];
  dragEnd: GridReorderRows["dragEnd"];
  itemIsBeingDragged: GridReorderRows["itemIsBeingDragged"];
}>({
  reorder: () => Promise.resolve(),
  dragStart: () => {},
  dragEnd: () => {},
  itemIsBeingDragged: () => false,
});

export const SelectionContext = React.createContext<GridRowSelection>({
  selectedState: {},
  setSelectedState: () => undefined,
  setDataItemSelected: () => undefined,
  selectedItems: [],
  onSelectionChange: () => undefined,
  onHeaderSelectionChange: () => undefined,
  checkHeaderSelectionValue: () => false,
  addSelectedFieldToDataResult: () => [] as any,
  updateSelectedStateAfterFilter: () => undefined,
});

export const DragHintContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);

export type GridRef = React.RefObject<GridHandle> | null;
export const GridContext = React.createContext<GridRef>(null);
