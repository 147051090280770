import { SvgIcon } from "@progress/kendo-react-common";
import ABKInfoPopup from "src/abk-shared/components/atoms/ABKInfoPopup";
import ABKLabel from "src/abk-shared/components/atoms/ABKLabel";
import { Dummy_Missing } from "src/abk-shared/interfaces/abk9SvgIcons";
import { generateBase64Image } from "src/core/utility/imageProcessing";
import { PiktogrammItem } from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";

interface Props {
  piktogramm: PiktogrammItem | undefined;
}

const PiktogrammVorschau = ({ piktogramm }: Props) => {
  let image;
  if (piktogramm !== undefined) {
    image = generateBase64Image(piktogramm.CONTENT, piktogramm.TYP);
  }

  const tooltip = "Folgendes Bild zeigt das augewählte Piktogramm";

  const dataTestIdIcon = "Piktogramm Logo";

  return (
    <>
      <div className="abk-form-field-input-wrapper test-abk-form-field-input-wrapper">
        <div>
          <ABKLabel editorId="piktogramm-vorschau">
            Vorschau Piktogramm
            <ABKInfoPopup popupContent={tooltip} />
          </ABKLabel>

          <div className="upload-logo-piktogramm-img-button-container">
            <div className="upload-logo-piktogramm-img-container">
              {image?.hasImage && (
                <img
                  src={image.imageBase64}
                  data-testid={dataTestIdIcon}
                  className="logo-img"
                />
              )}
              {(!image || !image?.hasImage) && (
                <div
                  data-testid={dataTestIdIcon}
                  style={{
                    height: "110px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon icon={Dummy_Missing} width={40} height={40} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PiktogrammVorschau;
