import { EditorTools } from "@progress/kendo-react-editor";
import React from "react";
import FindAndReplace = EditorTools.FindAndReplace;
import FindAndReplaceProps = EditorTools.FindAndReplaceProps;

const CustomFindAndReplace: React.FC<FindAndReplaceProps> = (props) => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);

  const onClick: React.MouseEventHandler<HTMLSpanElement> = (ev) => {
    const targetElement = ev.target as HTMLElement;
    if (
      targetElement.title == "Suchen und Ersetzen" ||
      (targetElement.closest("button") &&
        (targetElement.closest("button") as HTMLElement).title ==
          "Suchen und Ersetzen")
    ) {
      const spans = document.querySelectorAll("span.k-window-title");
      const targetSpan = Array.from(spans).find(
        (span) => span.textContent?.trim() === "Suchen und Ersetzen"
      );
      setDialogIsOpen(true);

      if (targetSpan && targetSpan.nextSibling) {
        //wurde mit console.log kontrolliert targetSpan.nextSibling.querySelector
        //<button class="k-button k-button-md k-b…-window-titlebar-action" aria-label="close"></button>
        (targetSpan.nextSibling as any).querySelector("button")?.click();

        setDialogIsOpen(false);
      }
    }
  };
  return (
    <span
      className={`${dialogIsOpen ? "active-editor-span" : "inactive-editor-button"}`}
      onClick={onClick}
    >
      {/* @ts-ignore Typ von Kendo React ist nicht aktuell*/}
      <FindAndReplace {...props} />
    </span>
  );
};

export default CustomFindAndReplace;
