import { QueryClient } from "@tanstack/react-query";
import { InfoItem } from "../../../../../../abk-shared/components/organisms/ABKSideBar/QuickInfo";
import { formatDateTimeToBrowserDefault } from "../../../../../../abk-shared/utility/dateUtils";
import { Job } from "../../../../../interfaces/Job";
import fetchJobFiles from "../../../../../queries/fetchJobFiles";
import fetchLogs from "../../../../../queries/fetchLogs";
import createListOfFileLinks from "./createListOfFileLinks";
import { Log } from "../../../../../interfaces/Logs";

export default async function generateQuickInfoForLogsAndFiles(
  queryClient: QueryClient,
  job: Job
) {
  const logsPromise = queryClient.fetchQuery<Log[]>({
    queryKey: ["jobLogs", job.JOBID],
    queryFn: () => fetchLogs(job),
  });
  const filesPromise = queryClient.fetchQuery({
    queryKey: ["jobFiles", job.JOBID],
    queryFn: () => fetchJobFiles(job),
  });
  const [logs, files] = await Promise.allSettled([logsPromise, filesPromise]);

  const quickInfoForLogsAndFiles: InfoItem[] = [];

  const hasFiles =
    files.status === "fulfilled" &&
    Array.isArray(files.value) &&
    files.value.length > 0;

  if (hasFiles) {
    const fileMessages = createListOfFileLinks(files.value, job);
    quickInfoForLogsAndFiles.push({
      title: "Dateien",
      content: fileMessages,
    });
  }

  const hasLogs =
    logs.status === "fulfilled" &&
    Array.isArray(logs.value) &&
    logs.value.length > 0;

  if (hasLogs) {
    const logMessages = logs.value.map((log) => {
      const messageText = log.TITLE;

      return {
        title: formatDateTimeToBrowserDefault(log.LOGTIMESTAMP),
        content: messageText === "Preparation" ? "Vorbereitung" : messageText,
      } as InfoItem;
    });
    quickInfoForLogsAndFiles.push({ title: "Logs", content: logMessages });
  }

  return quickInfoForLogsAndFiles;
}
