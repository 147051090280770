import { SvgIcon } from "@progress/kendo-react-common";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Dummy_Missing } from "src/abk-shared/interfaces/abk9SvgIcons";
import { generateBase64Image } from "src/core/utility/imageProcessing";
import { PiktogrammItem } from "src/modules/Datei/pages/Parameterlisten/Piktogramme/piktogramm.types";
import ABKFormDropdown from "../../../../../../../abk-shared/components/atoms/ABKFormDropdown";

interface PiktogrammDropDownProps {
  selectedPiktogramm: PiktogrammItem;
  piktogramme: PiktogrammItem[];
  onChange: (event: DropDownListChangeEvent) => void;
}

export default function PiktogrammDropDown({
  selectedPiktogramm,
  piktogramme,
  onChange,
}: PiktogrammDropDownProps) {
  return (
    <ABKFormDropdown
      label="Piktogramm"
      value={selectedPiktogramm}
      data={piktogramme}
      editorId="PIKTID"
      backendKey="PIKTID"
      dataItemKey="FILEID"
      textField="BEZ"
      onChange={onChange}
      itemRender={(li, item) => {
        const { hasImage, imageBase64 } = generateBase64Image(
          item.dataItem.CONTENT,
          item.dataItem.TYP
        );

        return (
          <div
            className="dropdown-item-with-preview"
            style={{ display: "flex", alignItems: "center" }}
          >
            {hasImage && (
              <img
                src={imageBase64}
                alt={item.BEZ}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                }}
              />
            )}
            {!hasImage && (
              <SvgIcon
                style={{
                  marginRight: "10px",
                }}
                icon={Dummy_Missing}
                width={30}
                height={30}
              />
            )}
            {li}
          </div>
        );
      }}
    />
  );
}
