import ABKControlledExpansionPanel from "src/abk-shared/components/atoms/ABKControlledExpansionPanel";
import { GliederungssystemSubtype } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { Parameterliste } from "src/modules/Datei/interfaces/parameterlisten";
import { DropdownZuordnung } from "src/modules/Datei/pages/Parameterlisten/Kenndaten/panels/Zuordnungen/DropdownZuordnung";
import "./styles.scss";

interface ZuordnungenFormProps {
  db: string;
  contId: string;
  pageState: Parameterliste;
  setPageState: (parameterliste: Parameterliste) => void;
  isOpen: boolean;
  updateSections: () => void;
  readOnly: boolean;
}

const ZuordnungenForm = ({
  db,
  contId,
  pageState,
  setPageState,
  isOpen,
  updateSections,
  readOnly,
}: ZuordnungenFormProps) => {
  return (
    <ABKControlledExpansionPanel
      title="Zuordnungen"
      expanded={isOpen}
      onAction={updateSections}
    >
      <div className="parameter-listen-zuordnungen-panel-flex-row">
        <DropdownZuordnung
          db={db}
          contId={contId}
          pageState={pageState}
          setPageState={setPageState}
          readOnly={readOnly}
          zuordnungSetting={{
            fieldId: "FUNKTGSDATAID",
            type: GliederungssystemSubtype.Funktion,
            label: "Gliederung Funktion",
            tooltip: "Verwendetes Gliederungssystem für Funktion",
          }}
        />
        <DropdownZuordnung
          db={db}
          contId={contId}
          pageState={pageState}
          setPageState={setPageState}
          readOnly={readOnly}
          zuordnungSetting={{
            fieldId: "PRODGSDATAID",
            type: GliederungssystemSubtype.Produkt,
            label: "Gliederung Produktgruppe",
            tooltip: "Verwendetes Gliederungssystem für Produktgruppe",
          }}
        />
      </div>
    </ABKControlledExpansionPanel>
  );
};

export default ZuordnungenForm;
