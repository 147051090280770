import React from "react";
import { createStore, StoreApi, useStore } from "zustand";
import { ValidationError } from "../../interfaces/Error";

/*
  Der Code hier wurde von diesem Artikel inspiriert:
  https://tkdodo.eu/blog/zustand-and-react-context

  Auch in der Zustand-Dokumentation vorhanden:
  https://zustand.docs.pmnd.rs/hooks/use-store#using-scoped-(non-global)-vanilla-store-in-react
*/

type ValidationErrorStore = {
  validationErrors: ValidationError[];
  setValidationErrors: FunctionSetValidationErrors;
};

export type FunctionSetValidationErrors = (
  validationErrors: ValidationError[]
) => void;

const ValidationStoreContext =
  React.createContext<StoreApi<ValidationErrorStore> | null>(null);

const useValidationErrorStore = (
  selector: (state: ValidationErrorStore) => ValidationErrorStore
) => {
  const storeFromContext = React.useContext(ValidationStoreContext);

  /*
    `dummyStore` ist da, um einen Fallback zu haben, falls diese Funktion
    ohne ContextProvider benutzt wird.
    Zum Beispiel wollen wir nicht einen `ValidationError` für die Input-Felder,
    die immer `readOnly` sind.
    Um das Store von `ReactContext` zu definieren, bitte den ContextProvider
    `ValidationStoreContext` in irgendeiner Parent-Komponente benutzen.
  */
  const [dummyStore] = useCreateStoreDuringFirstRender();

  const store = storeFromContext ?? dummyStore;
  return useStore(store, selector);
};

export const useValidationError = () =>
  useValidationErrorStore((state: ValidationErrorStore) => state);

const useCreateStoreDuringFirstRender = () =>
  React.useState(() =>
    createStore<ValidationErrorStore>((set) => ({
      validationErrors: [],
      setValidationErrors: (validationErrors) =>
        set(() => ({ validationErrors })),
    }))
  );

export default function ValidationErrorStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [store] = useCreateStoreDuringFirstRender();

  return (
    <ValidationStoreContext.Provider value={store}>
      {children}
    </ValidationStoreContext.Provider>
  );
}
