import {
  TreeListHeaderSelectionChangeEvent,
  TreeListSelectionChangeEvent,
  mapTree,
} from "@progress/kendo-react-treelist";
import React from "react";
import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { GenericObject } from "src/abk-shared/interfaces/GenericObject";
import { ZuordnungType } from "src/modules/Datei/interfaces/gliederungssysteme.types";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten";
import { SUB_ITEMS_FIELD } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/constants";
import idGetter from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/hooks/idGetter";
import computeMinimalSelection from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection/computeMinimalSelection";
import createNewSelectedState from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection/createNewSelectedState";
import {
  convertArrayToSelectedState,
  createSelectedItemsMap,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungSelection/selectedStateConversion";
import { ZuordnungData } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/queries/useFetchZuordnungData";

export type ZuordnungSelectionFunctions = ReturnType<
  typeof useZuordnungSelection
>;

export default function useZuordnungSelection(
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void,
  zuordnungType: ZuordnungType,
  zuordnungData: ZuordnungData
) {
  const { data, dataTree } = zuordnungData;

  const onSelectionChange = React.useCallback(
    (event: TreeListSelectionChangeEvent) => {
      const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
      const checked: boolean = checkboxElement.checked;

      const selectedState = convertArrayToSelectedState(
        pageState[zuordnungType],
        dataTree
      );

      const itemSelected = event.dataItem;
      const newSelectedState = createNewSelectedState(
        selectedState,
        itemSelected,
        checked
      );

      const minimalSelection = computeMinimalSelection(
        newSelectedState,
        dataTree
      );
      setPageState({
        ...pageState,
        [zuordnungType]: minimalSelection,
      });
    },
    [pageState, zuordnungType, dataTree, setPageState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: TreeListHeaderSelectionChangeEvent) => {
      const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
      const checked: boolean = checkboxElement.checked;
      const newSelectedState: SelectedState = {};

      for (const item of event.dataItems)
        createNewSelectedState(newSelectedState, item, checked);

      const minimalSelection = computeMinimalSelection(
        newSelectedState,
        dataTree
      );

      setPageState({ ...pageState, [zuordnungType]: minimalSelection });
    },
    [pageState, setPageState, zuordnungType, dataTree]
  );

  const headerSelectionValue = React.useCallback(
    (dataState: GenericObject[], selectedState: SelectedState) => {
      let allSelected: boolean = true;
      let anySelected: boolean = false;

      mapTree(dataState, SUB_ITEMS_FIELD, (item) => {
        const isSelected = selectedState[idGetter(item)] as boolean;
        allSelected = allSelected && isSelected;
        anySelected = anySelected || isSelected;
        return item;
      });

      // return value indicating the checkbox state
      if (allSelected) return true; // fully checked
      if (anySelected) return false; // indeterminate usually null accordingly to the kendo react checkbox docu (https://www.telerik.com/kendo-react-ui/components/inputs/checkbox)
      return false; // not checked
    },
    []
  );

  const selectedState = convertArrayToSelectedState(
    pageState[zuordnungType],
    dataTree
  );

  const selectedItemsMap = createSelectedItemsMap(
    pageState[zuordnungType],
    dataTree
  );
  const selectedItems = data.filter(
    (eintrag: any) => selectedItemsMap.get(eintrag.ITEMID)?.isSelected
  );

  return {
    onSelectionChange,
    onHeaderSelectionChange,
    headerSelectionValue,
    selectedState,
    selectedItems,
  };
}
