import { html as beautifyHtml } from "js-beautify";

// Funktion zur Umwandlung von HTML-Tags entsprechend den Önorm
export function convertHtmlToOenorm(
  html: string,
  shouldBeautifyHTML = true
): string {
  // Ersetze <strong> durch <b>
  html = html.replace(/<strong>/g, "<b>");
  html = html.replace(/<\/strong>/g, "</b>");

  // Ersetze <em> durch <i>
  html = html.replace(/<em>/g, "<i>");
  html = html.replace(/<\/em>/g, "</i>");

  html = html.replace(/<tbody>/g, "");
  html = html.replace(/<\/tbody>/g, "");

  // Ersetze <br> durch <br/>
  html = html.replace(/<br>/g, "<br/>");

  // Ersetze <img> durch <img/>
  html = html.replace(/<img\b([^>]*?)>/g, "<img$1 />");

  if (shouldBeautifyHTML) {
    const prettyHtml = beautifyHtml(html, { indent_size: 2 });
    return prettyHtml;
  }
  return html;
}
