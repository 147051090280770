import { State } from "@progress/kendo-data-query";
import { ToolbarActionObject } from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import filtersAreActive from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridDataState/filtersAreActive";
import getAnchorItem from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridReorderRows/getAnchorItem";
import { GridRowSelection } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { EintragFrontend } from "src/modules/Datei/interfaces/parameterlisten";
import { EintraegeActionHandlers } from "src/modules/Datei/pages/Parameterlisten/Eintraege/useEintraege/useEintraegeActionHandlers";

enum ActionTexts {
  Bearbeiten = "Bearbeiten",
  HinzufuegenAmTabellenende = "Hinzufügen am Tabellenende",
  HinzufuegenVorAktuellerZeile = "Hinzufügen vor aktueller Zeile",
  Loeschen = "Löschen",
  NachUntenVerschieben = "Nach unten verschieben",
  NachObenVerschieben = "Nach oben verschieben",
}

export default function createEintraegeGridActions(
  eintraegeActionHandlers: EintraegeActionHandlers,
  gridRowSelection: GridRowSelection,
  dataResult: EintragFrontend[],
  dataState: State,
  dataItemKey: string
) {
  const selectedItems = gridRowSelection.selectedItems as EintragFrontend[];
  const numberOfSelectedItems = selectedItems.length;

  const defaultActions = [
    {
      icon: "Edit.Edit",
      text: ActionTexts.Bearbeiten,
      onClick: (event: any) =>
        eintraegeActionHandlers.open(event, selectedItems),
    },
    {
      icon: "Grid.InsertEnd",
      text: ActionTexts.HinzufuegenAmTabellenende,
      onClick: () => eintraegeActionHandlers.newAtEnd(),
    },
    {
      icon: "Grid.InsertBefore",
      text: ActionTexts.HinzufuegenVorAktuellerZeile,
      onClick: () => eintraegeActionHandlers.newBeforeItem(),
    },
    {
      icon: "Edit.Delete",
      text: ActionTexts.Loeschen,
      onClick: () => eintraegeActionHandlers.delete(gridRowSelection),
    },
  ];

  if (!filtersAreActive(dataState)) {
    addMoveActionsToDefaultActionsIfApplicable(
      defaultActions,
      eintraegeActionHandlers,
      selectedItems,
      dataResult,
      dataItemKey
    );
  }

  if (numberOfSelectedItems === 1) return [...defaultActions];

  if (numberOfSelectedItems > 1) {
    const filterTexts = [
      ActionTexts.Bearbeiten,
      ActionTexts.HinzufuegenVorAktuellerZeile,
    ];
    const filteredActions = defaultActions.filter(
      (action) => !filterTexts.includes(action.text)
    );
    return [<>{`${numberOfSelectedItems} ausgewählt`}</>, ...filteredActions];
  }

  return defaultActions.filter((action) =>
    action.text.includes("Hinzufügen am Tabellenende")
  );
}

function addMoveActionsToDefaultActionsIfApplicable(
  defaultActions: ToolbarActionObject[],
  eintraegeActionHandlers: EintraegeActionHandlers,
  selectedItems: EintragFrontend[],
  dataResult: EintragFrontend[],
  dataItemKey: string
) {
  /*
    N.B.: Vielleicht können diese Funktion, `getAnchorItem`, langsam sein.
    Sie geht durch das `dataResult` Array mehrmals, und wenn `dataResult`
    groß ist, kann sie somit langsamer sein.
  */
  const anchorItemMoveDown = getAnchorItem(
    "after",
    selectedItems,
    dataResult,
    dataItemKey
  );

  if (anchorItemMoveDown !== undefined) {
    defaultActions.push({
      icon: "Grid.MoveDown",
      text: ActionTexts.NachUntenVerschieben,
      onClick: () =>
        eintraegeActionHandlers.moveDown(selectedItems, anchorItemMoveDown),
    });
  }

  const anchorItemMoveUp = getAnchorItem(
    "before",
    selectedItems,
    dataResult,
    dataItemKey
  );

  if (anchorItemMoveUp !== undefined) {
    defaultActions.push({
      icon: "Grid.MoveUp",
      text: ActionTexts.NachObenVerschieben,
      onClick: () =>
        eintraegeActionHandlers.moveUp(selectedItems, anchorItemMoveUp),
    });
  }
}
