import ABKMessageNoRecords from "src/abk-shared/components/atoms/ABKMessageNoRecords";
import MobileListItem from "./MobileListItem";
import { DisplayItem } from "..";

const MobileListView = ({
  componentData,
  updateData,
  dataToDisplay,
  isEditMode,
}: {
  componentData: any[];
  updateData: (data: any[]) => void;
  isEditMode: boolean;
  dataToDisplay: DisplayItem[];
}) => (
  <>
    {componentData.length === 0 ? (
      <ABKMessageNoRecords />
    ) : (
      componentData.map((dataItem) => (
        <MobileListItem
          isEditMode={isEditMode}
          dataToDisplay={dataToDisplay}
          dataItem={dataItem}
          key={dataItem.id}
          data={componentData}
          setData={updateData}
        />
      ))
    )}
  </>
);

export default MobileListView;
