import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { useEffect, useState } from "react";
import EintragBearbeitenExpansionPanelPreview from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/EintragBearbeitenExpansionPanelPreview";
import { generatePreviewForTextArea } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/previewTextGenerator";
import ABKControlledExpansionPanel from "../../../../../../../abk-shared/components/atoms/ABKControlledExpansionPanel";
import ABKFormRow from "../../../../../../../abk-shared/components/atoms/ABKFormRow";
import ABKForm from "../../../../../../../abk-shared/components/molecules/ABKForm";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { IField } from "./IField";
import { PropsEintragPanel } from "./ParTypePanelsMap";

const RechenansatzPanel = ({ setPageState, pageState }: PropsEintragPanel) => {
  const [expanded, setExpanded] = useState(false);

  const [field, setField] = useState<IField>();

  const fieldRechenansatz: IField = {
    label: "Rechenansatz",
    value: pageState?.FORMEL || "",
    isCheckbox: false,
  };

  useEffect(() => {
    setField(fieldRechenansatz);
  }, [pageState]);

  const handleRechenansatzChanged = (event: TextAreaChangeEvent) => {
    setPageState({
      ...pageState,
      FORMEL: event.target.value,
    } as EintragFrontend);
  };

  const previewText = generatePreviewForTextArea(field);
  return (
    <ABKControlledExpansionPanel
      title={
        <EintragBearbeitenExpansionPanelPreview
          title="Rechenansatz"
          previewText={previewText}
          shouldShowPreview={!expanded}
        />
      }
      expanded={expanded}
      onAction={() => setExpanded(!expanded)}
    >
      <ABKForm
        sections={[
          {
            formElements: [
              <ABKFormRow>
                <TextArea
                  defaultValue={"Textarea"}
                  autoSize={true}
                  style={{
                    resize: "none",
                    overflowX: "hidden",
                  }}
                  value={pageState?.FORMEL}
                  onChange={handleRechenansatzChanged}
                />
              </ABKFormRow>,
            ],
          },
        ]}
      />
    </ABKControlledExpansionPanel>
  );
};

export default RechenansatzPanel;
