const QuickInfoContentWithMultipleLines = ({
  itemContent,
}: {
  itemContent: Array<string> | undefined;
}) => (
  <>
    {itemContent?.map((content, index) => (
      <p key={index} className="quick-info-item-content">
        {content}
      </p>
    ))}
  </>
);

export default QuickInfoContentWithMultipleLines;
