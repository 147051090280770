import React from "react";

/*
  Wir setzen draggable="false" auf das Element mit einem `useLayoutEffect`,
  da KendoReact uns die Möglichkeit nicht gibt, den Column-Menü-Button zu
  manipulieren.
  Wurde in diesem Kommentar gefragt:
  https://ib-data.atlassian.net/browse/ABK9-805?focusedCommentId=11327
*/
export default function usePreventDraggingColumnMenuButton() {
  const thElementRef = React.useRef<HTMLTableCellElement | null>(null);

  React.useLayoutEffect(() => {
    if (!thElementRef.current) return;

    const columnMenuButton = thElementRef.current.querySelector(
      ".k-grid-header-menu.k-grid-column-menu"
    );
    if (columnMenuButton) columnMenuButton.setAttribute("draggable", "false");
  }, []);

  return thElementRef;
}
