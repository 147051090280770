import { useQuery } from "@tanstack/react-query";
import { BACKEND_URL } from "../../constants/urls";
import fetchJobs from "./fetchJobs";
import { QUERY_KEY_JOBS } from "./useGetAllJobs";

const useGetJobsOfContainer = (
  database: string | undefined,
  contId: string | undefined
) => {
  const url = `${BACKEND_URL}/api/db/${database}/cont/${contId}/jobs`;

  return useQuery({
    queryKey: [database, QUERY_KEY_JOBS, contId],
    queryFn: () => fetchJobs(url),
    enabled: Boolean(database) && Boolean(contId),
  });
};

export default useGetJobsOfContainer;
