import {
  Editor,
  EditorChangeEvent,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import { DirectEditorProps } from "prosemirror-view";
import * as React from "react";
import "./index.scss";
import "./insertImageDialog.css";

import CustomHTMLDialog from "./CustomHTMLDialog";
import useCustomViewHtmlStore from "./CustomHTMLDialog/useCustomViewHtmlStore";
import { insertImagePlugin } from "./insertImagePlugin";
import { insertImageFiles } from "./utils";
import textHighlight = EditorUtils.textHighlight;

const { Schema, EditorState, keymap } = ProseMirror;
const {
  pasteCleanup,
  sanitize,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;

const pasteSettings = {
  convertMsLists: true,
  attributes: {
    class: () => {},
    style: sanitizeStyleAttr,
    "*": removeAttribute,
    width: () => {},
    height: () => {},
    src: () => {},
  },
};

const styles = `
    * {
        font-family: var(--kendo-font-family);
    }
    
    p {
         font-family: var(--kendo-font-family);
    }
    
    .ProseMirror table[border="0"] td {
        border: 1px dashed gray;
    }
    
    .ProseMirror table[border="1"] td {
        border: 1px solid black;
    }
`;

interface AbkOenormEditorProps {
  value: string;
  setValue: (value: string) => void;
  hidden?: boolean;
  tools: Array<any>;
}

const AbkOenormEditor: React.FC<AbkOenormEditorProps> = ({
  value,
  setValue,
  hidden,
  tools,
}) => {
  const { showDialogWindow, setShowDialogWindow, resetStore } =
    useCustomViewHtmlStore();

  /*
    Schließe den HTML-Dialog, wenn wir die Seite wechseln, und um zu vermeiden, einen geöffneten
    Dialog zu bekommen, wenn wir auf dieser anderen Seite einen anderen Editor haben.
   */
  React.useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);

  const onImageInsert = (args: any) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;
    const position =
      event.type === "drop"
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
        : null;
    insertImageFiles({
      view,
      files,
      nodeType,
      position,
    });
    return files.length > 0;
  };

  const onMount = (event: { viewProps: DirectEditorProps; dom: any }) => {
    const state = event.viewProps.state;
    let plugins: any[] = [];

    if (state) {
      plugins = [...state.plugins, insertImagePlugin(onImageInsert)];
      plugins.push(textHighlight());
    }
    // Füge die Plugins für Text-Highlighting und Tabelle-Resizing hinzu
    //plugins.push(...tableResizing());

    // Füge das Keybinding für "Mod-f" hinzu, um das Finden und Ersetzen zu öffnen
    plugins.push(
      keymap({
        "Mod-f": () => {
          return true;
        },
      })
    );

    const tableNodeSpec = {
      ...state.schema.spec.nodes.get("table"),
    };
    tableNodeSpec.attrs = tableNodeSpec.attrs || {};
    tableNodeSpec.attrs["border"] = {
      default: "1",
    };
    const newNodes = state.schema.spec.nodes.update("table", tableNodeSpec);

    const newSchema = new Schema({
      nodes: newNodes,
      marks: state.schema.spec.marks,
    });

    const newState = EditorState.create({
      doc: EditorUtils.createDocument(newSchema, value),
      plugins,
    });

    // Erstelle die EditorView mit den aktualisierten Plugins
    const view = new ProseMirror.EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: newState,
      }
    );

    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);

    return view;
  };

  const handleEditorChange = (e: EditorChangeEvent) => {
    setValue(e.html);
  };

  return (
    <>
      <div
        data-testid="ABKOenormEditor"
        className="abk-oenorm-editor-wrapper"
        style={hidden === true ? { display: "none" } : {}}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Editor
          tools={tools}
          onPasteHtml={(event) => {
            // Führe das Sanitizing des eingefügten HTML-Codes aus
            let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
            html = pasteCleanup(html, pasteSettings);

            // Bereinigt eingefügte Bilder, die aus der Zwischenablage kommen
            if (event.nativeEvent.clipboardData) {
              html = replaceImageSourcesFromRtf(
                html,
                event.nativeEvent.clipboardData
              );
            }
            return html;
          }}
          onMount={onMount}
          className={"abk-oenorm-editor"}
          defaultEditMode={"div"}
          value={value}
          onChange={handleEditorChange}
        />
      </div>
      {showDialogWindow && (
        <CustomHTMLDialog
          value={value}
          setShowDialogWindow={setShowDialogWindow}
        />
      )}
    </>
  );
};

export default AbkOenormEditor;
