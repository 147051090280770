import { GenericObject } from "../../../../../interfaces/GenericObject";
import { FunctionIdGetter } from "../../interfaces/FunctionIdGetter.types";
import { SelectedState } from "./gridRowSelection.types";

const getSelectedItems = (
  items: GenericObject[],
  selectedState: SelectedState,
  idGetter: FunctionIdGetter
) => items.filter((item) => isItemSelected(item, selectedState, idGetter));

const isItemSelected = (
  item: GenericObject,
  selectedState: SelectedState,
  idGetter: FunctionIdGetter
) => selectedState[idGetter(item)];

export default getSelectedItems;
