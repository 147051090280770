import { useMutation } from "@tanstack/react-query";
import axios from "axios";

type MutationCheckContainer = {
  dbName: string;
  contId: string;
};
export default function useCheckContainer() {
  return useMutation({
    mutationFn: ({ dbName, contId }: MutationCheckContainer) =>
      axios.post(`/api/db/${dbName}/cont/${contId}/run/check`),
  });
}
