import * as React from "react";
import * as ReactDOM from "react-dom";
import { GenericObject } from "../../../../../../interfaces/GenericObject";
import ABKDragAndDropIcon from "../../../../../atoms/ABKDragAndDropIcon";
import ABKNoContent from "../../../../../atoms/ABKNoContent";
import {
  GridRef,
  SelectionContext,
} from "../../../hooks/useGridReorderRows/gridReorderRowsContexts";
import HintNumberOfDraggedItems from "./HintNumberOfDraggedItems";
import "./style.scss";

/*
  Der Code in dieser Datei wurde vom folgenden Beispiel inspiriert:
  https://www.telerik.com/kendo-react-ui/components/grid/rows/row-reordering/#toc-multi-select-reordering
*/
type Props = {
  portal: GridRef;
  draggedItems: GenericObject[];
  dragHintKey?: string;
};
export const DragHint = React.forwardRef<HTMLDivElement, Props>(
  ({ portal, draggedItems, dragHintKey, ...props }, ref) => {
    const { selectedItems } = React.useContext(SelectionContext);

    const dragHintText =
      draggedItems.length > 0 && dragHintKey && draggedItems[0][dragHintKey];
    const shouldDisplayNumberOfDraggedItems = draggedItems.length > 1;

    const Component = (
      <div
        ref={ref}
        className="k-card grid-drag-hint"
        {...props}
        style={{
          display: draggedItems.length > 0 ? undefined : "none",
        }}
      >
        <ABKDragAndDropIcon />
        <span className="k-text-ellipsis">
          {dragHintText ? dragHintText : <ABKNoContent />}
        </span>
        {shouldDisplayNumberOfDraggedItems && (
          <HintNumberOfDraggedItems>
            +{selectedItems.length - 1}
          </HintNumberOfDraggedItems>
        )}
      </div>
    );

    return portal && portal.current && portal.current.element
      ? ReactDOM.createPortal(Component, portal.current.element)
      : Component;
  }
);
