import AllgemeinPanelOhneKennung from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/AllgemeinPanelOhneKennung";
import { ErweitertPanel } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ErweitertPanel";
import PiktogrammPanel from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/PiktogrammPanel";
import ZuordnungPanel from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel";
import { EintragFrontend } from "../../../../../interfaces/parameterlisten";
import { ItemTypeDisplayed } from "../../maps";
import AllgemeinPanel from "./AllgemeinPanel";
import AuswahllistePanel from "./AuswahllistePanel";
import KommentarPanel from "./KommentarPanel";
import RechenansatzPanel from "./Rechenansatz";
import ZahlPanel from "./ZahlPanel";

export type PropsEintragPanel = {
  pageState: EintragFrontend;
  setPageState: (eintrag: EintragFrontend) => void;
};

const createAllgemeinPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <AllgemeinPanel pageState={pageState} setPageState={setPageState} />;

const createAllgemeinPanelOhneKennung = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => (
  <AllgemeinPanelOhneKennung
    pageState={pageState}
    setPageState={setPageState}
  />
);

const createZahlPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <ZahlPanel pageState={pageState} setPageState={setPageState} />;

const createRechenansatzPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <RechenansatzPanel pageState={pageState} setPageState={setPageState} />;

const createAuswahllistePanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <AuswahllistePanel pageState={pageState} setPageState={setPageState} />;

const createKommentarPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => <KommentarPanel pageState={pageState} setPageState={setPageState} />;

const createZuordnungFunktionenPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => (
  <ZuordnungPanel
    zuordnungType="Funktion"
    pageState={pageState}
    setPageState={setPageState}
  />
);
const createZuordnungProduktgruppenPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => (
  <ZuordnungPanel
    zuordnungType="Produkt"
    pageState={pageState}
    setPageState={setPageState}
  />
);

const createPiktogrammPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => {
  return <PiktogrammPanel pageState={pageState} setPageState={setPageState} />;
};

const createErweitertPanel = (
  pageState: EintragFrontend,
  setPageState: (eintrag: EintragFrontend) => void
) => {
  return <ErweitertPanel pageState={pageState} setPageState={setPageState} />;
};

export const parTypePanelsMap: Map<ItemTypeDisplayed, Function[]> = new Map([
  ["Überschrift", [createAllgemeinPanelOhneKennung, createKommentarPanel]],
  [
    "Parameterset",
    [
      createAllgemeinPanelOhneKennung,
      createKommentarPanel,
      createZuordnungFunktionenPanel,
      createZuordnungProduktgruppenPanel,
    ],
  ],
  [
    "Zahl",
    [
      createAllgemeinPanel,
      createZahlPanel,
      createRechenansatzPanel,
      createAuswahllistePanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Text (einzeilig)",
    [
      createAllgemeinPanel,
      createAuswahllistePanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Text (mehrzeilig)",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Datum",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
  [
    "Ja/Nein",
    [
      createAllgemeinPanel,
      createKommentarPanel,
      createPiktogrammPanel,
      createErweitertPanel,
    ],
  ],
]);
