import "./style.css";

type Props = { children: React.ReactNode; className?: string };

export default function ABKFormFieldInputWrapper({
  children,
  className,
}: Props) {
  return <div className={createClassName(className)}>{children}</div>;
}

const classNameForEndToEndTests = "test-abk-form-field-input-wrapper";
function createClassName(className?: string) {
  let finalClassName = `abk-form-field-input-wrapper ${classNameForEndToEndTests}`;

  if (!className) return finalClassName;

  return `${finalClassName} ${className}`;
}
