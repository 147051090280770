import {
  Dialog,
  DialogCloseEvent,
  WindowActionsEvent,
} from "@progress/kendo-react-dialogs";
import React from "react";
import ABKWindow from "src/abk-shared/components/molecules/ABKWindow";
import useInitialWidth from "src/abk-shared/utility/useInitialWidth";
import useIsMobileTemplate from "src/abk-shared/utility/useIsMobileTemplate";
import TitleEigenschaftenfenster from "src/core/components/molecules/ABKEigenschaftenfenster/TitleEigenschaftenfenster";
import "./style.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
  initialHeight?: number;
  initialWidth?: number;
  title: React.ReactNode;
  onClose: ((event: WindowActionsEvent | DialogCloseEvent) => void) | undefined;
};

const classNameForEndToEndTests = "test-eigenschaften-fenster-container";

const ABKEigenschaftenfenster = ({
  className,
  initialHeight,
  initialWidth,
  title,
  onClose,
  children,
}: Props) => {
  const isMobileTemplate = useIsMobileTemplate();
  const initialWidthComputed = useInitialWidth(initialWidth);

  const commonProps = {
    title: <TitleEigenschaftenfenster title={title} />,
    minWidth: 300,
    onClose: onClose,
    className: `${classNameForEndToEndTests} ${className}`,
  };

  if (isMobileTemplate) {
    /*
      In der Mobile-Version, der Dialog sollte nicht außerhalb des Bildschirm
      verschoben werden. Der Grund ist, das erlaubt dem User einen hässlichen
      weißen Hintergrund außerhalb der Anwendung zu herstellen.
      https://ib-data.atlassian.net/browse/ABK9-915
    */
    return (
      <Dialog
        {...commonProps}
        height={initialHeight}
        width={initialWidthComputed}
        className={`${commonProps.className} eigenschaften-fenster-mobile`}
      >
        {children}
      </Dialog>
    );
  }

  return (
    <ABKWindow
      {...commonProps}
      initialHeight={initialHeight}
      initialWidth={initialWidthComputed}
    >
      {children}
    </ABKWindow>
  );
};

export default ABKEigenschaftenfenster;
