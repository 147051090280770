import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { AbkIconsMap } from "../../../interfaces/abk9SvgIcons";

const ABKImageUploadButton: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className: string;
  disabled: boolean;
  multiple: boolean;
}> = ({ onChange, className, disabled, multiple }) => {
  const handleClick = () => {
    // Trigger file input click when upload button is clicked
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  return (
    <div className={className}>
      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
        multiple={multiple}
      />
      <Button
        onClick={handleClick}
        svgIcon={AbkIconsMap.get("General.Import")}
        title="Hochladen"
        disabled={disabled}
      />
    </div>
  );
};

export default ABKImageUploadButton;
