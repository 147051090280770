import { create } from "zustand";
import { DialogResponse } from "../../../../../Startseite/pages/Verwalten/delete/DeleteDialog";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import { SaveType } from "../SaveTypeEnum";

type DeleteDialogPromise = {
  resolve: (value: DialogResponse) => void;
} | null;

export type SetterHideGridWhileFetching = (
  hideGridWhileFetching: boolean
) => void;

interface EintragBearbeitenState {
  currentEintrag: EintragFrontend | null;
  setCurrentEintrag: (eintrag: EintragFrontend | null) => void;

  instance: {
    DBNAME: string;
    CONTID: string;
    DATAID: string;
  };
  setInstance: (instance: {
    DBNAME: string;
    CONTID: string;
    DATAID: string;
  }) => void;

  pageState: EintragFrontend | null;
  setPageState: (eintrag: EintragFrontend) => void;

  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;

  showDeleteDialog: boolean;
  setShowDeleteDialog: (show: boolean) => void;

  selectedEintraege: EintragFrontend[];
  setSelectedEintraege: (eintraege: EintragFrontend[]) => void;

  deleteDialogPromise: DeleteDialogPromise;
  setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) => void;

  showNewItemDialog: boolean;
  setShowNewItemDialog: (show: boolean) => void;

  newItemSaveType: SaveType;
  setNewItemSaveType: (saveType: SaveType) => void;
}

export const useEintragBearbeitenState = create<EintragBearbeitenState>(
  (set) => ({
    currentEintrag: null,
    setCurrentEintrag: (eintrag: EintragFrontend | null) =>
      set({ currentEintrag: eintrag }),

    instance: {
      DBNAME: "",
      CONTID: "",
      DATAID: "",
    },
    setInstance: (instance: {
      DBNAME: string;
      CONTID: string;
      DATAID: string;
    }) => set({ instance }),

    pageState: null,
    setPageState: (eintrag: EintragFrontend) => set({ pageState: eintrag }),

    openDialog: false,
    setOpenDialog: (open: boolean) => set({ openDialog: open }),

    showDeleteDialog: false,
    setShowDeleteDialog: (show: boolean) => set({ showDeleteDialog: show }),

    selectedEintraege: [],
    setSelectedEintraege: (eintraege: EintragFrontend[]) =>
      set({ selectedEintraege: eintraege }),

    deleteDialogPromise: null,
    setDeleteDialogPromise: (deleteDialogPromise: DeleteDialogPromise) =>
      set({ deleteDialogPromise }),

    showNewItemDialog: false,
    setShowNewItemDialog: (show: boolean) => set({ showNewItemDialog: show }),

    newItemSaveType: SaveType.NewAtEnd,
    setNewItemSaveType: (saveType: SaveType) =>
      set({ newItemSaveType: saveType }),
  })
);
