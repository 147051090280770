/*
 * Takes an Array of strings and splits it into pairs of strings.
 * e.g. ["a", "b", "c", "d"] => [["a", "b"], ["c", "d"]]
 * @param arr - Array of strings
 * @returns Array of pairs of strings
 * @example
 * const result = splitIntoPairs(["a", "b", "c", "d"]);
 * console.log(result); // [["a", "b"], ["c", "d"]]
 */
export function splitIntoPairs<T>(arr: T[]): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < arr.length; i += 2) {
    result.push(arr.slice(i, i + 2));
  }
  return result;
}
