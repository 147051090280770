import { State } from "@progress/kendo-data-query";
import React from "react";
import { DragAndDropDirection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridReorderRows/gridReorderRowsInterfaces";
import { GridRowSelection } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { CreateGridActionsFunction } from "../../../../../../abk-shared/components/organisms/ABKBasisGrid/interfaces/GridAction";
import { GenericObject } from "../../../../../../abk-shared/interfaces/GenericObject";
import useIsGridLoading from "../../../../../../core/utility/useIsGridLoading";
import { EintragFrontend } from "../../../../interfaces/parameterlisten";
import createEintraegeColumnsDefinition from "./createEintraegeColumnsDefinition";
import createEintraegeCustomCells from "./createEintraegeCustomCells";
import createEintraegeGridActions from "./createEintraegeGridActions";
import useMoveEintraege from "./mutations/useMoveEintraege";
import useEintraegeActionHandlers from "./useEintraegeActionHandlers";

export default function useEintraege(
  isFetching: boolean,
  dataItemKey: "ITEMID"
) {
  const columnsDefinition = React.useMemo(createEintraegeColumnsDefinition, []);

  const { isGridLoading, setIsGridLoading } = useIsGridLoading(isFetching);
  const eintraegeActionHandlers = useEintraegeActionHandlers(
    setIsGridLoading,
    dataItemKey
  );

  const customCells = React.useMemo(
    () => createEintraegeCustomCells(eintraegeActionHandlers),
    [eintraegeActionHandlers]
  );

  const gridActions: CreateGridActionsFunction = React.useCallback(
    (
      gridRowSelection: GridRowSelection,
      dataResult: GenericObject[],
      dataState: State
    ) =>
      createEintraegeGridActions(
        eintraegeActionHandlers,
        gridRowSelection,
        dataResult as EintragFrontend[],
        dataState,
        dataItemKey
      ),
    [eintraegeActionHandlers, dataItemKey]
  );

  const mutationMoveEintraege = useMoveEintraege(setIsGridLoading);
  const reorderRows = (
    selectedItems: GenericObject[],
    dataItem: GenericObject,
    direction: DragAndDropDirection
  ) => {
    return mutationMoveEintraege.mutateAsync({
      selectedEintraege: selectedItems as EintragFrontend[],
      anchorEintrag: dataItem as EintragFrontend,
      mode: direction === "before" ? "move-up" : "move-down",
    });
  };

  return {
    columnsDefinition,
    customCells,
    gridActions,
    reorderRows,
    isGridLoading,
  };
}
