import _ from "lodash";

const DEFAULT_ERROR_MESSAGE = "Ein Fehler ist aufgetreten.";
export function getMessageFromError(errorData: unknown, error?: unknown) {
  if (!_.isObject(errorData)) {
    if (typeof errorData !== "string") return DEFAULT_ERROR_MESSAGE;

    /*
      Der Fehler kann die folgende Form haben:
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="utf-8">
      <title>Error</title>
      </head>
      <body>
      <pre>Cannot GET /apia/db/Test-DB/cont</pre>
      </body>
      </html>

      In diesem Fall machen wir ein Parsing vom HTML, das der Fehler enthält.
    */
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(errorData, "text/html");
    const message = htmlDoc.getElementsByTagName("pre")?.[0]?.textContent;
    if (message) return message;

    return DEFAULT_ERROR_MESSAGE;
  }

  const errorDataAsAny = errorData as any;
  if (errorDataAsAny.title) return errorDataAsAny.title;
  if (errorDataAsAny.message) return errorDataAsAny.message;
  if (errorDataAsAny.Message) return errorDataAsAny.Message;

  if (!_.isObject(error)) return DEFAULT_ERROR_MESSAGE;
  const errorAsAny = error as any;
  if (errorAsAny.code) return errorAsAny.code;

  return DEFAULT_ERROR_MESSAGE;
}

export function isError404(abkError: any) {
  const status = abkError?.status;
  if (status === 404) return true;

  return false;
}
