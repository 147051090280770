import { GridToolbar } from "@progress/kendo-react-grid";
import "./style.scss";

type Props = {
  leftTools: React.ReactNode;
  rightTools: React.ReactNode;
  elementsAfterTools?: React.ReactNode;
  dataTestId?: string;
};
const ABKToolbar = ({
  leftTools,
  rightTools,
  elementsAfterTools,
  dataTestId,
}: Props) => (
  <GridToolbar className="grid-toolbar" size="small">
    <div className="wrapper-tools" data-testid={dataTestId}>
      <div className="left-elements">{leftTools}</div>
      <div className="right-elements">{rightTools}</div>
    </div>
    {elementsAfterTools}
  </GridToolbar>
);

export default ABKToolbar;
