import { Parameterliste } from "../../../interfaces/parameterlisten";
import useGetSingleParameterliste from "../../../queries/useGetSingleParameterliste";
import PageTemplate from "../../PageTemplate";
import AllgemeinForm from "./panels/Allgemein/AllgemeinForm";
import HerausgeberForm from "./panels/Herausgeber/HerausgeberForm";
import "./styles.scss";
/*
  Dieser Import ist für die Vorversion, auskommentiert, um einen Spur für die Vorversion
  der Gliederungssysteme zu behalten.
  Wenn die Vorversion für die Gliederungssystem implementiert ist,
  dann kann der folgende Code gelöscht werden, mit diesem Kommentar.
*/
// import useChangePageTitleKenndaten from "./useChangePageTitleKenndaten";
import { AxiosError } from "axios";
import _ from "lodash";
import ZuordnungenForm from "src/modules/Datei/pages/Parameterlisten/Kenndaten/panels/Zuordnungen/ZuordnungenForm";
import { ABKLoader } from "../../../../../abk-shared/components/atoms/ABKLoader";
import { ParameterlisteDraft, db } from "../../../../../core/db/db";
import { Versioned } from "../../../../../core/interfaces/Versioned";
import useDrafts from "../../../hooks/useDrafts";
import useSaveParameterliste from "../../../queries/useSaveParameterliste";
import BeschreibungForm from "./panels/Beschreibung/BeschreibungForm";

type Props = { dbName: string; contId: string; plID: string };
export const KenndatenForm = ({ dbName, contId, plID }: Props) => {
  /*
    Hier wurde die Vorversion auskommentiert. Die sollte nicht für die
    Parameterlisten verfügbar sein. Aber wurde trotzdem implementiert.
    Der folgende Code bleibt da, weil die Vorversion für die Gliederungssysteme
    implementiert sein wird. Und dieser Code wird uns dabei helfen.
  */
  // useChangePageTitleKenndaten(isPreviousVersion, dbName, contId);

  const {
    data: parameterliste,
    isLoading,
    isFetching,
    error: parameterlisteLoadingError,
    refetch,
  } = useGetSingleParameterliste(dbName, contId, plID);

  const { mutateAsync } = useSaveParameterliste();

  const is404 =
    parameterlisteLoadingError instanceof AxiosError &&
    parameterlisteLoadingError.status === 404;

  // -----------------------------------------------------------------------------------
  // @Michael, wenn du das siehst, bitte folgendes bild im browser öffnen:
  // https://i.pinimg.com/originals/52/ee/77/52ee7751e0fe6df4fdb7513a1b16102f.jpg
  // -----------------------------------------------------------------------------------

  const {
    isEdited,
    handleReset,
    handleSave,
    pageState,
    updatePageState,
    sections,
    updateSections,
    conflictMessage,
  } = useDrafts<Parameterliste, ParameterlisteDraft, [string, string]>({
    is404,
    isFetching,
    initialPageState: parameterliste,
    initialSections: new Map([
      ["allgemein", true],
      ["herausgeber", false],
      ["beschreibung", false],
      ["zuordnungen", false],
    ]),
    backend: {
      doSave: async (parameterliste: Versioned<Parameterliste>) =>
        mutateAsync({
          db: dbName,
          contId,
          parameterliste,
        }),
      doRefetch: refetch,
    },
    drafts: {
      key: (pl: Parameterliste) => [pl.CONTID, pl.DATAID],
      new: (
        data: Versioned<Parameterliste>,
        sections: Map<string, boolean>
      ): ParameterlisteDraft => ({
        database: dbName,
        contId: data.value.CONTID,
        dataId: data.value.DATAID,
        etag: data.etag,
        edited: _.cloneDeep(data.value),
        sections: sections,
      }),
      table: db.parameterListeDrafts,
    },
  });

  if (isLoading) {
    return <ABKLoader />;
  }

  if (!pageState) {
    return <div>Es stehen noch keine Parameter zur Verfügung</div>;
  }

  /*
    `readOnly` ist hier absichtlich als `false` definiert.
    Vorher war hier die folgende Zeile:
    `const readOnly = pageState.value.Kenndaten.STATUS === ParameterlisteStatus.Freigegeben;`
    Der Grund ist, wir wollen dieses Feature mit `readOnly` für jetzt ausschalten.
    Dieses Feature wird später implementiert werden. Aber jetzt wollen wir nicht
    nur in einer Stelle es implementieren. Daher haben wir es ausgeschaltet.
    Mehr dazu hier: https://ib-data.atlassian.net/browse/ABK9-970
  */
  const readOnly = false;

  return (
    <PageTemplate
      db={dbName}
      contId={contId}
      balkenProps={{
        edited: is404 || isEdited,
        onSave: handleSave,
        onReset: handleReset,
        conflictMessage,
      }}
    >
      <AllgemeinForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("allgemein") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("allgemein", !sections.get("allgemein"));
          return updateSections(newSections);
        }}
        readOnly={readOnly}
      />
      <HerausgeberForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("herausgeber") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("herausgeber", !sections.get("herausgeber"));
          return updateSections(newSections);
        }}
        readOnly={readOnly}
      />
      <BeschreibungForm
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("beschreibung") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("beschreibung", !sections.get("beschreibung"));
          return updateSections(newSections);
        }}
        readOnly={readOnly}
      />
      <ZuordnungenForm
        db={dbName}
        contId={contId}
        pageState={pageState.value}
        setPageState={updatePageState}
        isOpen={sections.get("zuordnungen") ?? false}
        updateSections={() => {
          const newSections = new Map(sections);
          newSections.set("zuordnungen", !sections.get("zuordnungen"));
          return updateSections(newSections);
        }}
        readOnly={readOnly}
      />
    </PageTemplate>
  );
};
