import { InputHandle } from "@progress/kendo-react-inputs";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import ABKMobileSearchBar from "src/abk-shared/components/molecules/ABKMobileSearchBar";
import ABKSearchBarInput from "src/abk-shared/components/molecules/ABKSearchBar/ABKSearchBarInput";
import "./style.scss";

type Props = {
  isMobileGrid: boolean;
  filterValue: string;
  onFilterChange: (newValue: string) => void;
};

function ABKSearchBar({ isMobileGrid, filterValue, onFilterChange }: Props) {
  const inputRef = React.useRef<InputHandle>(null);

  useHotkeys(
    "ctrl+e",
    (event) => {
      if (inputRef.current?.element) inputRef.current.element.focus();
      event.preventDefault();
    },
    []
  );

  if (isMobileGrid) {
    return (
      <ABKMobileSearchBar
        filterValue={filterValue}
        onFilterChange={onFilterChange}
        inputRef={inputRef}
        isMobileGrid={isMobileGrid}
      />
    );
  }

  return (
    <ABKSearchBarInput
      filterValue={filterValue}
      onFilterChange={onFilterChange}
      inputRef={inputRef}
    />
  );
}

export default ABKSearchBar;
