import ABKCompactMobileCard from "../../../../../abk-shared/components/molecules/ABKCompactMobileCard";
import { FunctionSetDataItemSelected } from "../../../../../abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import { EintragFrontend } from "../../../interfaces/parameterlisten";
import { useEintragBearbeitenState } from "./EintragBearbeitenDialog/useEintragBearbeitenState";
import { parTypeMapper } from "./maps";

type Props = {
  rowRef: React.RefObject<HTMLDivElement>;
  item: EintragFrontend;
  filterValue: string;
  isSelected: boolean;
  setDataItemSelected: FunctionSetDataItemSelected;
};

const EintraegeCard = ({
  rowRef,
  item,
  filterValue,
  isSelected,
  setDataItemSelected,
}: Props) => {
  const isSet = item.ITEMTYPE === parTypeMapper.get("Set");
  const isHead = item.ITEMTYPE === parTypeMapper.get("Head");
  const isBold = isSet || isHead;

  const { setCurrentEintrag, currentEintrag, setOpenDialog } =
    useEintragBearbeitenState();
  const isCurrentEintrag = item.ITEMID === currentEintrag?.ITEMID;

  const extraRowStyles = {
    backgroundColor: isBold ? "#F4F4F4" : "transparent",
    ...(isCurrentEintrag && { fontStyle: "italic" }),
  };

  const onRowClick = (clickedItem: EintragFrontend) => {
    setCurrentEintrag(clickedItem);
    setOpenDialog(true);
  };

  const cardTitleStyles = {
    color: isHead ? "#999900" : "black",
    fontWeight: "bold",
  };

  const cardSubtitleStyles = {}; // Add styles if needed

  const cardExtraTitleStyles = {
    color: "gray",
    fontWeight: "normal",
  };

  const cardTitle = item.BEZ || "";
  const cardSubtitle = item.ITEMTYPE || "";
  const cardExtraTitle = item.VARNAME;

  return (
    <ABKCompactMobileCard
      rowRef={rowRef}
      item={item}
      filterValue={filterValue}
      isSelected={isSelected}
      setDataItemSelected={setDataItemSelected}
      onRowClick={onRowClick}
      extraRowStyles={extraRowStyles}
      cardTitle={cardTitle}
      cardSubtitle={cardSubtitle}
      cardExtraTitle={cardExtraTitle}
      cardTitleStyles={cardTitleStyles}
      cardSubtitleStyles={cardSubtitleStyles}
      cardExtraTitleStyles={cardExtraTitleStyles}
    />
  );
};

export default EintraegeCard;
