import { Button } from "@progress/kendo-react-buttons";
import { SVGIcon } from "@progress/kendo-svg-icons";
import "./style.scss";

type Props = {
  isMobile: boolean;
  svgIcon?: SVGIcon;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const ABKToolbarButton = ({ isMobile, svgIcon, title, onClick }: Props) => (
  <Button
    svgIcon={svgIcon}
    title={title}
    fillMode="link"
    {...(isMobile && { className: "abk-toolbar-button-mobile" })}
    onClick={onClick}
  />
);

export default ABKToolbarButton;
