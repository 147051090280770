import ABKSearchBar from "src/abk-shared/components/molecules/ABKSearchBar";
import ABKToolbar from "src/abk-shared/components/molecules/ABKToolbar";
import { ToolbarActionObject } from "src/abk-shared/components/molecules/ABKToolbar/abkToolbar.types";
import useCreateToolbarButtons from "src/abk-shared/components/molecules/ABKToolbar/useCreateToolbarButtons";
import { ZuordnungTreeData } from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/hooks/useZuordnungTreeData/useZuordnungTreeData";

type Props = {
  isMobile: boolean;
  zuordnungTreeData: ZuordnungTreeData;
  filterValue: string;
  setFilterValue: React.Dispatch<React.SetStateAction<string>>;
};
function ZuordnungSelectionToolbar({
  isMobile,
  zuordnungTreeData,
  filterValue,
  setFilterValue,
}: Props) {
  const actions: ToolbarActionObject[] = [
    {
      icon: "Tree.ExpandAll",
      text: "Alles ausklappen",
      onClick: () => zuordnungTreeData.expandAll(),
    },
    {
      icon: "Tree.CloseAll",
      text: "Alles einklappen",
      onClick: () => zuordnungTreeData.collapseAll(),
    },
  ];
  const toolbarButtons = useCreateToolbarButtons(isMobile, 1, actions);

  return (
    <ABKToolbar
      leftTools={toolbarButtons}
      rightTools={
        <ABKSearchBar
          isMobileGrid={false}
          filterValue={filterValue}
          onFilterChange={(value) => setFilterValue(value)}
        />
      }
    />
  );
}

export default ZuordnungSelectionToolbar;
