import { SelectedState } from "src/abk-shared/components/organisms/ABKBasisGrid/hooks/useGridRowSelection/gridRowSelection.types";
import {
  DATA_ITEM_KEY,
  SUB_ITEMS_FIELD,
} from "src/modules/Datei/pages/Parameterlisten/Eintraege/EintragBearbeitenDialog/panels/ZuordnungPanel/ZuordnungPanelContent/constants";

export default function createNewSelectedState(
  selectedState: SelectedState,
  itemSelected: any,
  checked: boolean
) {
  selectedState[itemSelected[DATA_ITEM_KEY]] = checked;

  if (
    itemSelected.hasOwnProperty(SUB_ITEMS_FIELD) &&
    Array.isArray(itemSelected[SUB_ITEMS_FIELD])
  ) {
    for (const childItem of itemSelected[SUB_ITEMS_FIELD])
      createNewSelectedState(selectedState, childItem, checked);
  }

  return selectedState;
}
