import _ from "lodash";

export default function isDBNotFoundError(error: unknown) {
  if (!error) return false;
  if (!_.isObject(error)) return false;

  const errorAsAny = error as any;
  if (!errorAsAny.type) return false;
  if (errorAsAny.type === "/api/msg/XX_E404DBNameNotFound") return true;

  return false;
}
